import Vue from 'vue'

import VueCompositionApi from '@vue/composition-api';
import VueResource from 'vue-resource';
import i18n from './plugins/i18n'
import vuetify from './plugins/vuetify';
import VuetifyDialog from 'vuetify-dialog'
import 'vuetify-dialog/dist/vuetify-dialog.css'
import VueQrcode from '@chenfengyuan/vue-qrcode';
import App from './App.vue'
import router from './router'
import store from './store'
import _ from "lodash";
import {ACTION_TYPES} from "@/store/types";
import {DateTime} from "luxon";
import cronstrue from "cronstrue";
import {DOMAIN_ID} from "@/constants";

import {v4 as uuid} from "uuid";
import { installSentry } from './plugins/sentry'
let $sentry = installSentry();

Vue.use(VueCompositionApi);

Vue.use(VueResource);

Vue.use(VuetifyDialog, {
    context: {
        router,
        store,
        i18n,
        vuetify,
    },
    confirm: {
        showClose: false,
    }
});

Vue.component(VueQrcode.name, VueQrcode);

Vue.config.productionTip = false

Vue.use({
    install() {
        //Event Hub
        Vue.prototype.$eventHub = new Vue();

        // Sentry instance
        Vue.prototype.$sentry = $sentry;

        // Copy to Clipboard
        Vue.prototype.$copyToClipboard = async function (text) {
            let v = this;

            async function fallbackCopyTextToClipboard(text) {
                let textArea = document.createElement("textarea");
                textArea.value = text;

                // Avoid scrolling to bottom
                textArea.style.top = "0";
                textArea.style.left = "0";
                textArea.style.position = "fixed";

                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();

                try {
                    let successful = document.execCommand('copy');
                    let msg = successful ? 'successful' : 'unsuccessful';
                    console.log('Fallback: Copying text command was ' + msg);
                } catch (err) {
                    console.error('Fallback: Oops, unable to copy', err);
                }

                document.body.removeChild(textArea);

                v.$showAlertMessage('Copied!');
            }

            if (!navigator.clipboard) {
                await fallbackCopyTextToClipboard(text);
                return;
            }

            try {
                await navigator.clipboard.writeText(text);
                this.$showAlertMessage('Copied!');
                console.log('Async: Copying to clipboard was successful!');
            } catch (err) {
                console.error('Async: Could not copy text: ', err);
            }
        };

        // Filtering
        Vue.prototype.$filterItems = function (items, filters) {
            let filteringAttrs = Object.keys(filters);

            if (filteringAttrs) {
                filteringAttrs.forEach((path) => {
                    let filterValue = filters[path];

                    if (filterValue instanceof Array) {
                        items = _.filter(items, (i) => {
                            return _.some(filters[path].map((v) => {
                                return _.get(i, path) === v;
                            }))
                        })
                    } else if (filterValue instanceof Object) {
                        if (filterValue.date_range) {
                            let dateRange = filterValue.value;
                            let fromDate = new Date(dateRange[0]);
                            let toDate = new Date(dateRange[1]);
                            items = _.filter(items, (i) => {
                                let recordValue = new Date(_.get(i, path));

                                return fromDate <= recordValue && recordValue <= toDate;
                            })
                        }
                    } else if (typeof filterValue === "string" || filterValue instanceof String) {
                        items = _.filter(items, (i) => {
                            return _.get(i, path) && _.get(i, path).toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
                        })
                    } else if (typeof filterValue === "boolean" || filterValue instanceof Boolean) {
                        items = _.filter(items, (i) => {
                            return Boolean(_.get(i, path)) === filterValue;
                        })
                    }
                })
            }

            return items;
        }

        // API Call
        Vue.prototype.$downloadFile = async function (file_id) {
            let path = `file/${file_id}`;

            let response = await this.$store.dispatch(ACTION_TYPES.CALL_API, {
                url: path,
                params: {},
                opt: {extra: {responseType: 'arraybuffer'}},
            });

            let blob = new Blob([response.data], {type: response.headers.get('content-type')});
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = response.headers.get('content-filename');
            link.click();
        }
        Vue.prototype.$showPdf = function (file_id) {
            this.$eventHub.$emit('showPdf', file_id);
        };

        Vue.prototype.$showAlertMessage = function (message) {
            this.$store.dispatch(ACTION_TYPES.SHOW_SNACKBAR, message);
        };

        Vue.prototype.$isTeamNote = function () {
            return Boolean(window.tnConnector);
        };

        Vue.prototype.$loginTeamNote = async function (path) {
            if (this.$store.getters.isLoggingIn) {
                return;
            }
            this.$store.dispatch(ACTION_TYPES.IS_LOGGING_IN, true);

            const debugUid = uuid()
            sessionStorage.setItem("debug_uuid", debugUid);
            $sentry.captureMessage(`$loginTeamNote ${debugUid}`);

            const response = await store.dispatch(ACTION_TYPES.CALL_API, {
                url: 'oauth/',
                params: {
                    domain_id: DOMAIN_ID,
                },
            });
            const host = response.body.host;
            const clientId = response.body.client_id;
            const preferredMethod = response.body.preferred_method;

            setTimeout(() => {
                this.$store.dispatch(ACTION_TYPES.IS_LOGGING_IN, false);
                let afterLogin = window.location.origin + window.location.pathname + (path ? `#${path}` : window.location.hash);
                window.location.href = `${host}/oauth2/auth?response_type=id_token&client_id=${clientId}&scope=user_name&redirect_uri=${encodeURIComponent(afterLogin)}&prefer=${encodeURIComponent(preferredMethod)}`;
            }, 50);
        };

        Vue.prototype.$parseDate = function (d, fmt = 'yyyy-MM-dd HH:mm') {
            return DateTime.fromISO(d).toFormat(fmt);
        };

        Vue.prototype.$parseCronString = function (cronString, until = null) {
            if (!cronString) return '';

            if (cronString === 'ASD_LEGACY') return 'IMPORTED';

            return cronstrue.toString(cronString.replace('m', '0').replace('h', '0'), {verbose: true}).substr('At 12:00 AM, '.length).replace('only ', '') +
                (until ? `, Until ${DateTime.fromISO(until).toFormat('yyyy-MM-dd')}` : '');
        }

        Vue.prototype.$getURL = function (f) {
            if (f instanceof File) {
                return URL.createObjectURL(f);
            } else {
                return 'api/file/' + f;
            }
        };

        Vue.prototype.$convertFileToBinaryString = async function (file) {
            const toBase64 = file => new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsBinaryString(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });

            return await toBase64(file);
        };
    },
});

setTimeout(async () => {
    if (window.tnConnector) {
        if (window.tnConnector.init && typeof window.tnConnector.init === 'function') {
            window.tnConnector.init({
                featureId: 'booking',
            }, () => {
                // window.tnConnector.ui.setNavigationBarColor({
                //     uiColor: '#e24139',
                // })
                window.tnConnector.ui.setNavigationBarVisibility({visibility: 0});
    
                initVue();
            }, (error) => {
                console.log(error);
            });
        } else {
            initVue();
        }
    } else {
        initVue();
    }

    if (window.navigator && navigator.serviceWorker) {
        navigator.serviceWorker.getRegistrations()
            .then(function (registrations) {
                for (let registration of registrations) {
                    registration.unregister();
                }
            });
    }
}, 50);

const featureId = 'booking';

// setTimeout(async () => {
//     if (window.tnConnector) {
//         window.tnConnector.init({
//             featureId,
//         }, () => {
//             // Init
//         }, (error) => {
//             console.log(error);
//         });
//     } else {
//         // Init without tnConnector
//     }
// }, 50);

function initVue() {
    new Vue({
        router,
        store,
        i18n,
        vuetify,
        render: h => h(App)
    }).$mount('#app')
}

// initVue();
