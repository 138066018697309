<template>
  <v-dialog v-model="dialogShown"
            scrollable
            content-class="pending-bookings">
    <v-card>
      <v-card-title>{{ $t('booking_status.PENDING_APPROVAL') }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-data-table :headers="headers"
                          :items="session.pending_bookings"
                          :items-per-page="-1"
                          hide-default-footer
                          mobile-breakpoint="0"
                          v-if="session">
              <template v-slot:item.created_at="{value}">
                {{ $parseDate(value) }}
              </template>
              <template v-slot:item.start_time="{value}">
                {{ $parseDate(value, 'yyyy-MM-dd') }}
              </template>
              <template v-slot:item.time="{item}">
                {{ $parseDate(item.start_time, 'HH:mm') + ' - ' + $parseDate(item.end_time, 'HH:mm') }}
              </template>
              <template v-slot:item.status="{value}">
                {{ $t(`booking_status.${value}`) }}
              </template>
              <template #item.detail="{item}">
                <v-btn icon
                       @click="showBooking(item)">
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>

      <BookingDetailModal :shown="bookingDetailModalShown"
                          @dismiss="bookingDetailModalShown = false"
                          :booking="showingBooking"></BookingDetailModal>
    </v-card>
  </v-dialog>
</template>

<script>
import {ref, watch} from "@vue/composition-api";
import BookingDetailModal from "@/components/BookingDetailModal";

export default {
  name: 'PendingBookingsModal',
  components: {BookingDetailModal},
  props: {
    session: Object,
    shown: Boolean,
  },
  setup(props, {root, emit}) {
    const dialogShown = ref(false);

    watch(() => props.shown, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        dialogShown.value = newValue;
      }
    });

    watch(() => dialogShown.value, (newValue) => {
      if (!newValue) {
        emit('dismiss')
      }
    });

    const headers = ref([
      {text: root.$t('booking.created_at'), value: 'created_at'},
      {text: root.$t('booking.account'), value: 'account.name'},
      {text: root.$t('booking.item'), value: 'item.name'},
      {text: root.$t('booking.date'), value: 'start_time'},
      {text: root.$t('booking.time'), value: 'time', sortable: false},
      {text: root.$t('booking.title'), value: 'title'},
      {text: '', value: 'detail', width: '50px'},
    ]);

    const bookingDetailModalShown = ref(false);
    const showingBooking = ref(null);
    const showBooking = function (booking) {
      showingBooking.value = booking;
      bookingDetailModalShown.value = true;
    }

    return {
      dialogShown,
      headers,

      bookingDetailModalShown,
      showingBooking,
      showBooking,
    }
  }
}
</script>
