export const ACTION_TYPES = {
    // appBar
    SET_APP_BAR_SHOWN: 'SET_APP_BAR_SHOWN',
    SET_APP_BAR_TITLE: 'SET_APP_BAR_TITLE',
    CLEAR_APP_BAR_TITLE: 'CLEAR_APP_BAR_TITLE',
    SET_APP_BAR_ICON_SRC: 'SET_APP_BAR_ICON_SRC',
    CLEAR_APP_BAR_ICON_SRC: 'CLEAR_APP_BAR_ICON_SRC',

    // loadingSpinner
    START_LOADING: 'START_LOADING',
    STOP_LOADING: 'STOP_LOADING',
    CLEAR_LOADING: 'CLEAR_LOADING',
    SET_LOADING_MSG: 'SET_LOADING_MSG',

    // snackBar
    SHOW_SNACKBAR: 'SHOW_SNACKBAR',
    HIDE_SNACKBAR: 'HIDE_SNACKBAR',

    // Files
    SAVE_FILE: 'SAVE_FILE',
    GET_FILE: 'GET_FILE',
    DELETE_FILE: 'DELETE_FILE',

    // API
    CALL_API: 'CALL_API',
    UPLOAD_FILE: 'UPLOAD_FILE',

    // Account
    AUTHENTICATE_TEAMNOTE_JWT: 'AUTHENTICATE_TEAMNOTE_JWT',
    SET_ME: 'SET_ME',
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT',
    GET_ME: 'GET_ME',
    SET_AFTER_LOGIN: 'SET_AFTER_LOGIN',
    IS_LOGGING_IN: 'IS_LOGGING_IN',
    CLEAR_AFTER_LOGIN: 'CLEAR_AFTER_LOGIN',

    //// Room Booking
    GET_DATA: 'GET_DATA',

    SEARCH_SESSIONS: 'SEARCH_SESSIONS',

    BOOK_SESSIONS: 'BOOK_SESSIONS',
    BOOK_RECURRING_SESSIONS: 'BOOK_RECURRING_SESSIONS',
    GET_BOOKING_RECORDS: 'GET_BOOKING_RECORDS',
    GET_BOOKINGS: 'GET_BOOKINGS',
    SAVE_BOOKING_TITLE: 'SAVE_BOOKING_TITLE',
    CHECK_IN_BOOKING: 'CHECK_IN_BOOKING',
    CHECK_OUT_BOOKING: 'CHECK_OUT_BOOKING',
    CANCEL_BOOKING: 'CANCEL_BOOKING',
    SEND_ICAL: 'SEND_ICAL',

    GET_SESSIONS: 'GET_SESSIONS',

    GET_PENDING_APPROVALS: 'GET_PENDING_APPROVALS',
    APPROVE_BOOKING: 'APPROVE_BOOKING',

    //// Schedule
    GET_SCHEDULE: 'GET_SCHEDULE',
    RSVP_INVITATION: 'RSVP_INVITATION',

    //// Users
    GET_USERS: 'GET_USERS',

    //// Event
    GET_EVENT: 'GET_EVENT',
    CREATE_EVENT: 'CREATE_EVENT',
    LINK_EVENT_BOOKING: 'LINK_EVENT_BOOKING',
    UNLINK_EVENT_BOOKING: 'UNLINK_EVENT_BOOKING',

    //// Managed Item
    SET_ITEM_SESSION_GENERATION_LIMIT: "SET_ITEM_SESSION_GENERATION_LIMIT",

    // Settings
    GET_SETTINGS: 'GET_SETTINGS',
    SET_SETTINGS: 'SET_SETTINGS',
}
