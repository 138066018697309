<template>
  <v-row>
    <v-col>
      <DatePickerInput v-model="date"
                       :hide-details="hideDetails"></DatePickerInput>
    </v-col>
    <v-col>
      <TimePickerInput v-model="time"
                       use-native
                       :hide-details="hideDetails"></TimePickerInput>
    </v-col>
  </v-row>

</template>

<script>
import {ref, watch} from "@vue/composition-api";
import {DateTime} from "luxon";
import TimePickerInput from "@/components/TimePickerInput";
import DatePickerInput from "@/components/DatePickerInput";

export default {
  name: 'DateTimePickerInput',
  components: {DatePickerInput, TimePickerInput},
  props: {
    value: String,
    disabled: Boolean,
    readonly: Boolean,
    hideDetails: [Boolean, String],
    rules: Array,
  },
  setup(props, {root, emit}) {
    const date = ref(null);
    const clearDate = function () {
      date.value = (props.range ? [] : null);
      showDatePicker.value = false;
    };
    watch(
        date,
        (newVal, oldVal) => {
          if (newVal !== oldVal) {
            tryEmit();
          }
        }
    );

    const showDatePicker = ref(false);

    const time = ref(null);
    const clearTime = function () {
      time.value = (props.range ? [] : null);
      showTimePicker.value = false;
    };
    watch(
        time,
        (newVal, oldVal) => {
          if (newVal !== oldVal) {
            tryEmit();
          }
        }
    );

    const showTimePicker = ref(false);

    const tryEmit = function () {
      let datetime = DateTime.fromISO(date.value + 'T' + time.value);

      if (!datetime.invalid) {
        emit('input', datetime.toISO());
      }
    }

    watch(() => props.value, (newValue) => {
      if (newValue) {
        let datetime = DateTime.fromISO(newValue);
        if (!datetime.invalid) {
          date.value = datetime.toFormat('yyyy-MM-dd');
          time.value = datetime.toFormat('HH:mm');
        }

      } else {
        date.value = null;
        time.value = null;
      }
    }, {deep: true, immediate: true});

    return {
      date,
      showDatePicker,
      clearDate,

      time,
      showTimePicker,
      clearTime,
    }
  }
}
</script>
