import VueI18n from 'vue-i18n'
import Vue from 'vue'

Vue.use(VueI18n);

const messages = {
    "en": {
        login: {
            title: 'Login',
            username: 'Username',
            password: 'Password',
            login_with_teamnote: 'Login with SSO'
        },
        app_drawer: {
            dashboard: 'Facility Status',
            book_facilities: 'Facility Search',
            schedule: 'Schedule',
            my_schedule: 'My Schedule',
            item_schedule: 'Facility Schedule',
            my_bookings: 'My Bookings',
            events: 'Events',
            organized_events: 'My Events',
            invited_events: 'Invitations',
            my_invitations: 'My Invitations',
            pending_approval: 'Pending Approvals',
            managed_items: 'Managed Items',
            register_device: 'Device Registration',
            scan_qr_code: 'Scan QR Code',
            logout: 'Logout',
            logout_confirm_msg: 'Confirm Logout?',
            house_rules: 'House Rules',
            quick_guide: 'Quick Guide',
            teamnote: 'Back to ArchSD Chat',
        },
        item_type: {
            title: 'Facility Type',
            add_title: 'Add Item Type',
            edit_title: 'Item Type Detail',

            name: 'Name',
            open_for_booking: 'Open for Booking',

            tags: 'Item Tags',
            add_tag: 'Add Tag',
            price_tiers: 'Price Tiers',
            day_schedules: 'Day Schedules',
            week_schedules: 'Week Schedules',
            ad_hoc_week_schedules: 'Ad Hoc Schedules',
            schedule_calendar: 'Schedule Calendar',
            items: 'Items',
            add_item: 'Add Item',

            create_success_msg: 'Item Type Successfully Added',
            edit_success_msg: 'Item Type Successfully Saved',
            delete_confirm_msg: 'Confirm Delete Item Type and ALL facilities under this Item Type?',
            delete_success_msg: 'Item Type Successfully Deleted',
        },
        price_tier: {
            title: 'Price Tier',
            add_title: 'Add Price Tier',
            edit_title: 'Edit Price Tier',

            name: 'Name',
            price: 'Price',
            legend: 'Legend',

            create_success_msg: 'Price Tier Successfully Added',
            edit_success_msg: 'Price Tier Successfully Saved',
            delete_confirm_msg: 'Confirm Delete Price Tier?',
            delete_success_msg: 'Price Tier Successfully Deleted',
        },
        day_schedule: {
            title: 'Day Schedule',
            add_title: 'Add Day Schedule',
            edit_title: 'Edit Day Schedule',

            name: 'Name',

            select_schedule: 'Select Day Schedule',

            add_session_title: 'Add Session',
            from_time: 'Session Start',
            to_time: 'Session End',
            price_tiers: 'Price Tier',

            create_success_msg: 'Day Schedule Successfully Added',
            edit_success_msg: 'Day Schedule Successfully Saved',
            delete_confirm_msg: 'Confirm Delete Day Schedule?',
            delete_success_msg: 'Day Schedule Successfully Deleted',
        },
        week_schedule: {
            day_1: 'MON',
            day_2: 'TUE',
            day_3: 'WED',
            day_4: 'THU',
            day_5: 'FRI',
            day_6: 'SAT',
            day_7: 'SUN',
            public_holiday_schedule: 'Public Holiday',

            edit_success_msg: 'Week Schedule Successfully Saved',
        },
        ad_hoc_week_schedule: {
            title: 'Ad Hoc Schedules',
            add_title: 'Add Ad Hoc Schedule',
            select_schedule: 'Select Schedule',

            name: 'Name',
            priority: 'Priority',
            valid_from: 'Valid From',
            valid_until: 'Valid Until',

            day_1: 'MON',
            day_2: 'TUE',
            day_3: 'WED',
            day_4: 'THU',
            day_5: 'FRI',
            day_6: 'SAT',
            day_7: 'SUN',
            public_holiday_schedule: 'Public Holiday',

            create_success_msg: 'Ad Hoc Schedule Successfully Added',
            edit_success_msg: 'Ad Hoc Schedule Successfully Saved',
            delete_confirm_msg: 'Confirm Delete Ad Hoc Schedule?',
            delete_success_msg: 'Ad Hoc Schedule Successfully Deleted',
        },
        item_tag: {
            title: 'Item Tag',
            add_title: 'Add Item Tag',
            edit_title: 'Item Tag Detail',

            item_type: 'Item Type',
            name: 'Name',

            create_success_msg: 'Item Tag Successfully Added',
            edit_success_msg: 'Item Tag Successfully Saved',
            delete_confirm_msg: 'Confirm Delete Item Tag?',
            delete_success_msg: 'Item Tag Successfully Deleted',
        },
        item: {
            title: 'Item',
            add_title: 'Add Item',
            edit_title: 'Item Detail',

            item_type: 'Item Type',
            name: 'Name',
            descriptions: 'Descriptions',
            capacity: 'Capacity',
            location: 'Location',
            region: 'Floor',
            tags: 'Tags',
            session_generation_limit: 'Open Booking Until',

            change_session_generation_limit: 'Change Booking Period',
            session_generate_from: 'Open Booking From',
            session_generate_until: 'Open Booking Until',
            session_generate_follow_system: 'Follow System Settings',
            session_generate_by_date: 'Exact Date',
            session_generate_by_day: 'No. of Days',
            change_session_generation_limit_confirm_msg: 'Confirm Change Facility Booking Period? Sessions will be regenerated',
            change_session_generation_limit_success_msg: 'Item Booking Period Updated Successfully',

            show_item_schedule: 'Show Facility Schedule',

            create_admin_booking: 'Create Admin Booking',
            create_booking_confirm_msg: 'Confirm Create Booking? All conflicting bookings will be cancelled.',

            create_success_msg: 'Item Successfully Added',
            edit_success_msg: 'Item Successfully Saved',
            delete_confirm_msg: 'Confirm Delete Item?',
            delete_success_msg: 'Item Successfully Deleted',

            asd_export_car_booking: 'Export Car Bookings',
        },
        location: {
            title: 'Locations',
            add_title: 'Add Location',
            edit_title: 'Location Detail',

            name: 'Name',
            address: 'Address',

            regions: 'Floors',
            items: 'Items',

            add_region: 'Add Floor',
            add_item: 'Add Item',

            create_success_msg: 'Location Successfully Added',
            edit_success_msg: 'Location Successfully Saved',
            delete_confirm_msg: 'Confirm Delete Location and ALL facilities under this Location?',
            delete_success_msg: 'Location Successfully Deleted',
        },
        region: {
            title: 'Floor',
            add_title: 'Add Floor',
            edit_title: 'Floor Detail',

            name: 'Name',
            location: 'Location',

            items: 'Items',

            add_item: 'Add Item',

            create_success_msg: 'Floor Successfully Added',
            edit_success_msg: 'Floor Successfully Saved',
            delete_confirm_msg: 'Confirm Delete Floor and ALL facilities under this Region?',
            delete_success_msg: 'Floor Successfully Deleted',
        },
        booking_record: {
            detail_title: 'Booking Details',

            status: 'Status',
            created_at: 'Booked At',
            bookings: 'Booking Date & Time',
            recurrence: 'Recurring Rule',

            cancel: 'Cancel Booking',
            cancel_confirm_msg: 'Confirm Cancel Booking?',
            cancel_success_msg: 'Booking Successfully Cancelled',

            view_event: 'View Linked Events',
            events: 'Linked Events',
            organize_event: 'Organize Event',

            send_ical: 'Send Reminder with iCal',
        },
        booking: {
            account: 'Booking User',
            item: 'Facility',
            datetime: 'Booking Date & Time',
            date: 'Booking Date',
            original_time: 'Original Booking Time',
            time: 'Booking Time',
            created_at: 'Reserved At',
            approved_at: 'Approved At',
            role: 'My Role',

            title: 'Purpose',
            start_time: 'Start Time',
            end_time: 'End Time',
            fee: 'Fee',
            status: 'Status',
            checkin_time: 'Checked-in At',
            checkout_time: 'Checked-out At',
            checkin_pin: 'Check In PIN',
            remarks: '',
            chairs: 'Chair Person',

            book_confirm_msg: 'Confirm Submit Booking for selected Facility?',
            book_success_msg: 'Booking Submitted Successfully',

            save_title_confirm_msg: 'Save Booking Purpose?',
            save_title_success_msg: 'Booking Purpose Saved',

            edit_recur_confirm_msg: 'Edit recurring event',
            edit_recur_this: 'This event',
            edit_recur_following: 'This and following events',
            edit_recur_all: 'All events',

            invitations: 'Meeting Personnel',
            current_invitations: 'Meeting Personnel',

            save_confirm_msg: 'Save Booking?',
            save_success_msg: 'Booking Successfully Saved',

            hosts: 'Contact Person',
            add_hosts: 'Contact Person',
            host_save_confirm_msg: 'Assign Booking to selected Accounts?',
            host_save_success_msg: 'Booking Contact Person Saved',

            check_in: 'Check In',
            check_in_confirm_message: 'Check In Booking?',
            check_in_success_message: 'Checked In',
            check_in_enter_pin: 'Enter PIN in Kiosk to check in',
            check_out: 'Check Out',
            check_out_confirm_message: 'Check Out Booking?',
            check_out_success_message: 'Checked Out',

            approved: 'Approve',
            approve_all: 'Approve All',
            disapprove_all: 'Disapprove All',
            submit_approval: 'Submit',
            approve: 'Approve',
            confirm_approve_msg: 'Approve Booking?',
            approve_success_msg: 'Booking Approved',
            reject: 'Reject',
            confirm_disapprove_msg: 'Reject Booking?',
            disapprove_success_msg: 'Booking Rejected',

            edit_time_both_title: 'Check / Uncheck sessions to extend / shorten Booking',
            edit_time_only_extension_title: 'Click sessions to extend Booking',
            edit_time_only_shortening_title: 'Uncheck sessions to shorten Booking',
            edit_time_remove_description: 'Sessions to be removed: ',
            edit_time_confirmation_msg: 'Confirm to shorten the booking period <br>from <b>{from}</b> to <b>{to}</b>?',

            item_admin_cancel_confirm_msg: 'Confirm Cancel Booking?',
            item_admin_cancel_success_msg: 'Booking Cancelled',

            item_admin_edit_additional_item_confirm_msg: 'Confirm Update Booking?',
            item_admin_edit_additional_item_success_msg: 'Booking Updated',
        },
        instant_book: {
            title: 'Instant Book',
            item: 'Facility',
            start: 'Session Start',
            end: 'Session End',
        },
        event: {
            add_title: 'New Event',
            detail_title: 'Event Details',

            name: 'Event Title',
            description: 'Description',
            date: 'Event Date',
            start_time: 'Start Time',
            end_time: 'End Time',
            organizer: 'Organizer',

            invitations: 'Meeting Personnel',
            current_invitations: 'Invited',

            link_booking: 'Link Booking',
            link_booking_confirm_message: 'Link Booking to Event?',

            unlink_booking: 'Un-Link Booking',
            unlink_booking_confirm_message: 'Un-Link Booking from Event?',

            // booking: 'Linked Booking',
            booking: 'Venue',

            create_confirm_msg: 'Confirm Create Event?',
            create_success_msg: 'Event Successfully Created',
        },
        invitation: {
            add_title: 'Create Invitations',
            detail_title: 'Invitation Details',

            chair: 'Chair',
            required_participant: 'Required Participant',
            optional_participant: 'Optional Participant',

            booking: 'Booking',
            account: 'Invitee',
            organizer: 'Organizer',
            status: 'Status',
            created_at: 'Invited At',

            rsvp: 'RSVP',
            rsvp_accepted: 'GOING',
            rsvp_declined: 'NOT GOING',
            rsvp_tentative: 'MAYBE',
            rsvp_success_msg: 'Responded "{status}" to "{title}"',

            resend: 'Resend',
            resend_confirm_msg: 'Resend Invitation Email to Selected Invitee?',
            resend_success_msg: 'Invitation Sent',

            cancel: 'Cancel',
            cancel_confirm_msg: 'Cancel Selected Invitation(s)?',
            cancel_success_msg: 'Invitation Cancelled',

            send_confirm_msg: 'Send Invitation Email to Selected Accounts?',
            send_success_msg: 'Invitation Sent',

            count: 'Guest',
            counts: 'Guests',
        },
        device: {
            id: 'Device ID',
            scan_qr_code: 'Scan QR Code',

            link: 'LINK',
            link_confirm_msg: 'Confirm Link Device?',
            link_success_msg: 'Device Linked',

            unlink: 'UNLINK',
            unlink_confirm_msg: 'Confirm Unlink Device?',
            unlink_success_msg: 'Device Un-linked',
        },
        // Common
        user_type: {
            super_admin: 'Super Admin',
            admin: 'Admin',
        },
        booking_record_status: {
            PENDING: 'Pending Confirmation',
            PENDING_APPROVAL: 'Pending Approval',
            REJECTED: 'Rejected',
            CONFIRMED: 'Confirmed',
            CANCELLED_BY_USER: 'Cancelled',
            CANCELLED_BY_ADMIN: 'Cancelled by Admin',
            EXPIRED: 'Expired',
            NO_SHOW: 'No Show',
            EDITING_PENDING_APPROVAL: 'Pending Approval for Change',
        },
        booking_status: {
            PENDING_APPROVAL: 'Pending Approval',
            REJECTED: 'Rejected',
            CONFIRMED: 'Confirmed',
            CREATED_BY_ADMIN: 'Created By Admin',
            CANCELLED_BY_USER: 'Cancelled',
            REFUNDED: 'Refunded',
            CANCELLED_BY_ADMIN: 'Cancelled By Admin',
            EXPIRED: 'Expired',
            NO_SHOW: 'No-Show',
            EDITING_PENDING_APPROVAL: 'Pending Approval for Change',
        },
        invitation_role: {
            CHAIR: 'Chair Person',
            REQUIRED_PARTICIPANT: 'Required',
            OPTIONAL_PARTICIPANT: 'Optional',
            NON_PARTICIPANT: 'Non Participant',
        },
        invitation_status: {
            'NEEDS-ACTION': 'PENDING',
            ACCEPTED: 'YES',
            DECLINED: 'NO',
            TENTATIVE: 'MAYBE',
            CANCELLED: 'CANCELLED',
        },
        add: 'Add',
        apply: 'Apply',
        back: 'Back',
        cancel: 'Cancel',
        change: 'Change',
        clear: 'Clear',
        clone: 'Clone',
        close: 'Close',
        confirm: 'Confirm',
        create: 'Create',
        delete: 'Delete',
        disable: 'Disable',
        edit: 'Edit',
        enable: 'Enable',
        filter: 'Filter',
        info: 'Info',
        lock: 'Lock',
        next: 'Next',
        ok: 'OK',
        previous: 'Previous',
        reset: 'Reset',
        save: 'Save',
        search: 'Search',
        view: 'View',
        unlock: 'Unlock',

        created_at: 'Created At',
        updated_at: 'Updated At',

        error_messages: {
            default: 'Something went wrong. Please try again later',
            object_not_found: 'Object Not Found',
            // Login
            login_fail: 'Incorrect Username or Password. Please confirm and try again.',
            // Client
            duplicate_client_code: 'Duplicate Client Code',

            // Contract
            duplicate_contract_id: 'Duplicate Contract Number',

            invalid_email: 'Invalid Email Address',

            // Check-In
            invalid_checkin_pin: 'Incorrect PIN',
            invalid_checkin_token: 'Invalid or Expired QR Code',
            invalid_booking_id: 'Invalid Booking',
        }
    },
    "zh": {},
};

const i18n = new VueI18n({
    locale: 'en',
    messages,
});

export default i18n;
