<template>
  <BGModal :shown="dialogShown">
    <template #content>
      <v-container fluid class="d-flex flex-column pa-0" style="height: 100%">
        <v-snackbar color="rgba(0, 0, 0, 0.5)"
                    timeout="-1"
                    :value="true"
                    top
                    content-class="text-center">
          Scan QR code or enter PIN to check-in
        </v-snackbar>
        <v-row no-gutters
               class="flex-grow-1">
          <v-col>
            <v-btn block
                   plain
                   x-large
                   :ripple="false"
                   @click="startTnQrCodeReader"
                   style="height: 100% !important;"
                   v-if="tnQrCodeReaderAvailable">
              <v-container fluid>
                <v-row>
                  <v-col>
                    <v-icon size="60px">fas fa-camera</v-icon>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    Tap to Scan QR Code
                  </v-col>
                </v-row>
              </v-container>
            </v-btn>
            <QrcodeStream :camera="qrCodeReaderSuspended? 'off' : 'auto'"
                          @init="onQrcodeStreamInit"
                          @decode="onQrCodeDecode"
                          class="qr-code-reader"
                          v-if="dialogShown && !tnQrCodeReaderAvailable && htmlCameraAvailable">
              <div class="qr-code-reader-overlay" v-if="qrCodeReaderSuspended">
                <label>PROCESSING...</label>
              </div>
            </QrcodeStream>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template #footer>
      <v-row>
        <v-col cols="3"
               class="pt-0">
          <v-btn block
                 color="primary"
                 @click="cancel">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="6"
               class="pt-0">
          <v-text-field v-model="checkInPin"
                        dense
                        hide-details
                        outlined
                        type="tel"
                        pattern="[0-9]{6}"
                        label="PIN"></v-text-field>
        </v-col>
        <v-col cols="3"
               class="pt-0">
          <v-btn block
                 color="primary"
                 :disabled="!checkInPin"
                 @click="checkIn">
            <v-icon>fas fa-check</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </BGModal>
</template>

<script>
import {QrcodeStream} from 'vue-qrcode-reader'
import {computed, ref, watch} from "@vue/composition-api";
import {ACTION_TYPES} from "@/store/types";
import BGModal from "@/components/BGModal";
import jwt_decode, {InvalidTokenError} from "jwt-decode";

export default {
  name: 'BookingCheckInModal',
  components: {BGModal, QrcodeStream},
  props: {
    shown: Boolean,
    bookingId: String,
  },
  setup(props, {root, emit}) {
    const dialogShown = ref(props.shown);

    // Init
    watch(() => props.shown, async (newValue, oldValue) => {
      if (newValue !== oldValue) {
        dialogShown.value = newValue;

        if (newValue) {
          qrCodeReaderSuspended.value = false
          checkInPin.value = null;
          token.value = null;
        }
      }
    });
    const cancel = function () {
      qrCodeReaderSuspended.value = true;

      emit('cancel');
    };

    const checkInPin = ref(null);
    const token = ref(null);

    const tnQrCodeReaderAvailable = computed(() => {
      return window.tnConnector && window.tnConnector.util.scanCode;
    });
    const startTnQrCodeReader = function () {
      window.tnConnector.util.scanCode({}, ({type, data}) => {
        onQrCodeDecode(data);
      }, async ({errorMessage, isCameraPermissionDenied, isCancelledByUser}) => {
        if (isCameraPermissionDenied) {
          await root.$store.dispatch(ACTION_TYPES.SHOW_SNACKBAR, 'Camera Permission Denied');
        }
      })
    };

    const htmlCameraAvailable = ref(true);
    const onQrcodeStreamInit = async function (promise) {
      try {
        const {capabilities} = await promise

        // successfully initialized
        htmlCameraAvailable.value = true;
      } catch (error) {
        // await root.$store.dispatch(ACTION_TYPES.SHOW_SNACKBAR, error.name);
        htmlCameraAvailable.value = false;

        if (error.name === 'NotAllowedError') {
          // user denied camera access permisson
        } else if (error.name === 'NotFoundError') {
          // no suitable camera device installed
        } else if (error.name === 'NotSupportedError') {
          // page is not served over HTTPS (or localhost)
        } else if (error.name === 'NotReadableError') {
          // maybe camera is already in use
        } else if (error.name === 'OverconstrainedError') {
          // did you requested the front camera although there is none?
        } else if (error.name === 'StreamApiNotSupportedError') {
          // browser seems to be lacking features
        }
      } finally {
        // hide loading indicator
      }
    }
    const qrCodeReaderSuspended = ref(false);
    const onQrCodeDecode = function (s) {
      qrCodeReaderSuspended.value = true;

      qrCodeReaderSuspended.value = true;
      try {
        let hash = new URL(s).hash;
        token.value = hash.substr('#/scan/'.length);
        checkIn();
      } catch (e) {
        if (e instanceof InvalidTokenError || e instanceof TypeError) {
          root.$store.dispatch(ACTION_TYPES.SHOW_SNACKBAR, 'Invalid QR Code');
        }
      } finally {
        qrCodeReaderSuspended.value = false;
      }
    };

    const checkIn = async function () {
      let params = {
        booking_id: props.bookingId,
        checkin_pin: checkInPin.value,
        token: token.value,
      }

      try {
        await root.$store.dispatch(ACTION_TYPES.CHECK_IN_BOOKING, params);
        emit('finish');
      } catch (e) {
        console.log(e);
        if (e.body?.msg) {
          root.$store.dispatch(ACTION_TYPES.SHOW_SNACKBAR, root.$t(`error_messages.${e.body.msg}`));
        } else {
          root.$store.dispatch(ACTION_TYPES.SHOW_SNACKBAR, root.$t('error_messages.default'));
        }

        qrCodeReaderSuspended.value = false
        checkInPin.value = null;
        token.value = null;
      }
    }

    return {
      dialogShown,
      cancel,

      tnQrCodeReaderAvailable,
      startTnQrCodeReader,

      htmlCameraAvailable,
      onQrcodeStreamInit,
      qrCodeReaderSuspended,
      onQrCodeDecode,

      checkInPin,
      checkIn,
    }
  },
}
</script>

<style lang="less">
.booking-check-in-modal {
  background-color: white;
  height: 100%;
  position: relative;

  .v-snack {
    padding-top: 8px !important;
  }
}
</style>
