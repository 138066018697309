<template>
  <BannerFrame :label="$t('invitation.detail_title')"
               flex-content>
    <v-container class="event-detail" v-if="invitation">
      <v-row>
        <v-col>
          <v-text-field :label="$t('invitation.booking')"
                        readonly
                        hide-details
                        :value="booking.title"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field :label="$t('invitation.organizer')"
                        readonly
                        hide-details
                        :value="booking.account.name"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field :label="$t('event.start_time')"
                        readonly
                        hide-details
                        :value="$parseDate(booking.start_time)"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field :label="$t('event.end_time')"
                        readonly
                        hide-details
                        :value="$parseDate(booking.end_time)"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-textarea :label="$t('invitation.status')"
                      readonly
                      hide-details
                      auto-grow
                      rows="1"
                      no-resize
                      :value="$t(`invitation_status.${invitation.status}`)"></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="4">
          <v-btn color="primary"
                 block
                 @click="$router.back()">
            {{ $t('back') }}
          </v-btn>
        </v-col>
        <v-col cols="12" sm="6" md="4"
               v-if="invitation.status !== 'CANCELLED'">
          <v-menu transition="slide-y-transition"
                  top
                  offset-y>
            <template #activator="{on, attrs}">
              <v-btn color="primary"
                     block
                     v-bind="attrs"
                     v-on="on">
                {{ $t('invitation.rsvp') }}
              </v-btn>
            </template>
            <v-card class="rsvp-menu">
              <v-list dense>
                <v-list-item :class="['btn-accepted', {'v-btn--active': invitation.status === 'ACCEPTED'}]"
                             @click="rsvp('ACCEPTED')">
                  <v-list-item-icon>
                    <v-icon>fas fa-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>GOING</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :class="['btn-declined', {'v-btn--active': invitation.status === 'DECLINED'}]"
                             @click="rsvp('DECLINED')">
                  <v-list-item-icon>
                    <v-icon>fas fa-times</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>NOT GOING</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :class="['btn-tentative', {'v-btn--active': invitation.status === 'TENTATIVE'}]"
                             @click="rsvp('TENTATIVE')">
                  <v-list-item-icon>
                    <v-icon>fas fa-question</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>MAYBE</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </v-col>
      </v-row>
    </v-container>
  </BannerFrame>
</template>

<script>
import {computed, onMounted, ref} from "@vue/composition-api";
import BannerFrame from "@/components/BannerFrame";
import {ACTION_TYPES} from "@/store/types";
import {DateTime} from "luxon";
import EditRecurringDialog from "@/components/EditRecurringDialog";

export default {
  name: 'InvitationDetail',
  components: {BannerFrame},
  setup(props, {root}) {
    onMounted(() => {
      getInvitation();
    })

    const invitationId = ref(root.$route.params.invitationId);
    const invitation = ref(null);
    const getInvitation = async function () {
      let response = await root.$store.dispatch(ACTION_TYPES.CALL_API, {
        url: 'client/invitations/',
        params: {invitation_id: root.$route.params.invitationId},
      });

      invitation.value = response.body.invitation;
    };
    const booking = computed(() => {
      return invitation.value ? invitation.value.booking : null;
    })
    const rsvp = async function (resp) {
      let edit = 'this';

      if (booking.value.bookingRecord.recurrence) {
        edit = await root.$dialog.showAndWait(EditRecurringDialog);
        if (edit === undefined) return;
      }

      await root.$store.dispatch(ACTION_TYPES.CALL_API, {
        url: 'rsvp/submit/',
        params: {
          token: invitation.value.token,
          response: resp,
        },
      });
      await getInvitation();
    };

    return {
      invitation,
      booking,
      rsvp,
    }
  },
}
</script>

<style lang="less">
.rsvp-menu {
  .v-list-item {
    cursor: pointer;

    &.v-btn--active {
      &.btn-accepted {
        color: green !important;

        .v-icon {
          color: green !important;
        }
      }

      &.btn-declined {
        color: red !important;

        .v-icon {
          color: red !important;
        }
      }

      &.btn-tentative {
        color: blue !important;

        .v-icon {
          color: blue !important;
        }
      }
    }
  }
}
</style>
