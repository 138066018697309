<template>
  <v-dialog ref="menu"
            v-model="dialogShown"
            persistent
            max-width="600px">
    <template v-slot:activator="{ on }">
      <v-textarea outlined
                  :append-icon="readonly ? null: 'fas fa-edit'"
                  :label="label"
                  :placeholder="label"
                  readonly
                  :value="humanReadable"
                  :hide-details="hideDetails"
                  auto-grow
                  rows="1"
                  v-on="readonly ? null : on"
                  :rules="rules"></v-textarea>
    </template>
    <v-card>
      <v-card-text>
        <v-form ref="form">
          <v-input hide-details="auto">
            <v-container fluid class="flex-column pa-0">
              <legend class="v-label theme--light">Repeat Every</legend>
              <v-row>
                <v-col v-if="repeatEveryInterval !== 'week'">
                  <v-text-field outlined
                                v-model="repeatEvery"
                                type="number"
                                min="1"
                                hide-details="auto">
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-select outlined
                            v-model="repeatEveryInterval"
                            :items="repeatEveryOptions"
                            class="repeat-every-interval-input"
                            hide-details="auto">
                  </v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-input>
<!--          <v-row>-->
<!--            <v-col>-->
<!--              <v-select outlined-->
<!--                        label="Repeat every"-->
<!--                        v-model="repeatEveryInterval"-->
<!--                        :items="repeatEveryOptions"-->
<!--                        class="repeat-every-interval-input"-->
<!--                        hide-details="auto">-->
<!--                <template #append-outer v-if="repeatEveryInterval !== 'week'">-->
<!--                  <v-text-field outlined-->
<!--                                v-model="repeatEvery"-->
<!--                                type="number"-->
<!--                                min="1"-->
<!--                                hide-details="auto">-->
<!--                  </v-text-field>-->
<!--                </template>-->
<!--              </v-select>-->
<!--            </v-col>-->
<!--          </v-row>-->
          <v-row v-if="repeatEveryInterval === 'month'">
            <v-col>
              <v-text-field outlined
                            label="Repeat on"
                            v-model="repeatOnMonthDay"
                            type="number"
                            min="1"
                            max="31"
                            hide-details="auto"
                            suffix="th Day of Month"></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="repeatEveryInterval === 'week'">
            <v-col>
              <v-select outlined
                        multiple
                        label="Repeat On"
                        :items="repeatOnWeekDaysOptions"
                        v-model="repeatOnWeekDays"
                        :rules="repeatOnWeekDaysRules"
                        hide-details="auto"></v-select>

              <!--              <v-input v-model="repeatOnWeekDays"-->
              <!--                       :rules="repeatOnWeekDaysRules"-->
              <!--                       hide-details="auto">-->
              <!--                <v-container fluid class="flex-column pa-0">-->
              <!--                  <legend class="v-label theme&#45;&#45;light">Repeat On</legend>-->
              <!--                  <v-btn-toggle multiple-->
              <!--                                rounded-->
              <!--                                dense-->
              <!--                                color="primary"-->
              <!--                                v-model="repeatOnWeekDays">-->
              <!--                    <v-btn :value="0">-->
              <!--                      S-->
              <!--                    </v-btn>-->
              <!--                    <v-btn :value="1">-->
              <!--                      M-->
              <!--                    </v-btn>-->
              <!--                    <v-btn :value="2">-->
              <!--                      T-->
              <!--                    </v-btn>-->
              <!--                    <v-btn :value="3">-->
              <!--                      W-->
              <!--                    </v-btn>-->
              <!--                    <v-btn :value="4">-->
              <!--                      T-->
              <!--                    </v-btn>-->
              <!--                    <v-btn :value="5">-->
              <!--                      F-->
              <!--                    </v-btn>-->
              <!--                    <v-btn :value="6">-->
              <!--                      S-->
              <!--                    </v-btn>-->
              <!--                  </v-btn-toggle>-->
              <!--                </v-container>-->
              <!--              </v-input>-->
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary"
               text
               @click="dialogShown = false">Cancel
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary"
               text
               @click="form.validate() && confirm()">OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {computed, ref, watch} from '@vue/composition-api';
import DatePickerInput from "@/components/DatePickerInput";
import {DateTime} from 'luxon';
import cronstrue from 'cronstrue';

export default {
  name: 'CronInput',
  components: {DatePickerInput},
  props: {
    label: String,
    readonly: Boolean,
    hideDetails: [Boolean, String],
    rules: Array,
    value: String,
  },
  setup(props, {root, emit}) {
    const dialogShown = ref(false);

    const cronString = ref('');
    const humanReadable = computed(() => {
      return root.$parseCronString(cronString.value);

      // if (!cronString.value) return '';
      //
      // return `${cronstrue.toString(cronString.value.replace('m', '0').replace('h', '0'), {verbose: true}).substr('At 12:00 AM, '.length)}`;
    });

    const form = ref();

    const repeatEvery = ref(1);
    const repeatEveryInterval = ref('week');
    const repeatEveryOptions = ref([
      {text: 'Day', value: 'day'},
      {text: 'Week', value: 'week'},
      {text: 'Month', value: 'month'},
    ]);

    const repeatOnWeekDays = ref([]);
    const repeatOnWeekDaysRules = ref([
      value => value.length > 0 || 'Required',
    ]);
    const repeatOnWeekDaysOptions = ref([
      {text: 'Sunday', value: 0},
      {text: 'Monday', value: 1},
      {text: 'Tuesday', value: 2},
      {text: 'Wednesday', value: 3},
      {text: 'Thursday', value: 4},
      {text: 'Friday', value: 5},
      {text: 'Saturday', value: 6},
    ])

    const repeatOnMonthDay = ref(1);

    const requiredRules = ref([
      value => !!value || 'Required',
    ]);

    const today = ref(DateTime.fromJSDate(new Date()).toISODate());
    const yearAfterToday = ref(DateTime.fromJSDate(new Date()).plus({years: 1}).toISODate());

    const confirm = function () {
      if (repeatEveryInterval.value === 'day') {
        cronString.value = `m h */${repeatEvery.value} * *`;
      } else if (repeatEveryInterval.value === 'week') {
        cronString.value = `m h * */${repeatEvery.value} ${repeatOnWeekDays.value.join(',')}`
      } else if (repeatEveryInterval.value === 'month') {
        cronString.value = `m h ${repeatOnMonthDay.value} */${repeatEvery.value} *`;
      }
      emit('input', cronString.value);
      dialogShown.value = false;
    };

    watch(() => props.value, (newValue) => {
      cronString.value = newValue;
    }, {immediate: true});

    return {
      dialogShown,
      humanReadable,

      form,

      repeatEvery,
      repeatEveryInterval,
      repeatEveryOptions,

      repeatOnWeekDays,
      repeatOnWeekDaysRules,
      repeatOnWeekDaysOptions,

      repeatOnMonthDay,

      requiredRules,
      today,
      yearAfterToday,

      confirm,
    }
  }
}
</script>

<style lang="less">
.repeat-every-interval-input {
  .v-input__append-outer {
    margin-top: 0;

    .v-text-field {
      .v-input__slot::before, v-input__slot::after {
        border: none;
      }
    }
  }
}

.error--text {
  legend {
    color: #ff5252 !important;
    caret-color: #ff5252 !important;
  }
}


</style>
