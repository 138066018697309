<template>
  <v-navigation-drawer color="#FFFFFF"
                       v-model="drawerShown"
                       app
                       width="320"
                       :right="right"
                       touchless
                       id="appDrawer">
    <v-container fluid class="app-drawer-container">
      <v-list-item class="app-drawer-logo">
        <v-img :src="require('@/assets/logo.png')"
               contain></v-img>
      </v-list-item>

      <v-list-item class="account-info">
        <v-list-item-avatar tile>
          <v-img :src="require('@/assets/icon.png')"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          Booking System
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="account-info"
                   v-if="account">
        <!--                   :to="{name: 'scan-qr-code'}"-->
        <!--                   v-if="account">-->
        <v-list-item-content class="text-left">
          <v-list-item-title class="title">
            {{ account.name }}
          </v-list-item-title>
          <!--          <v-list-item-subtitle>{{ account.domain.name }}</v-list-item-subtitle>-->
          <v-list-item-subtitle>{{ account.title }}</v-list-item-subtitle>
        </v-list-item-content>

        <!--        <v-list-item-action>-->
        <!--          <v-icon>fas fa-qrcode</v-icon>-->
        <!--        </v-list-item-action>-->
      </v-list-item>

      <v-list class="app-drawer-list">
        <v-list-item v-for="(item, idx) in permittedItems"
                     :key="idx"
                     :link="Boolean(item.route)"
                     :to="item.route"
                     :class="[item.class, 'text-left']"
                     @click="(item.onclick && item.onclick()) || null"
                     exact>
          <v-list-item-icon>
            <v-icon v-if="item.icon" class="item-icon" size="24">{{ item.icon }}</v-icon>

            <v-img v-if="item.img"
                   width="32px"
                   height="32px"
                   contain
                   class="app-drawer-list-icon"
                   :src="item.img"></v-img>
                   
            <div class="active-avatar" v-if="item.icon || item.img">
              <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
              <v-img v-if="item.img"
                     width="40px"
                     height="40px"
                     contain
                     :class="['app-drawer-list-icon', {'no-grayscale': item.no_grayscale}]"
                     :src="item.img"></v-img>
            </div>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              {{ item.title }}
              <v-chip x-small
                      label
                      v-if="item.admin">Admin Only
              </v-chip>
            </v-list-item-title>
          </v-list-item-content>

        </v-list-item>
      </v-list>
    </v-container>

    <PdfViewer :shown="pdfViewerShown"
               :src="pdfSrc"
               @close="pdfViewerShown = false"></PdfViewer>
  </v-navigation-drawer>
</template>

<script>
import _ from 'lodash';
import {computed, ref, watch} from '@vue/composition-api'
import {USER_TYPE} from "@/constants";
import {ACTION_TYPES} from "@/store/types";
import {Item, ItemApprover, ItemType} from "@/store/models";
import PdfViewer from "@/components/PdfViewer";

export default {
  name: 'AppDrawer',
  components: {PdfViewer},
  props: {
    value: Boolean,
    right: Boolean,
  },
  setup(props, {emit, root}) {
    // Drawer Open State
    const drawerShown = ref(Boolean(props.value));
    watch(drawerShown, (newValue, oldValue) => {
      if (newValue != null && newValue !== oldValue) {
        emit('input', newValue);
      }
    });

    // Props
    watch(() => props.value, (newValue, oldValue) => {
      if (newValue != null && newValue !== oldValue) {
        drawerShown.value = newValue;
      }
    });

    //
    const logout = async function () {
      if (await root.$dialog.confirm({text: root.$t('app_drawer.logout_confirm_msg')})) {
        root.$store.dispatch(ACTION_TYPES.LOGOUT);
      }
    };

    const backToTeamNote = function () {
      window.tnConnector.ui.openSideMenu({}, () => {
      }, () => {
      });
    }

    const account = computed(() => {
      return root.$store.getters.account;
    })

    // PDF Viewer
    const pdfViewerShown = ref(false);
    const pdfSrc = ref(null);

    const showHouseRules = async function () {
      if (root.$isTeamNote()) {
        root.$store.dispatch(ACTION_TYPES.START_LOADING, {uid: 'quick-guide'});
        const response = await root.$http.get('house_rules.pdf', {responseType: 'arraybuffer'});
        let blob = new Blob([response.data], {type: response.headers.get('content-type')});
        let binaryString = await root.$convertFileToBinaryString(blob);
        window.tnConnector.app.writeFile({
          filename: 'house_rules.pdf',
          data: binaryString,
        }, ({filename}) => {
          window.tnConnector.app.openDocument({
            filename,
            mimeType: 'application/pdf',
          }, () => {
            root.$store.dispatch(ACTION_TYPES.CLEAR_LOADING);
          }, () => {
            root.$store.dispatch(ACTION_TYPES.CLEAR_LOADING);
          })
        }, () => {
          root.$store.dispatch(ACTION_TYPES.CLEAR_LOADING);
        })
      } else {
        let link = document.createElement('a');
        link.href = 'house_rules.pdf';
        link.target = '_blank';
        // link.download = 'House Rules for Booking Meeting Rooms at QGO ArchSD Offices.pdf';
        link.click();
      }
    };
    const showQuickGuide = async function () {
      if (root.$isTeamNote()) {
        root.$store.dispatch(ACTION_TYPES.START_LOADING, {uid: 'quick-guide'});
        const response = await root.$http.get('quick_guide.pdf', {responseType: 'arraybuffer'});
        let blob = new Blob([response.data], {type: response.headers.get('content-type')});
        let binaryString = await root.$convertFileToBinaryString(blob);
        window.tnConnector.app.writeFile({
          filename: 'quick_guide.pdf',
          data: binaryString,
        }, ({filename}) => {
          window.tnConnector.app.openDocument({
            filename,
            mimeType: 'application/pdf',
          }, () => {
            root.$store.dispatch(ACTION_TYPES.CLEAR_LOADING);
          }, () => {
            root.$store.dispatch(ACTION_TYPES.CLEAR_LOADING);
          })
        }, () => {
          root.$store.dispatch(ACTION_TYPES.CLEAR_LOADING);
        })
      } else {
        let link = document.createElement('a');
        link.href = 'quick_guide.pdf';
        link.target = '_blank';
        // link.download = 'House Rules for Booking Meeting Rooms at QGO ArchSD Offices.pdf';
        link.click();
      }
    }

    // Menu Items
    const items = computed(() => {
      const account_group_ids = root.$store.getters.account.account_groups;

      let managedItemIds = ItemApprover.query().where('user_id', account.value.id).get().map((a) => a.item_id);
      let managedItemCount = managedItemIds.length;
      let managedItemTypeIds = _.uniq(Item.query().whereIdIn(managedItemIds).get().map(i => i.type_id));
      let managedItemTypes = ItemType.query().whereIdIn(managedItemTypeIds).orderBy('ordering').get();
      return [
        ...(root.$isTeamNote() ? [
          {
            title: root.$t('app_drawer.teamnote'),
            img: require('@/assets/teamnote.png'),
            no_grayscale: true,
            onclick: backToTeamNote
          }
        ] : []),

        // {title: root.$t('app_drawer.dashboard'), icon: 'fas fa-eye', route: {name: 'dashboard'}},
        {
          title: root.$t('app_drawer.book_facilities'),
          img: require('@/assets/facility_search.png'),
          not_icon: 'fas fa-calendar-plus',
          route: {name: 'select-type'}
        },
        ...ItemType.query().where((i) => !i.deleted).where((i) => i.open_for_booking).where((i)=> {
          return !i.open_for_booking_groups || i.open_for_booking_groups.length === 0 || _.intersection(i.open_for_booking_groups, account_group_ids).length !== 0;
        }).orderBy('ordering').get().map((t) => {
          return {
            title: t.name,
            img: {
              'c36b6963-1b01-44a9-88ba-a1f6e21011c7': require('@/assets/lactation_room.png'),
              '17ad032a-4de0-4ef4-bfa3-28e9e1158e54': require('@/assets/meeting_room.png'),
              'a7a56262-6735-472e-9e77-d1a959521f2e': require('@/assets/car_booking.png'),
            }[t.id],
            route: {name: 'search', params: {itemTypeId: t.id}},
            class: 'font-weight-light pl-8'
          }
        }),
        {
          title: root.$t('app_drawer.schedule'),
          img: require('@/assets/schedule.png'),
          not_icon: 'fas fa-calendar-alt',
        },
        {title: root.$t('app_drawer.my_schedule'), route: {name: 'schedule'}, class: 'font-weight-light'},
        {title: root.$t('app_drawer.item_schedule'), route: {name: 'item-schedule'}, class: 'font-weight-light'},
        {
          title: root.$t('app_drawer.my_bookings'),
          img: require('@/assets/my_bookings.png'),
          not_icon: 'mdi-playlist-check',
          route: {name: 'my-bookings'}
        },

        ...(managedItemCount > 0 ? [
          {
            title: root.$t('app_drawer.pending_approval'),
            img: require('@/assets/pending_approval.png'),
            not_icon: 'fas fa-calendar-check',
            route: {name: 'pending-approval'},
            admin: true,
          },
          ...(managedItemTypes.map((t) => {
            return {
              title: t.name,
              route: {name: 'pending-approval', params: {itemTypeId: t.id}},
              class: 'font-weight-light'
            }
          })),
          // {
          //   title: root.$t('app_drawer.managed_items'),
          //   icon: 'fas fa-tasks',
          //   route: {name: 'managed-items'},
          //   admin: true
          // },
        ] : []),

        {
          title: root.$t('app_drawer.register_device'),
          icon: 'fas fa-tablet-alt',
          route: {name: 'register-device'},
          admin: true,
          user_type: [USER_TYPE.SUPER_ADMIN, USER_TYPE.ADMIN],
        },

        // {title: root.$t('app_drawer.logout'), icon: 'fas fa-sign-out-alt', onclick: logout},
        {title: root.$t('app_drawer.house_rules'), img: require('@/assets/house_rules.png'), onclick: showHouseRules},
        {title: root.$t('app_drawer.quick_guide'), img: require('@/assets/quick_guide.png'), onclick: showQuickGuide},
        ...(root.$isTeamNote() ? [] : [
          // {title: root.$t('app_drawer.logout'), icon: 'fas fa-sign-out-alt', onclick: logout}
        ]),
      ]
    });
    const permittedItems = computed(() => {
      return _.filter(items.value, (item) => {
        return !item.user_type || account.value && item.user_type.indexOf(account.value.type) !== -1;
      })
    })

    // On Menu Click
    const onMenuItemClick = (item) => {
      drawerShown.value = false;
      root.$router.push(item.route);
    };

    return {
      drawerShown,
      logout,
      account,
      permittedItems,
      items,
      onMenuItemClick,

      pdfViewerShown,
      pdfSrc,
    }
  },
  watch: {
    value(newValue) {
      this.drawerShown = newValue;
    },
    drawerShown(newValue) {
      this.$emit('input', newValue);
    },
  },
}
</script>

<style lang="less">
#appDrawer {
  .app-drawer-container {
    padding: 0;
    height: 100% !important;
    display: flex;
    flex-direction: column;

    .app-drawer-logo {
      padding: 12px;
      flex: 0 0 auto;
    }

    .account-info {
      font-weight: bold;
      font-size: 110%;
      padding: 12px;
      flex: 0 0 auto;
    }

    .app-drawer-list-icon {
      filter: grayscale(100%);

      &.no-grayscale {
        filter: grayscale(0%) !important;
      }
    }

    .app-drawer-list {
      flex: 1 1 0;
      overflow: auto;

      .v-list-item__icon {
        position: relative;
        
        .item-icon {
          margin-left: 6px;
        }

        .active-avatar {
          display: none;
          position: absolute;
          height: 250%;
          width: 250%;
          background-color: white;
          top: -75%;
          left: -75%;
          border-radius: 100%;
          border: var(--v-primary-base) 3px solid;

          .v-icon, .v-image {
            left: 25%;
            top: 25%;
            height: 50%;
            width: 50%;
          }

          &::before {
            content: '';
            background-color: var(--v-primary-base);
            width: 200%;
            left: -150%;
            height: 100%;
            position: absolute;
            z-index: -1;
          }
        }
      }

      .v-list-item--active {
        //border-left: solid 4px var(--v-primary-base);
        //color: #998065;
        color: var(--v-primary-base);

        .app-drawer-list-icon {
          filter: grayscale(0%) !important;
        }

        .v-list-item__icon {
          .active-avatar {
            display: block;
          }
        }
      }
    }
  }
}
</style>
