<template>
  <v-container :class="['banner-frame', 'pa-0', {'flex-content': flexContent}]" fluid>
    <v-parallax
        class="banner-frame-banner m-0 flex-grow-0"
        dark
        :height="isAlwaysLarge ? 200 : (pageWidth > 1200 ? 200 : 150)"
        :src="src || defaultImgSrc"
        v-if="!hideBanner">
      <div class="banner-frame-label title-font">
        <span :class="isSnapContent?'transform-label':''">{{ label }}</span>
      </div>
    </v-parallax>
    <v-row :class="['banner-frame-content m-0', {'transform-content': isSnapContent}, {'with-padding': !noPadding}]"
           v-if="!isBannerOnly">
      <v-col :class="{'banner-frame-content-wrapper': isSnapContent, 'pa-0': noPadding}">
        <slot></slot>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'BannerFrame',
  props: {
    src: String,
    label: String,
    transformContent: String,
    isBannerOnly: Boolean,
    isAlwaysLarge: Boolean,
    isSnapContent: Boolean,
    isSticky: Boolean,
    noPadding: Boolean,
    flexContent: Boolean,
    hideBanner: Boolean,
  },
  data() {
    return {
      pageWidth: 9999,
      defaultImgSrc: require('../assets/banner.jpg')
      // imgSrc: this.src || require('../assets/acc.jpg'),
    }
  },
  mounted() {
    this.pageWidth = window.innerWidth;
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.pageWidth = window.innerWidth;
      });
    })
  },
}
</script>

<style lang="less">
.banner-frame {
  padding: 30px;

  .banner-frame-label {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    color: white;
    font-size: 3rem;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.2);
    letter-spacing: 1px;
    text-transform: uppercase;
    font-family: 'Montserrat', "PingFang TC", "Heiti TC", "微軟正黑體", "Microsoft JhengHei", "sans-serif" !important;

    background-color: rgba(11, 11, 11, 0.2);

    @media only screen and (max-width: 1200px) {
      font-size: 1.5rem;
    }
  }

  .banner-frame-label-word-container {
    z-index: 1;
    background-color: black;
    pointer-events: none;
    position: absolute;
    width: 100%;
    margin-top: -400px;
    justify-content: center;
  }

  .banner-frame-label-word {
    color: white;
    font-size: 3rem;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.2);
    letter-spacing: 1px;
    text-transform: uppercase;
    font-family: 'Montserrat', "PingFang TC", "Heiti TC", "微軟正黑體", "Microsoft JhengHei", "sans-serif" !important;

    @media only screen and (max-width: 1200px) {
      font-size: 1.5rem;
    }
  }

  .banner-frame-content {
    background-color: #efefef;
    padding: 2rem 0 5rem;
    margin: 0 !important;
    max-width: 100%;
    font-family: 'Montserrat', "PingFang TC", "Heiti TC", "微軟正黑體", "Microsoft JhengHei", "sans-serif" !important;

    @media only screen and (max-width: 1200px) {
      padding: 0;
    }

    &.transform-content {
      padding-bottom: 0 !important;

      .banner-frame-content-wrapper {
        /*transform: translateY(-10rem);*/
      }
    }
  }

  &.flex-content {
    height: 100%;
    display: flex;
    flex-direction: column;

    .banner-frame-content {
      flex: 1 1 0;
      display: flex;
      flex-direction: column;
    }
  }
}

.v-parallax__content {
  padding: 0px !important;
}

.banner-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

@media only screen and (min-width: 1200px) {
  .banner-frame-content {

    &.with-padding {
      padding-left: 10rem !important;
      padding-right: 10rem !important;
    }
  }

  .transform-label {
    transform: translateY(-60px);
  }

  .banner-frame-content-wrapper {
    transform: translateY(-200px) !important;
  }
}
</style>
