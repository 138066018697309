import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rsvp-input"},[(!_vm.readonly)?_c(VAutocomplete,{attrs:{"outlined":"","hide-details":"auto","label":_vm.label || _vm.$t('booking.invitations'),"hint":_vm.hint,"persistent-hint":"","items":_vm.accounts,"multiple":"","return-object":"","search-input":_vm.searchInput,"item-text":_vm.accountText,"menu-props":{closeOnContentClick: true}},on:{"update:searchInput":function($event){_vm.searchInput=$event},"update:search-input":function($event){_vm.searchInput=$event},"change":_vm.onAccountSelect},scopedSlots:_vm._u([{key:"selection",fn:function(){return undefined},proxy:true},{key:"item",fn:function(ref){
var parent = ref.parent;
var item = ref.item;
return [_c(VListItemContent,{staticClass:"text-left"},[_c(VListItemTitle,{domProps:{"innerHTML":_vm._s(parent.genFilteredText(item.name))}}),(item.title)?_c(VListItemSubtitle,{domProps:{"innerHTML":_vm._s(parent.genFilteredText(item.title))}}):_vm._e(),(item.mobile)?_c(VListItemSubtitle,{domProps:{"innerHTML":_vm._s(parent.genFilteredText(item.mobile))}}):_vm._e(),(item.email)?_c(VListItemSubtitle,{domProps:{"innerHTML":_vm._s(parent.genFilteredText(item.email))}}):_vm._e()],1)]}}],null,false,1887866470),model:{value:(_vm.selectedAccounts),callback:function ($$v) {_vm.selectedAccounts=$$v},expression:"selectedAccounts"}}):_vm._e(),_c(VRow,{staticClass:"rsvp-input-invitation-list",attrs:{"no-gutters":""}},_vm._l((_vm.invitations),function(invitation,idx){return _c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VListItem,{staticClass:"rsvp-input-invitation-item px-0"},[_c(VListItemContent,{staticClass:"text-left"},[_c(VListItemTitle,[_vm._v(" "+_vm._s(invitation.account.name)+" ")]),_vm._l((_vm.filteredFields(invitation.account)),function(field){return _c(VListItemSubtitle,[_vm._v(" "+_vm._s(field)+" ")])})],2),_c(VListItemAction,{staticClass:"ml-0"},[_c('div',[(!_vm.disableRoles)?_c(VMenu,{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"rsvp-input-role-btn",attrs:{"text":"","x-small":""}},'v-btn',attrs,false),_vm.readonly ? null: on),[_vm._v(" "+_vm._s(_vm.$t(("invitation_role." + (invitation.role))))+" "),(!_vm.readonly)?_c(VIcon,[_vm._v("mdi-menu-down")]):_vm._e()],1)]}}],null,true)},[_c(VList,_vm._l((_vm.INVITATION_ROLE),function(role){return _c(VListItem,{class:[{'v-list-item--active': invitation.role === role}],on:{"click":function($event){return _vm.setInvitationRole(idx, role)}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(_vm.invitationRoleIcon(role)))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t(("invitation_role." + role))))])],1)],1)}),1)],1):_vm._e(),(!_vm.readonly)?_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.removeInvitation(idx)}}},[_c(VIcon,[_vm._v("mdi-close")])],1):_vm._e()],1)])],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }