<template>
  <v-card>
    <v-card-title>
      {{ title || $t('booking.edit_recur_confirm_msg') }}
    </v-card-title>
    <v-card-text>
      <v-radio-group v-model="edit">
        <v-radio :label="$t('booking.edit_recur_this')"
                 value="this"></v-radio>
        <v-radio :label="$t('booking.edit_recur_following')"
                 value="following"></v-radio>
<!--        <v-radio :label="$t('booking.edit_recur_all')"-->
<!--                 value="all"></v-radio>-->
      </v-radio-group>
    </v-card-text>
    <v-card-actions class="text-right">
      <v-spacer></v-spacer>
      <v-btn text
             @click="$emit('submit', false)">
        {{ $t('cancel') }}
      </v-btn>
      <v-btn text
             color="primary"
             @click="$emit('submit', edit)">
        {{ $t('ok') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {ref} from '@vue/composition-api'

export default {
  name: 'EditRecurringDialog',
  props: {
    title: String,
  },
  setup() {
    return {
      edit: ref('this'),
    }
  }
}
</script>
