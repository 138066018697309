<template>
  <v-container v-if="itemType"
               fluid
               class="search">
    <!--FILTER-->
    <v-navigation-drawer color="#FFFFFF"
                         v-model="filterShown"
                         app
                         right
                         mobile-breakpoint="2000">
      <v-container class="filter-container">
        <v-row class="flex-grow-0">
          <v-col>FILTER</v-col>
        </v-row>
        <v-divider></v-divider>
        <v-container class="pa-0 flex-grow-1 overflow-y-auto overflow-x-hidden filter-content-container">
          <v-form ref="filterForm">
            <v-row>
              <v-col cols="12"
                     class="px-0"
                     v-if="mode === 'single'">
                <v-btn block
                       text
                       @click="backToToday">
                  TODAY
                </v-btn>
                <v-date-picker :max="aYearFromNow"
                               v-model="selectedDate"
                               :rules="requiredRules"
                               hide-details="auto"
                               dense
                               no-title
                               scrollable
                               full-width></v-date-picker>
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12"
                     v-if="mode === 'recurring'">
                <DatePickerInput label="Start Date"
                                 :min="today"
                                 v-model="selectedStartDate"
                                 :rules="requiredRules"
                                 hide-details="auto"></DatePickerInput>
              </v-col>
              <v-col cols="12"
                     v-if="mode === 'recurring'">
                <DatePickerInput label="End Date"
                                 :min="selectedStartDate || today"
                                 :max="aYearFromNow"
                                 v-model="selectedEndDate"
                                 :rules="selectedEndDateRules"
                                 hide-details="auto"></DatePickerInput>
              </v-col>
              <v-col cols="12">
                <TimePickerInput label="From Time"
                                 v-model="selectedFromTime"
                                 :rules="selectedFromTimeRules"
                                 hide-details="auto"
                                 use-native></TimePickerInput>
              </v-col>
              <v-col cols="12">
                <TimePickerInput label="To Time"
                                 v-model="selectedToTime"
                                 :rules="selectedToTimeRules"
                                 hide-details="auto"
                                 use-native></TimePickerInput>
              </v-col>
              <!--              <v-col cols="12"-->
              <!--                     v-if="mode === 'single'">-->
              <!--                <v-text-field outlined-->
              <!--                              label="Consecutive Available Hour(s)"-->
              <!--                              placeholder="Consecutive Available Hour(s)"-->
              <!--                              clearable-->
              <!--                              hide-details-->
              <!--                              v-model="consecutiveHours"-->
              <!--                              type="number"-->
              <!--                              min="1"></v-text-field>-->
              <!--              </v-col>-->
              <!--              <v-col cols="12"-->
              <!--                     v-if="mode === 'single'">-->
              <!--                <v-checkbox label="Only show available"-->
              <!--                            hide-details-->
              <!--                            v-model="consecutiveHours"-->
              <!--                            value="1"></v-checkbox>-->
              <!--              </v-col>-->
              <v-col cols="12"
                     v-if="mode === 'recurring'">
                <CronInput outlined
                           label="Recurrence"
                           hide-details="auto"
                           v-model="recurrence"
                           :rules="requiredRules"></CronInput>
              </v-col>
              <v-col cols="12" v-if="showLocationFilter">
                <v-select outlined
                          label="Location"
                          hide-details
                          class=""
                          :items="locationOptions"
                          v-model="selectedLocationId"
                          @input="onLocationChange"
                          clearable>
                </v-select>
              </v-col>
              <v-col cols="12" v-if="showRegionFilter">
                <v-select outlined
                          label="Floor"
                          hide-details
                          class=""
                          :items="regionOptions"
                          v-model="selectedRegionId"
                          clearable>
                </v-select>
              </v-col>
            </v-row>
          </v-form>
          <v-row>
            <v-col class="py-0">
              <v-container v-for="attributeGroup in attributeGroups"
                           :key="attributeGroup.category"
                           fluid
                           class="pa-0">
                <v-row v-if="attributeGroup.category">
                  <v-col class="attribute-category-header">{{ attributeGroup.category }}</v-col>
                </v-row>
                <v-row>
                  <v-col v-for="attribute in getAttributeGroupAttributes(attributeGroup)"
                         :key="attribute.id" cols="12"
                         :class="{'py-0': attributeGroup.category}">
                    <v-text-field outlined
                                  clearable
                                  hide-details
                                  :placeholder="attribute.name"
                                  :label="attribute.name"
                                  v-model="attributeFilter[attribute.id]"
                                  v-if="attribute.type === ITEM_TYPE_ATTRIBUTE_TYPE.TEXT"></v-text-field>

                    <v-text-field outlined
                                  clearable
                                  hide-details
                                  type="number"
                                  min="0"
                                  :placeholder="attribute.name"
                                  :label="attribute.name"
                                  v-model="attributeFilter[attribute.id]"
                                  v-if="attribute.type === ITEM_TYPE_ATTRIBUTE_TYPE.NUMBER || attribute.type === ITEM_TYPE_ATTRIBUTE_TYPE.NUMBER_MIN || attribute.type === ITEM_TYPE_ATTRIBUTE_TYPE.NUMBER_MAX"></v-text-field>

                    <v-row v-if="attribute.type === ITEM_TYPE_ATTRIBUTE_TYPE.RANGE">
                      <v-col>
                        <v-text-field outlined
                                      clearable
                                      hide-details
                                      type="number"
                                      min="0"
                                      :placeholder="'Min. ' + attribute.name"
                                      :label="'Min. ' + attribute.name"
                                      @input="onRangeAttributeChange(attribute.id, $event, 'min')"></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field outlined
                                      clearable
                                      hide-details
                                      type="number"
                                      min="0"
                                      :placeholder="'Max. ' + attribute.name"
                                      :label="'Max. ' + attribute.name"
                                      @input="onRangeAttributeChange(attribute.id, $event, 'max')"
                        ></v-text-field>
                      </v-col>
                    </v-row>


                    <v-text-field outlined
                                  clearable
                                  hide-details
                                  type="date"
                                  :placeholder="attribute.name"
                                  :label="attribute.name"
                                  v-model="attributeFilter[attribute.id]"
                                  v-if="attribute.type === ITEM_TYPE_ATTRIBUTE_TYPE.DATE"></v-text-field>

                    <v-row v-if="attribute.type === ITEM_TYPE_ATTRIBUTE_TYPE.DATE_RANGE">
                      <v-col>
                        <v-text-field outlined
                                      clearable
                                      hide-details
                                      type="date"
                                      min="0"
                                      :placeholder="attribute.name + ' (From)'"
                                      :label="attribute.name + ' (From)'"
                                      @input="onRangeAttributeChange(attribute.id, $event, 'min')"></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field outlined
                                      clearable
                                      hide-details
                                      type="date"
                                      min="0"
                                      :placeholder="attribute.name + ' (Until)'"
                                      :label="attribute.name + ' (Until)'"
                                      @input="onRangeAttributeChange(attribute.id, $event, 'max')"></v-text-field>
                      </v-col>
                    </v-row>


                    <v-checkbox :label="attribute.name"
                                hide-details
                                v-model="attributeFilter[attribute.id]"
                                v-if="attribute.type === ITEM_TYPE_ATTRIBUTE_TYPE.BOOLEAN"></v-checkbox>
                  </v-col>
                </v-row>
                <v-row v-if="attributeGroup.attributes.length > 3">
                  <v-col v-if="!attributeGroupExpanded(attributeGroup)">
                    <v-btn block text
                           @click="expandAttributeGroup(attributeGroup)"
                           key="more">
                      Show all {{ attributeGroup.attributes.length }}
                      <v-icon class="ml-4">
                        fas fa-chevron-down
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col v-if="attributeGroupExpanded(attributeGroup)">
                    <v-btn block text
                           @click="collapseAttributeGroup(attributeGroup)"
                           key="less">
                      Show Less
                      <v-icon class="ml-4">
                        fas fa-chevron-up
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>

        </v-container>

        <v-divider></v-divider>
        <v-row class="flex-grow-0">
          <v-col>
            <v-btn block
                   large
                   color="primary"
                   @click="($refs.filterForm.validate() || scrollFilterToTop()) && search()">
              APPLY
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-navigation-drawer>

    <!--FILTER BUTTON-->
    <v-fab-transition>
      <v-btn color="primary"
             fab
             bottom
             right
             v-if="!filterShown"
             @click="filterShown = true"
             :class="['filter-btn', {'single-booking': mode === 'single'}]">
        <v-icon>
          fas fa-filter
        </v-icon>
      </v-btn>
    </v-fab-transition>

    <!--CALENDAR-->
    <v-container class="bg-white pb-8 rounded-lg"
                 v-if="showCalendar && selectedDate">
      <AvailabilityGrid :start-date="selectedDate"
                        :end-date="selectedDate"
                        :start-time="selectedFromTime"
                        :end-time="selectedToTime"
                        :extra-params="{
                            item_type_id: $route.params.itemTypeId,
                            location_id: selectedLocationId,
                            region_id: selectedRegionId,
                            attributes: attributeFilter,
                          }"
                        :interval-minutes="30"
                        :interval-height="32"
                        :consecutive-hours="consecutiveHours"
                        show-booking-details
                        :refresh-trigger.sync="refreshTrigger"
                        @click:category="showItemDescription"
                        v-model="selectedSessionIds"
                        :sessions-selected.sync="selectedSessions"
                        :selected-item-id.sync="selectedItemId"
                        :all-locations-room-booking-settings="allLocationsRoomBookingSettings"
                        v-if="mode === 'single'"></AvailabilityGrid>

      <RecurringAvailabilityGrid :start-date="selectedStartDate"
                                 :end-date="selectedEndDate"
                                 :start-time="selectedFromTime"
                                 :end-time="selectedToTime"
                                 :recurrence="recurrence"
                                 :extra-params="{
                                    item_type_id: $route.params.itemTypeId,
                                    location_id: selectedLocationId,
                                    region_id: selectedRegionId,
                                    attributes: attributeFilter,
                                   }"
                                 show-booking-details
                                 :refresh-trigger.sync="refreshTrigger"
                                 @click:category="showItemDescription"
                                 v-model="selectedItemId"
                                 v-if="mode === 'recurring'"></RecurringAvailabilityGrid>

      <ItemDetailModal :shown="itemDescriptionMenuShown"
                       :item-id="showingItemId"
                       @cancel="itemDescriptionMenuShown = false">

      </ItemDetailModal>
    </v-container>

    <!--BOOKING DETAIL-->
    <BGModal :shown="bookingDetailModalShown"
             :title="`${itemType.name} Details`">
      <template #content>
        <v-container v-if="bookingDetailModalShown && !itemTypeLayoutDefinitions.length">
          <v-form ref="bookingDetailForm">
            <DisplayTextField :label="$t('booking.item')"
                              v-if="selectedItem"
                              :value="selectedItem.name"></DisplayTextField>
            <DisplayTextField readonly
                              :label="$t('booking.date')"
                              v-if="mode === 'single'"
                              v-model="selectedDate"></DisplayTextField>
            <DisplayTextField readonly
                              :label="$t('booking.time')"
                              v-if="mode === 'single'"
                              v-model="selectedSessionTimeLabel"></DisplayTextField>
            <DisplayTextField readonly
                              label="Start Date"
                              v-if="mode === 'recurring'"
                              :value="selectedStartDate"></DisplayTextField>
            <DisplayTextField readonly
                              label="End Date"
                              v-if="mode === 'recurring'"
                              :value="selectedEndDate"></DisplayTextField>
            <DisplayTextField readonly
                              :label="$t('booking_record.recurrence')"
                              v-if="mode === 'recurring'"
                              :value="$parseCronString(recurrence)"></DisplayTextField>
            <v-checkbox label="Recurring Booking"
                        v-if="mode === 'single'"
                        v-model="showBookingDetailRecurringOptions"></v-checkbox>
            <!-- Recurring -->
            <v-form ref="bookingDetailRecurringForm">
              <v-row v-if="showBookingDetailRecurringOptions">
                <v-col>
                  <DatePickerInput label="End Date"
                                   v-if="showBookingDetailRecurringOptions"
                                   :min="selectedDate"
                                   :max="aYearFromNow"
                                   v-model="bookingDetailRecurringEndDate"
                                   :rules="requiredRules"
                                   hide-details="auto"></DatePickerInput>
                </v-col>
              </v-row>
              <v-row v-if="showBookingDetailRecurringOptions">
                <v-col>
                  <CronInput outlined
                             label="Recurrence"
                             hide-details="auto"
                             v-model="bookingDetailRecurrence"
                             :rules="requiredRules"></CronInput>
                </v-col>
              </v-row>
              <v-row v-if="bookingDetailRecurringUnavailableCount >= 0"
                     class="text-left text-subtitle-1">
                <v-col>
                  <v-icon v-if="bookingDetailRecurringUnavailableCount === 0"
                          color="green">fas fa-check
                  </v-icon>
                  <v-icon v-else
                          color="red">fas fa-exclamation-triangle
                  </v-icon>
                  {{
                    bookingDetailRecurringUnavailableCount === 0 ? 'ALL AVAILABLE' : `UNAVAILABLE TIMESLOT: ${bookingDetailRecurringUnavailableCount}`
                  }}

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon
                             v-bind="attrs"
                             v-on="on"
                             @click="bookingDetailRecurringCalendarShown = true">
                        <v-icon color="grey">
                          fas fa-search
                        </v-icon>
                      </v-btn>
                    </template>
                    See Details
                  </v-tooltip>
                </v-col>
              </v-row>

              <BGModal :shown="bookingDetailRecurringCalendarShown"
                       content-class="search"
                       :persistent="false"
                       @dismiss="bookingDetailRecurringCalendarShown = false">
                <template #content>
                  <v-container class="bg-white pa-4 rounded-lg">
                    <RecurringAvailabilityGrid :start-date="selectedDate"
                                               :end-date="bookingDetailRecurringEndDate"
                                               :start-time="selectedSessionStart"
                                               :end-time="selectedSessionEnd"
                                               :recurrence="bookingDetailRecurrence"
                                               :extra-params="{item_id: selectedItemId}"
                                               :refresh-trigger="bookingDetailRecurringCalendarShown"
                                               @click:category="showItemDescription"
                                               hide-header></RecurringAvailabilityGrid>
                  </v-container>
                </template>
                <template #footer>
                  <v-row no-gutters>
                    <v-btn color="secondary"
                           @click="bookingDetailRecurringCalendarShown = false"
                           class="mr-3"
                           depressed
                           large>
                      {{ $t('cancel') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-row>
                </template>
              </BGModal>
            </v-form>

            <!--Title-->
            <v-row v-if="enabledFeatures.indexOf('title') !== -1">
              <v-col>
                <v-text-field outlined
                              :label="$t('booking.title')"
                              hide-details="auto"
                              v-model="bookingTitle"
                              :rules="requiredFeatures.indexOf('title') !== -1 ? titleRules : []"></v-text-field>
              </v-col>
            </v-row>

            <!--Hosts-->
            <v-row v-if="enabledFeatures.indexOf('hosts') !== -1">
              <v-col>
                <MultipleAccountInput v-model="hostIds"
                                      hint="Additional contact persons for the booking"
                                      :label="$t('booking.hosts')"></MultipleAccountInput>
              </v-col>
            </v-row>

            <!--Invitation-->
            <v-row v-if="enabledFeatures.indexOf('invitations') !== -1">
              <v-col>
                <v-input v-model="invitations"
                         hide-details="auto"
                         :rules="requiredFeatures.indexOf('invitations') !== -1 ? invitationRules : []">
                  <v-container fluid class="pa-0">
                    <RsvpInput v-model="invitations"></RsvpInput>
                  </v-container>
                </v-input>
              </v-col>
            </v-row>

            <!--Additional Item-->
            <v-row>
              <v-col v-for="itemTypeAdditionalItem in itemTypeAdditionalItems"
                     :key="itemTypeAdditionalItem.id"
                     cols="12">
                <v-input hide-details="auto"
                         v-model="additionalItems[itemTypeAdditionalItem.id]">
                  <!--TEXT-->
                  <v-text-field outlined
                                clearable
                                :rules="itemTypeAdditionalItem.required ? requiredRules : []"
                                hide-details="auto"
                                :placeholder="itemTypeAdditionalItem.name"
                                :label="itemTypeAdditionalItem.name"
                                :hint="itemTypeAdditionalItem.hint"
                                :persistent-hint="Boolean(itemTypeAdditionalItem.hint)"
                                v-model="additionalItems[itemTypeAdditionalItem.id]"
                                v-if="itemTypeAdditionalItem.type === ITEM_TYPE_ADDITIONAL_ITEM_TYPE.TEXT"></v-text-field>

                  <!--NUMBER-->
                  <v-text-field outlined
                                clearable
                                :rules="itemTypeAdditionalItem.required ? requiredRules : []"
                                hide-details="auto"
                                type="number"
                                min="0"
                                :placeholder="itemTypeAdditionalItem.name"
                                :label="itemTypeAdditionalItem.name"
                                :hint="itemTypeAdditionalItem.hint"
                                :persistent-hint="Boolean(itemTypeAdditionalItem.hint)"
                                v-model="additionalItems[itemTypeAdditionalItem.id]"
                                v-if="itemTypeAdditionalItem.type === ITEM_TYPE_ADDITIONAL_ITEM_TYPE.NUMBER"></v-text-field>

                  <!--DATE-->
                  <DatePickerInput :label="itemTypeAdditionalItem.name"
                                   :hint="itemTypeAdditionalItem.hint"
                                   :persistent-hint="Boolean(itemTypeAdditionalItem.hint)"
                                   :rules="itemTypeAdditionalItem.required ? requiredRules : []"
                                   v-model="additionalItems[itemTypeAdditionalItem.id]"
                                   v-if="itemTypeAdditionalItem.type === ITEM_TYPE_ADDITIONAL_ITEM_TYPE.DATE"></DatePickerInput>

                  <!--TIME-->
                  <TimePickerInput :label="itemTypeAdditionalItem.name"
                                   :hint="itemTypeAdditionalItem.hint"
                                   :persistent-hint="Boolean(itemTypeAdditionalItem.hint)"
                                   hide-details="auto"
                                   :rules="itemTypeAdditionalItem.required ? requiredRules : []"
                                   use-native
                                   v-model="additionalItems[itemTypeAdditionalItem.id]"
                                   v-if="itemTypeAdditionalItem.type === ITEM_TYPE_ADDITIONAL_ITEM_TYPE.TIME"></TimePickerInput>

                  <!--BOOLEAN-->
                  <v-checkbox :label="itemTypeAdditionalItem.name"
                              :hint="itemTypeAdditionalItem.hint"
                              :persistent-hint="Boolean(itemTypeAdditionalItem.hint)"
                              hide-details="auto"
                              v-model="additionalItems[itemTypeAdditionalItem.id]"
                              v-if="itemTypeAdditionalItem.type === ITEM_TYPE_ADDITIONAL_ITEM_TYPE.BOOLEAN"></v-checkbox>

                  <!--OPTION-->
                  <v-select outlined
                            :label="itemTypeAdditionalItem.name"
                            :hint="itemTypeAdditionalItem.hint"
                            :persistent-hint="Boolean(itemTypeAdditionalItem.hint)"
                            hide-details="auto"
                            :rules="itemTypeAdditionalItem.required ? requiredRules : []"
                            :items="getItemTypeAdditionalItemOptions(itemTypeAdditionalItem)"
                            v-model="additionalItems[itemTypeAdditionalItem.id]"
                            v-if="itemTypeAdditionalItem.type === ITEM_TYPE_ADDITIONAL_ITEM_TYPE.OPTIONS"></v-select>
                </v-input>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-container v-if="bookingDetailModalShown && itemTypeLayoutDefinitions.length">
          <v-form ref="bookingDetailForm">
            <v-row v-for="item in itemTypeLayoutDefinitions">
              <!-- Display -->
              <v-col v-if="item.type === ITEM_TYPE_LAYOUT_TYPES.DISPLAY"
                     class="py-0">
                <DisplayTextField :label="item.label"
                                  :hint="item.hint"
                                  :value="getDisplayValue(item)"></DisplayTextField>
              </v-col>

              <!-- Date / Time Display -->
              <v-col v-if="[
                              ITEM_TYPE_LAYOUT_TYPES.DATE_DISPLAY,
                              ITEM_TYPE_LAYOUT_TYPES.TIME_DISPLAY,
                            ].indexOf(item.type) !== -1"
                     class="py-0">
                <DisplayTextField :label="item.label"
                                  :hint="item.hint"
                                  :value="$parseDate(getDisplayValue(item), item.format)"></DisplayTextField>
              </v-col>

              <!-- Text Input -->
              <v-col v-if="item.type === ITEM_TYPE_LAYOUT_TYPES.TEXT">
                <v-text-field outlined
                              :label="item.label"
                              :hint="item.hint"
                              :persistent-hint="!!item.hint"
                              hide-details="auto"
                              :value="getDisplayValue(item)"
                              @input="onTextInput(item, $event)"
                              :rules="item.required ? requiredRules : []"></v-text-field>
              </v-col>

              <!-- Recurring -->
              <v-col cols="12"
                     v-if="item.type === ITEM_TYPE_LAYOUT_TYPES.RECURRENCE"
                     class="py-0">
                <v-checkbox :label="item.label"
                            v-model="showBookingDetailRecurringOptions"></v-checkbox>
              </v-col>
              <v-col cols="12"
                     v-if="item.type === ITEM_TYPE_LAYOUT_TYPES.RECURRENCE">
                <v-form ref="bookingDetailRecurringForm">
                  <v-row v-if="showBookingDetailRecurringOptions">
                    <v-col>
                      <DatePickerInput label="End Date"
                                       v-if="showBookingDetailRecurringOptions"
                                       :min="selectedDate"
                                       :max="aYearFromNow"
                                       v-model="bookingDetailRecurringEndDate"
                                       :rules="requiredRules"
                                       hide-details="auto"></DatePickerInput>
                    </v-col>
                  </v-row>
                  <v-row v-if="showBookingDetailRecurringOptions">
                    <v-col>
                      <CronInput outlined
                                 label="Recurrence"
                                 hide-details="auto"
                                 v-model="bookingDetailRecurrence"
                                 :rules="requiredRules"></CronInput>
                    </v-col>
                  </v-row>
                  <v-row v-if="bookingDetailRecurringUnavailableCount >= 0"
                         class="text-left text-subtitle-1">
                    <v-col>
                      <v-icon v-if="bookingDetailRecurringUnavailableCount === 0"
                              color="green">fas fa-check
                      </v-icon>
                      <v-icon v-else
                              color="red">fas fa-exclamation-triangle
                      </v-icon>
                      {{
                        bookingDetailRecurringUnavailableCount === 0 ? 'ALL AVAILABLE' : `UNAVAILABLE TIMESLOT: ${bookingDetailRecurringUnavailableCount}`
                      }}

                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon
                                 v-bind="attrs"
                                 v-on="on"
                                 @click="bookingDetailRecurringCalendarShown = true">
                            <v-icon color="grey">
                              fas fa-search
                            </v-icon>
                          </v-btn>
                        </template>
                        See Details
                      </v-tooltip>
                    </v-col>
                  </v-row>

                  <BGModal :shown="bookingDetailRecurringCalendarShown"
                           content-class="search"
                           :persistent="false"
                           @dismiss="bookingDetailRecurringCalendarShown = false">
                    <template #content>
                      <v-container class="bg-white pa-4 rounded-lg">
                        <RecurringAvailabilityGrid :start-date="selectedDate"
                                                   :end-date="bookingDetailRecurringEndDate"
                                                   :start-time="selectedSessionStart"
                                                   :end-time="selectedSessionEnd"
                                                   :recurrence="bookingDetailRecurrence"
                                                   :extra-params="{item_id: selectedItemId}"
                                                   :refresh-trigger="bookingDetailRecurringCalendarShown"
                                                   @click:category="showItemDescription"
                                                   hide-header></RecurringAvailabilityGrid>
                      </v-container>
                    </template>
                    <template #footer>
                      <v-row no-gutters>
                        <v-btn color="secondary"
                               @click="bookingDetailRecurringCalendarShown = false"
                               class="mr-3"
                               depressed
                               large>
                          {{ $t('cancel') }}
                        </v-btn>
                        <v-spacer></v-spacer>
                      </v-row>
                    </template>
                  </BGModal>
                </v-form>
              </v-col>

              <!-- Account Select -->
              <v-col v-if="item.type === ITEM_TYPE_LAYOUT_TYPES.ACCOUNTS">
                <MultipleAccountInput v-model="hostIds"
                                      :label="item.label"
                                      :hint="item.hint"
                                      :fields="item.fields"></MultipleAccountInput>
              </v-col>

              <!-- RSVP -->
              <v-col v-if="item.type === ITEM_TYPE_LAYOUT_TYPES.RSVP">
                <v-input v-model="invitations"
                         :hint="item.hint"
                         :persistent-hint="!!item.hint"
                         hide-details="auto"
                         :rules="item.disable_rsvp_role ? [] : invitationRules">
                  <v-container fluid class="pa-0">
                    <RsvpInput :label="item.label"
                               :hint="item.hint"
                               v-model="invitations"
                               :disable-roles="item.disable_rsvp_role"></RsvpInput>
                  </v-container>
                </v-input>
              </v-col>

              <!-- ADDITIONAL ITEM -->
              <v-col v-if="item.type === ITEM_TYPE_LAYOUT_TYPES.ADDITIONAL_ITEM">
                <v-input hide-details="auto"
                         v-model="additionalItems[item.id]">
                  <!--TEXT-->
                  <v-text-field outlined
                                clearable
                                :rules="item.required ? requiredRules : []"
                                hide-details="auto"
                                :placeholder="itemTypeAdditionalItemMap[item.id].name"
                                :label="itemTypeAdditionalItemMap[item.id].name"
                                :hint="item.hint"
                                :persistent-hint="Boolean(item.hint)"
                                v-model="additionalItems[item.id]"
                                v-if="itemTypeAdditionalItemMap[item.id].type === ITEM_TYPE_ADDITIONAL_ITEM_TYPE.TEXT"></v-text-field>

                  <!--NUMBER-->
                  <v-text-field outlined
                                clearable
                                :rules="itemTypeAdditionalItemMap[item.id].required ? requiredRules : []"
                                hide-details="auto"
                                type="number"
                                min="0"
                                :placeholder="itemTypeAdditionalItemMap[item.id].name"
                                :label="itemTypeAdditionalItemMap[item.id].name"
                                :hint="item.hint"
                                :persistent-hint="Boolean(item.hint)"
                                v-model="additionalItems[item.id]"
                                v-if="itemTypeAdditionalItemMap[item.id].type === ITEM_TYPE_ADDITIONAL_ITEM_TYPE.NUMBER"></v-text-field>

                  <!--DATE-->
                  <DatePickerInput :label="itemTypeAdditionalItemMap[item.id].name"
                                   :hint="item.hint"
                                   :persistent-hint="Boolean(item.hint)"
                                   :rules="itemTypeAdditionalItemMap[item.id].required ? requiredRules : []"
                                   v-model="additionalItems[item.id]"
                                   v-if="itemTypeAdditionalItemMap[item.id].type === ITEM_TYPE_ADDITIONAL_ITEM_TYPE.DATE"></DatePickerInput>

                  <!--TIME-->
                  <TimePickerInput :label="itemTypeAdditionalItemMap[item.id].name"
                                   :hint="item.hint"
                                   :persistent-hint="Boolean(item.hint)"
                                   hide-details="auto"
                                   :rules="itemTypeAdditionalItemMap[item.id].required ? requiredRules : []"
                                   use-native
                                   v-model="additionalItems[item.id]"
                                   v-if="itemTypeAdditionalItemMap[item.id].type === ITEM_TYPE_ADDITIONAL_ITEM_TYPE.TIME"></TimePickerInput>

                  <!--BOOLEAN-->
                  <v-checkbox :label="itemTypeAdditionalItemMap[item.id].name"
                              :hint="item.hint"
                              :persistent-hint="Boolean(item.hint)"
                              hide-details="auto"
                              v-model="additionalItems[item.id]"
                              v-if="itemTypeAdditionalItemMap[item.id].type === ITEM_TYPE_ADDITIONAL_ITEM_TYPE.BOOLEAN"></v-checkbox>

                  <!--OPTION-->
                  <v-select outlined
                            :label="itemTypeAdditionalItemMap[item.id].name"
                            :hint="item.hint"
                            :persistent-hint="Boolean(item.hint)"
                            hide-details="auto"
                            :rules="itemTypeAdditionalItemMap[item.id].required ? requiredRules : []"
                            :items="getItemTypeAdditionalItemOptions(itemTypeAdditionalItemMap[item.id])"
                            v-model="additionalItems[item.id]"
                            v-if="itemTypeAdditionalItemMap[item.id].type === ITEM_TYPE_ADDITIONAL_ITEM_TYPE.OPTIONS"></v-select>
                </v-input>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </template>
      <template #footer>
        <v-row no-gutters>
          <v-btn color="secondary"
                 @click="bookingDetailModalShown = false"
                 class="mr-3"
                 depressed
                 large>
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary"
                 @click="$refs.bookingDetailForm.validate() && bookSessions()"
                 class="mr-3"
                 depressed
                 large>
            SUBMIT
          </v-btn>
        </v-row>
      </template>
    </BGModal>

    <v-footer app
              inset
              class="pr-6 py-3 bg-white"
              v-if="showCalendar && selectedDate">
      <v-container fluid class="pa-0">
        <v-row class="font-weight-bold align-end">
          <v-col cols="12" md="6" class="d-flex justify-center justify-md-start"
                 v-if="$vuetify.breakpoint.mdAndUp">
            <label class="item-type-label">
              {{ itemType.name }}
            </label>
            <v-tooltip top
                       color="grey lighten-4"
                       v-if="itemType.remarks">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon
                       color="red"
                       v-bind="attrs"
                       v-on="on">
                  <v-icon
                      color="red">
                    fas fa-info-circle
                  </v-icon>
                </v-btn>
              </template>
              <div v-html="itemType.remarks"></div>
            </v-tooltip>
          </v-col>
          <v-col cols="12" md="6" class="d-flex justify-center justify-md-end">
            <v-btn tile
                   depressed
                   small
                   class="mr-2"
                   color="lightgrey"
                   @click="prevDay">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>

            <v-dialog ref="dialog"
                      :close-on-content-click="false"
                      :return-value.sync="selectedDate"
                      transition="scale-transition"
                      offset-y
                      width="290px">
              <template v-slot:activator="{ on }">
                <label class="d-flex align-center justify-center flex-grow-1"
                       v-on="on">
                  {{ $parseDate(selectedDate, 'yyyy-MM-dd (ccc)') }}
                </label>
              </template>
              <v-date-picker :max="aYearFromNow"
                             v-model="dialogDate"
                             scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="$refs.dialog.save(dialogDate)">OK</v-btn>
              </v-date-picker>
            </v-dialog>
            <v-btn tile
                   depressed
                   small
                   color="lightgrey"
                   @click="nextDay">
              <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" class="d-flex py-4 pa-md-0">
            <div class="d-flex align-center flex-grow-1 pl-md-4 text-left font-weight-bold">
              {{ selectedItem ? selectedItem.name : '' }}
            </div>
            <div class="d-flex align-center flex-grow-0 pr-md-8">
              {{ selectedSessionTimeLabel }}
            </div>
          </v-col>
          <v-col class="text-right" cols="12" md="6">
            <v-btn color="primary"
                   block
                   :disabled="selectedSessionIds.length === 0 && !selectedItemId"
                   @click="showBookingDetailModal">BOOK
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-container>

  <!--  <BannerFrame -->
  <!--               :label="itemType.name"-->
  <!--               :src="$getURL(itemType.banner)"-->
  <!--               no-padding-->
  <!--               hide-banner>-->
  <!--    -->
  <!--  </BannerFrame>-->
</template>

<script>
import {computed, onMounted, ref, watch} from '@vue/composition-api';
import BannerFrame from "@/components/BannerFrame";
import {BookingRecord, Item, ItemType, Location, Region} from "@/store/models";
import {DateTime} from 'luxon';
import DatePickerInput from "@/components/DatePickerInput";
import {ACTION_TYPES} from "@/store/types";
import _ from 'lodash';
import {
  INVITATION_ROLE,
  ITEM_TYPE_ADDITIONAL_ITEM_TYPE,
  ITEM_TYPE_ATTRIBUTE_TYPE, ITEM_TYPE_LAYOUT_KEYS,
  ITEM_TYPE_LAYOUT_TYPES,
  SESSION_STATUS
} from "@/constants";
import TimePickerInput from "@/components/TimePickerInput";
import AvailabilityGrid from "@/components/AvailabilityGrid";
import BGModal from "@/components/BGModal";
import UserSelector from "@/components/UserSelector";
import ItemDetailModal from "@/components/ItemDetailModal";
import RecurringAvailabilityGrid from "@/components/RecurringAvailabilityGrid";
import $ from 'jquery';
import CronInput from "@/components/CronInput";
import Vue from 'vue';
import MultipleAccountAutoComplete from "@/components/MultipleAccountAutoComplete";
import DisplayTextField from "@/components/DisplayTextField";
import RsvpInput from "@/components/RsvpInput";
import MultipleAccountInput from "@/components/MultipleAccountInput";

const SEARCH_ON_CHANGE = true;

export default {
  name: 'Search',
  components: {
    MultipleAccountInput,
    RsvpInput,
    DisplayTextField,
    MultipleAccountAutoComplete,
    CronInput,
    RecurringAvailabilityGrid,
    ItemDetailModal, UserSelector, BGModal, AvailabilityGrid, TimePickerInput, DatePickerInput, BannerFrame
  },
  setup(props, {root}) {
    const myAccountGroups = computed(() => {
      return root.$store.getters.account.account_groups;
    })

    const itemTypeId = computed(() => root.$route.params.itemTypeId);
    const itemType = computed(() => {
      let t = ItemType.query().withAllRecursive().where('id', itemTypeId.value).first();
      if (t) {
        return {
          ...t,
          imgSrc: {
            'Meeting Room': require('../assets/meeting_room.png'),
            'Lactation Room': require('../assets/lactation_room.png'),
            'Car': require('../assets/car.png'),
          }[t.name]
        }
      }
    });

    const mode = ref('single');
    const recurringBooking = function () {
      selectedItemId.value = null;
      selectedSessionIds.value = [];
      selectedSessions.value = [];
      mode.value = 'recurring';

      Vue.nextTick(() => {
        (filterForm.value.validate() || showFilter()) && search();
      })
    };
    const singleBooking = function () {
      selectedItemId.value = null;
      selectedSessionIds.value = [];
      selectedSessions.value = []
      mode.value = 'single';

      Vue.nextTick(() => {
        (filterForm.value.validate() || showFilter()) && search();
      })
    }

    // const filterShown = ref(root.$vuetify.breakpoint.smAndUp);
    const saveFilter = function () {
      localStorage.setItem(`filter_${itemTypeId.value}_date`, selectedDate.value);
      if (selectedLocationId.value) {
        localStorage.setItem(`filter_${itemTypeId.value}_location`, selectedLocationId.value);
      } else {
        localStorage.removeItem(`filter_${itemTypeId.value}_location`);
      }
      if (selectedRegionId.value) {
        localStorage.setItem(`filter_${itemTypeId.value}_region`, selectedRegionId.value);
      } else {
        localStorage.removeItem(`filter_${itemTypeId.value}_region`);
      }
    };
    const restoreFilter = function () {
      selectedDate.value = localStorage.getItem(`filter_${itemTypeId.value}_date`) || DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd');
      selectedLocationId.value = localStorage.getItem(`filter_${itemTypeId.value}_location`);
      selectedRegionId.value = localStorage.getItem(`filter_${itemTypeId.value}_region`);
    };
    // const filterShown = ref(root.$vuetify.breakpoint.smAndUp);
    const filterShown = ref(false);
    const showFilter = function () {
      filterShown.value = true;
      return false
    }
    const filterDescription = computed(() => {
      return `${selectedDate.value}`
    });
    const selectedDate = ref(null);
    const backToToday = function () {
      selectedDate.value = DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd');
    }
    const dialogDate = ref(DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'));
    const prevDay = function () {
      selectedDate.value = DateTime.fromFormat(selectedDate.value, 'yyyy-MM-dd').minus({days: 1}).toFormat('yyyy-MM-dd');
    };
    const nextDay = function () {
      selectedDate.value = DateTime.fromFormat(selectedDate.value, 'yyyy-MM-dd').plus({days: 1}).toFormat('yyyy-MM-dd');
    };
    watch(() => selectedDate.value, (newValue) => {
      if (newValue) {
        dialogDate.value = newValue;
        saveFilter();
        search();
      }
    })

    const today = ref(DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'));
    const aYearFromNow = ref(DateTime.fromJSDate(new Date()).plus({days: 730}).toFormat('yyyy-MM-dd'));
    const selectedStartDate = ref(DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'));
    const selectedEndDate = ref(null);
    const selectedEndDateRules = ref([
      value => (mode.value === 'single' || !!value) || 'Required',
    ])
    const selectedFromTime = ref(null);
    watch(() => selectedFromTime.value, () => {
      (SEARCH_ON_CHANGE && mode.value === 'single') ? search() : null;
    });
    const selectedFromTimeRules = ref([
      value => (mode.value === 'single' || !!value) || 'Required',
    ]);
    const selectedToTime = ref(null);
    watch(() => selectedToTime.value, () => {
      (SEARCH_ON_CHANGE && mode.value === 'single') ? search() : null;
    });
    const selectedToTimeRules = ref([
      ...selectedFromTimeRules.value,
      value => (!selectedFromTime.value || value > selectedFromTime.value) || 'Invalid Time',
    ])

    const consecutiveHours = ref('1');

    const recurrence = ref(null);

    const relatedLocations = ref([]);
    const relatedRegions = ref([]);
    const allLocationsRoomBookingSettings = ref([]);

    const locationOptions = computed(() => {
      return _.sortBy(relatedLocations.value, 'ordering').map((l) => {
        return {text: l.name, value: l.id}
      });
    });
    const showLocationFilter = computed(() => {
      return locationOptions.value.length >= 2;
    });
    const selectedLocationId = ref(null);
    watch(() => locationOptions.value, (newValue) => {
      // Check if saved location is related
      if (selectedLocationId.value && _.findIndex(newValue, {value: selectedLocationId.value}) === -1) {
        selectedLocationId.value = null;
      }
    });
    const onLocationChange = function () {
      selectedRegionId.value = null;
      saveFilter();
      (SEARCH_ON_CHANGE && mode.value === 'single') ? search() : null;
    }

    const regionOptions = computed(() => {
      if (selectedLocationId.value) {
        return relatedRegions.value
            .filter((r) => r.location_id === selectedLocationId.value)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((r) => {
              return {text: r.name, value: r.id}
            })
      }

      return [];
    });
    const showRegionFilter = computed(() => {
      return regionOptions.value.length >= 2;
    });
    const selectedRegionId = ref(null);
    watch(() => selectedRegionId.value, () => {
      saveFilter();
      (SEARCH_ON_CHANGE && mode.value === 'single') ? search() : null;
    })

    const getRelatedLocations = async function () {
      let response = await root.$store.dispatch(ACTION_TYPES.CALL_API, {
        url: 'client/type_locations/',
        params: {
          item_type_id: itemTypeId.value,
        },
      });

      relatedLocations.value = response.body.locations;
      allLocationsRoomBookingSettings.value = getLocationRoomBookingSettingsMapping();
      relatedRegions.value = response.body.regions;
    }

    const getLocationRoomBookingSettingsMapping = () => {
      let settings = [];

      let locations = Location.query().withAllRecursive().orderBy('name').get();
      _.each(locations, (l) => {
        let regions = Region.query().withAllRecursive().where('location_id', l.id).orderBy('name').all();
        // let regions = Region.query().withAllRecursive().all();
        // regions = _.filter(regions, (r) => r.location_id === l.id);

        let item_ids = [];
        _.each(regions, (r) => {
          item_ids = _.concat(item_ids, _.map(r.items, 'id'))
        })

        let location_room_booking_settings = _.find(relatedLocations.value, { id: l.id })?.room_booking_settings || null

        settings.push({
          location_id: l.id,
          room_booking_settings: location_room_booking_settings,
          item_ids: _.uniq(item_ids)
        });
      })

      return settings;
    }

    watch(() => itemType.value, (newValue) => {
      if (newValue) {
        if (newValue.default_show_filter) {
          filterShown.value = true;
        }
        if (newValue.save_previous_filter) {
          restoreFilter();
        } else {
          selectedDate.value = DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd')
        }
        root.$store.dispatch(ACTION_TYPES.SET_APP_BAR_TITLE, newValue.name);
        if (!newValue.save_previous_filter || (!selectedLocationId.value && !selectedRegionId.value)) {
          selectedLocationId.value = newValue.default_location;
          selectedRegionId.value = newValue.default_region;
        }
      }
    }, {immediate: true});

    // Attributes
    const attributeGroups = computed(() => {
      if (itemType.value) {
        let itemTypeAttributes = itemType.value.attributes;

        let attributesWithoutCategory = [];
        let categories = {};

        itemTypeAttributes.forEach((a) => {
          if (a.category) {
            if (!(a.category in categories)) {
              categories[a.category] = [];
            }
            categories[a.category].push(a);
          } else {
            attributesWithoutCategory.push(a);
          }
        });

        return [{
          category: '',
          attributes: _.sortBy(attributesWithoutCategory, ['ordering', 'name'])
        }].concat(Object.keys(categories).map((category) => {
          return {category, attributes: _.sortBy(categories[category], ['ordering', 'name'])};
        }));
      }

      return [];
    });
    const expandedCategories = ref([]);
    const attributeGroupExpanded = function (group) {
      return _.indexOf(expandedCategories.value, group.category) !== -1
    };
    const expandAttributeGroup = function (group) {
      if (expandedCategories.value.indexOf(group.category) === -1) {
        expandedCategories.value = _.concat(expandedCategories.value, [group.category]);
      }
    };
    const collapseAttributeGroup = function (group) {
      if (expandedCategories.value.indexOf(group.category) !== -1) {
        expandedCategories.value.splice(expandedCategories.value.indexOf(group.category), 1);
      }
    };
    const getAttributeGroupAttributes = function (group) {
      if (attributeGroupExpanded(group) || group.attributes.length <= 3) {
        return group.attributes;
      } else {
        return _.slice(group.attributes, 0, 3);
      }
    };
    const attributeFilter = ref({});
    const onRangeAttributeChange = function (attributeId, value, key) {
      let filter = attributeFilter.value;
      if (!(attributeId in filter)) {
        filter[attributeId] = {};
      }
      if (value === null) {
        delete filter[attributeId][key];
      } else {
        filter[attributeId][key] = value;
      }
      attributeFilter.value = filter;
    };

    const showCalendar = ref(false);

    const isFormValid = ref(false);
    const requiredRules = ref([
      value => !!value || 'Required',
    ]);

    const filterForm = ref();
    const refreshTrigger = ref(false);
    const scrollFilterToTop = function () {
      $('.filter-content-container').scrollTop(0);
      return false;
    }
    const search = async function () {
      refreshTrigger.value = true;

      showCalendar.value = true;
      selectedSessionIds.value = [];
      selectedSessions.value = [];
    };

    const showFloatingInfo = ref(false);

    onMounted(() => {
      // getRelatedLocations();
    });

    // Calendar
    const selectedSessionIds = ref([]);
    const selectedSessions = ref([]);
    const selectedSessionStart = computed(() => {
      if (selectedSessions.value.length > 0) {
        let sorted = _.sortBy(selectedSessions.value, ['start']);
        return _.head(sorted).start.split(' ')[1];
      }
    })
    const selectedSessionEnd = computed(() => {
      if (selectedSessions.value.length > 0) {
        let sorted = _.sortBy(selectedSessions.value, ['start']);
        return _.last(sorted).end.split(' ')[1];
      }
    })
    const selectedSessionTimeLabel = computed(() => {
      if (selectedSessions.value.length > 0) {
        return `${selectedSessionStart.value} - ${selectedSessionEnd.value}`
      }
    })

    const selectedItemId = ref(null);
    const selectedItem = computed(() => {
      if (selectedItemId.value) {
        return Item.find(selectedItemId.value);
      }
    });

    const itemDescriptionMenuShown = ref(false);
    const showingItemId = ref(null);
    const menuX = ref(0);
    const menuY = ref(0);
    const showItemDescription = function ({event, item_id}) {
      showingItemId.value = item_id;

      if (event.touches) {
        // Touch Device
        menuX.value = event.touches[0].clientX;
        menuY.value = event.touches[0].clientY;
      } else {
        menuX.value = event.clientX;
        menuY.value = event.clientY;
      }

      setTimeout(() => {
        itemDescriptionMenuShown.value = true;
      }, 10)
    }

    // Booking Details
    const showBookingDetailModal = function () {
      // Features
      enabledFeatures.value = JSON.parse(itemType.value.enabled_features);
      requiredFeatures.value = JSON.parse(itemType.value.required_features);

      const item = Item.find(selectedItemId.value);
      if (item.enabled_features) {
        enabledFeatures.value = JSON.parse(item.enabled_features);
      }
      if (item.required_features) {
        requiredFeatures.value = JSON.parse(item.required_features);
      }
      additionalItems.value = {};

      //
      showBookingDetailRecurringOptions.value = false;
      bookingDetailRecurringEndDate.value = null;
      bookingDetailRecurrence.value = null;
      bookingDetailRecurringUnavailableCount.value = -1;

      bookingDetailModalShown.value = true;
    };
    const bookingDetailModalShown = ref(false);
    const itemTypeLayoutDefinitions = computed(() => {
      if (itemType.value) {
        return JSON.parse(itemType.value.booking_preview_layout_definition);
      }
      return [];
    })
    const getDisplayValue = function (item) {
      return {
        [ITEM_TYPE_LAYOUT_KEYS.ITEM_TYPE_NAME]: itemType.value.name,
        [ITEM_TYPE_LAYOUT_KEYS.ITEM_NAME]: selectedItem.value.name,
        [ITEM_TYPE_LAYOUT_KEYS.BOOKING_TITLE]: bookingTitle.value,
        [ITEM_TYPE_LAYOUT_KEYS.BOOKING_DATE]: selectedDate.value,
        [ITEM_TYPE_LAYOUT_KEYS.BOOKING_START_TIME]: selectedSessionStart.value,
        [ITEM_TYPE_LAYOUT_KEYS.BOOKING_END_TIME]: selectedSessionEnd.value,
      }[item.key];
    }
    const onTextInput = function (item, value) {
      if (item.key === ITEM_TYPE_LAYOUT_KEYS.BOOKING_TITLE) {
        bookingTitle.value = value;
      }
    }
    const enabledFeatures = ref([]);
    const requiredFeatures = ref([]);
    const bookingTitle = ref(null);
    const titleRules = ref([
      ...requiredRules.value,
      value => value && value.replace(/ /g, '').length >= 10 || 'Please provide more detailed meeting purpose (Min. 10 Characters)',
    ])
    const invitations = ref([]);
    const invitationRules = ref([
      value => !!value && value.length > 0 || 'Required',
      value => !!value && _.some(value, (i) => i.role === INVITATION_ROLE.CHAIR) || 'Chairman is Required',
    ])
    const hostIds = ref([]);

    //
    const showBookingDetailRecurringOptions = ref(false);
    const bookingDetailRecurringEndDate = ref(null);
    const bookingDetailRecurrence = ref(null);

    watch([bookingDetailRecurringEndDate, bookingDetailRecurrence], ([newDate, newRecurrence], [oldDate, oldRecurrence]) => {
      if (showBookingDetailRecurringOptions.value && newDate && newRecurrence) {
        loadBookingDetailRecurringPreview();
      }
    })
    const bookingDetailRecurringUnavailableCount = ref(-1);
    const loadBookingDetailRecurringPreview = async function () {
      bookingDetailRecurringUnavailableCount.value = -1;
      const response = await root.$store.dispatch(ACTION_TYPES.CALL_API, {
        url: 'client/search_recurring/',
        params: {
          item_id: selectedItemId.value,
          from_date: DateTime.fromFormat(selectedDate.value, 'yyyy-MM-dd').toISO(),
          to_date: DateTime.fromFormat(bookingDetailRecurringEndDate.value, 'yyyy-MM-dd').startOf('day').plus({days: 1}).toISO(),
          from_time: selectedSessionStart.value,
          to_time: selectedSessionEnd.value,
          recurrence: bookingDetailRecurrence.value,
        }
      });

      // Process sessions
      let unavailableCount = {};

      let timeslots = response.body.sessions.map((s) => {
        if (!(s.item_id in unavailableCount)) {
          unavailableCount[s.item_id] = 0;
        }

        let status = SESSION_STATUS.AVAILABLE;

        // Check if all overlapping sessions are available
        let all_available = true;

        // Check if timeslot has been covered by consecutive sessions
        let consecutive_start = null,
            consecutive_end = null;

        s.sessions.forEach((session) => {
          if (session.require_privilege && _.intersection(_.find(response.body.items, {id: s.item_id}).privileged_groups, myAccountGroups.value).length === 0) {
            all_available = false;
          }

          if (session.status !== SESSION_STATUS.AVAILABLE) {
            all_available = false;
          }

          if (consecutive_start === null) {
            consecutive_start = session.start;
            consecutive_end = session.end;
          } else if (session.start === consecutive_end) {
            consecutive_end = session.end;
          }
        })

        if (!all_available || (consecutive_start > s.start) || (consecutive_end < s.end) || !s.sessions.length) {
          unavailableCount[s.item_id] += 1;
          status = SESSION_STATUS.OCCUPIED;
        }
      });

      bookingDetailRecurringUnavailableCount.value = unavailableCount[selectedItemId.value];
    }
    const bookingDetailRecurringCalendarShown = ref(false);

    // Additional Items
    const itemTypeAdditionalItems = computed(() => {
      if (itemType.value) {
        return _.sortBy(itemType.value.additional_items, (i) => parseInt(i.ordering, 10))
      }

      return [];
    });
    const itemTypeAdditionalItemMap = computed(() => {
      let result = {};

      itemTypeAdditionalItems.value.forEach((i) => {
        result[i.id] = i;
      })

      return result;
    })
    const getItemTypeAdditionalItemOptions = function (itemTypeAdditionalItem) {
      if (itemTypeAdditionalItem.type === ITEM_TYPE_ADDITIONAL_ITEM_TYPE.OPTIONS) {
        let meta = JSON.parse(itemTypeAdditionalItem.meta);

        return meta.options.map((o) => {
          return {text: o, value: o};
        });
      }

      return [];
    }
    const additionalItems = ref({});

    const bookSessions = async function () {
      if (await root.$dialog.confirm({text: root.$t('booking.book_confirm_msg')})) {
        let invitationsByRole = _.groupBy(invitations.value, 'role');
        let response
        if (mode.value === 'single') {
          if (showBookingDetailRecurringOptions.value) {
            response = await root.$store.dispatch(ACTION_TYPES.BOOK_RECURRING_SESSIONS, {
              item_id: selectedItemId.value,
              from_date: DateTime.fromFormat(selectedDate.value, 'yyyy-MM-dd').toISO(),
              to_date: DateTime.fromFormat(bookingDetailRecurringEndDate.value, 'yyyy-MM-dd').startOf('day').plus({days: 1}).toISO(),
              from_time: selectedSessionStart.value,
              to_time: selectedSessionEnd.value,
              recurrence: bookingDetailRecurrence.value,

              item_type_id: root.$route.params.itemTypeId,
              location_id: selectedLocationId.value,
              region_id: selectedRegionId.value,
              attributes: attributeFilter.value,

              title: bookingTitle.value,
              host_ids: hostIds.value,

              chair_ids: (invitationsByRole[INVITATION_ROLE.CHAIR] || []).map((i) => i.account.id),
              required_participant_ids: (invitationsByRole[INVITATION_ROLE.REQUIRED_PARTICIPANT] || []).map((i) => i.account.id),
              optional_participant_ids: (invitationsByRole[INVITATION_ROLE.OPTIONAL_PARTICIPANT] || []).map((i) => i.account.id),

              additional_items: additionalItems.value,
            });
          } else {
            response = await root.$store.dispatch(ACTION_TYPES.BOOK_SESSIONS, {
              session_ids: selectedSessionIds.value,
              title: bookingTitle.value,
              host_ids: hostIds.value,

              chair_ids: (invitationsByRole[INVITATION_ROLE.CHAIR] || []).map((i) => i.account.id),
              required_participant_ids: (invitationsByRole[INVITATION_ROLE.REQUIRED_PARTICIPANT] || []).map((i) => i.account.id),
              optional_participant_ids: (invitationsByRole[INVITATION_ROLE.OPTIONAL_PARTICIPANT] || []).map((i) => i.account.id),

              additional_items: additionalItems.value,
            });
          }
        } else if (mode.value === 'recurring') {
          response = await root.$store.dispatch(ACTION_TYPES.BOOK_RECURRING_SESSIONS, {
            item_id: selectedItemId.value,
            from_date: DateTime.fromFormat(selectedStartDate.value, 'yyyy-MM-dd').toISO(),
            to_date: DateTime.fromFormat(selectedEndDate.value, 'yyyy-MM-dd').startOf('day').plus({days: 1}).toISO(),
            from_time: selectedFromTime.value,
            to_time: selectedToTime.value,
            recurrence: recurrence.value,

            item_type_id: root.$route.params.itemTypeId,
            location_id: selectedLocationId.value,
            region_id: selectedRegionId.value,
            attributes: attributeFilter.value,

            title: bookingTitle.value,
            host_ids: hostIds.value,

            chair_ids: (invitationsByRole[INVITATION_ROLE.CHAIR] || []).map((i) => i.account.id),
            required_participant_ids: (invitationsByRole[INVITATION_ROLE.REQUIRED_PARTICIPANT] || []).map((i) => i.account.id),
            optional_participant_ids: (invitationsByRole[INVITATION_ROLE.OPTIONAL_PARTICIPANT] || []).map((i) => i.account.id),

            additional_items: additionalItems.value,
          });
        }

        if (response.body.booking_record) {
          root.$showAlertMessage(root.$t('booking.book_success_msg'));

          await BookingRecord.insert({
            data: [response.body.booking_record],
          });

          root.$router.push({name: 'booking-details', params: {bookingId: response.body.booking.id}});
        }
      }
    };

    watch(() => itemTypeId.value, () => {
      getRelatedLocations();
      showCalendar.value = false;
      // selectedLocationId.value = localStorage.getItem(`filter_${root.$route.params.itemTypeId}_location`);
      // selectedRegionId.value = localStorage.getItem(`filter_${root.$route.params.itemTypeId}_region`);
      attributeFilter.value = {};
      search();
    }, {immediate: true})

    return {
      itemType,

      mode,
      recurringBooking,
      singleBooking,

      filterShown,
      filterDescription,
      selectedDate,
      backToToday,
      dialogDate,
      prevDay,
      nextDay,

      today,
      aYearFromNow,
      selectedStartDate,
      selectedEndDate,
      selectedEndDateRules,
      selectedFromTime,
      selectedFromTimeRules,
      selectedToTime,
      selectedToTimeRules,

      consecutiveHours,

      recurrence,

      locationOptions,
      showLocationFilter,
      selectedLocationId,
      onLocationChange,

      regionOptions,
      showRegionFilter,
      selectedRegionId,

      attributeGroups,
      attributeGroupExpanded,
      expandAttributeGroup,
      collapseAttributeGroup,
      getAttributeGroupAttributes,
      attributeFilter,
      onRangeAttributeChange,
      ITEM_TYPE_ATTRIBUTE_TYPE,

      showCalendar,
      isFormValid,
      requiredRules,

      filterForm,
      refreshTrigger,
      scrollFilterToTop,
      search,

      selectedSessionIds,
      selectedSessions,
      selectedSessionTimeLabel,
      selectedItem,
      selectedItemId,
      selectedSessionStart,
      selectedSessionEnd,

      allLocationsRoomBookingSettings,

      itemDescriptionMenuShown,
      showingItemId,
      menuX,
      menuY,
      showItemDescription,

      showBookingDetailModal,
      bookingDetailModalShown,
      itemTypeLayoutDefinitions,
      ITEM_TYPE_LAYOUT_TYPES,
      ITEM_TYPE_LAYOUT_KEYS,
      getDisplayValue,
      onTextInput,
      enabledFeatures,
      requiredFeatures,
      bookingTitle,
      titleRules,
      invitations,
      invitationRules,
      hostIds,

      showBookingDetailRecurringOptions,
      bookingDetailRecurringEndDate,
      bookingDetailRecurrence,
      bookingDetailRecurringUnavailableCount,
      bookingDetailRecurringCalendarShown,

      itemTypeAdditionalItems,
      itemTypeAdditionalItemMap,
      getItemTypeAdditionalItemOptions,
      additionalItems,
      ITEM_TYPE_ADDITIONAL_ITEM_TYPE,

      bookSessions,

      showFloatingInfo,
    }
  }
}
</script>

<style lang="less">
.search {
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  .mode-menu-btn {
    position: fixed;
    right: 12px;
    top: 20px;
    z-index: 5;
    transition: right 0.5s ease-out;

    &.filter-shown {
      right: 268px;
    }
  }

  .filter-btn {
    position: fixed;
    right: 12px;
    top: 20px;
    z-index: 10;
    transition: bottom 0.5s ease-out;

    &.single-booking {
      bottom: 140px !important;
    }
  }

  .filter-container {
    height: 100% !important;
    display: flex;
    flex-direction: column;

    .v-picker {
      background-color: transparent;

      .v-picker__body {
        background-color: transparent;
      }
    }
  }

  .bg-white {
    background-color: white;
  }

  .attribute-category-header {
    font-weight: bold;
    font-size: 120%;
  }

  .region-options {
    //font-weight: bolder;
    padding-left: 16px !important;

    &.location-options {
      font-weight: bold;
      padding-left: 8px !important;
      font-size: 110%;
    }
  }

  .v-footer {
    //z-index: 4 !important;
    //bottom: 156px !important;

    .justify-md-start, .justify-md-end {
      border-bottom: solid 1px #E5E5E5;
    }
  }

  .v-calendar-category__column-header {
    cursor: pointer;
  }
}


</style>
