<template>
  <v-autocomplete outlined
                  hide-details
                  :label="label"
                  :items="accounts"
                  v-model="selectedAccount"
                  return-object
                  :item-text="accountText"
                  :filter="filter"
                  @input="onAccountSelect">
    <template #item="{parent, item}">
      <v-list-item-content class="text-left">
        <v-list-item-title v-html="parent.genFilteredText(item.name)"></v-list-item-title>
        <!--              <v-list-item-subtitle v-text="account.username"></v-list-item-subtitle>-->
        <v-list-item-subtitle v-if="item.title"
                              v-html="parent.genFilteredText(item.title)">
        </v-list-item-subtitle>
        <v-list-item-subtitle v-if="item.mobile"
                              v-html="parent.genFilteredText(item.mobile)">
        </v-list-item-subtitle>
        <v-list-item-subtitle v-if="item.email"
                              v-html="parent.genFilteredText(item.email)">
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import {computed, onMounted, ref} from "@vue/composition-api";
import {User} from "@/store/models";
import _ from "lodash";
import {ACTION_TYPES} from "@/store/types";

export default {
  name: 'AccountAutoComplete',
  props: {
    label: String,
    accountFilter: {
      type: Function,
      default: (a) => true,
    },
    accountText: {
      type: Function,
      default: (a) => `${a.name} <${a.username}>`
    }
  },
  setup(props, {root, emit}) {
    const filter = function (item, queryText, itemText) {
      return _.reduce(queryText.split(' '), (result, q) => {
        return result && (q.length === 0 || itemText.toLowerCase().indexOf(q.toLowerCase()) !== -1);
      }, true)
    };

    const allAccounts = computed(() => User.query().orderBy('name').get());
    const accounts = computed(() => {
      return _.filter(allAccounts.value, props.accountFilter);
    });
    const selectedAccount = ref(null);
    const onAccountSelect = function () {
      emit('input', selectedAccount.value);
      selectedAccount.value = null;
    };

    onMounted(async () => {
      await root.$store.dispatch(ACTION_TYPES.GET_USERS, {});
    })

    return {
      filter,

      accounts,
      selectedAccount,
      onAccountSelect,
    }
  },
}
</script>
