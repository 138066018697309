<template>
  <v-dialog ref="menu"
            v-model="showTimePicker"
            persistent
            :return-value="time"
            width="290px">
    <template v-slot:activator="{ on }">
      <v-text-field outlined
                    :append-icon="readonly || useNative ? null: 'fas fa-clock'"
                    :label="label"
                    :placeholder="label"
                    v-model="time"
                    :readonly="!useNative"
                    :hide-details="hideDetails"
                    clearable
                    type="time"
                    v-on="readonly || useNative ? null : on"
                    :rules="rules"></v-text-field>
    </template>
    <v-time-picker v-model="time"
                   format="24hr"
                   scrollable>
      <v-btn text color="primary" @click="showTimePicker = false">Cancel</v-btn>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="clearTime">Clear</v-btn>
      <v-btn text color="primary" @click="$refs.menu.save(time)">OK</v-btn>
    </v-time-picker>
  </v-dialog>
</template>

<script>
import {ref, watch} from "@vue/composition-api";

export default {
  name: 'TimePickerInput',
  props: {
    value: String,
    label: String,
    readonly: Boolean,
    hideDetails: [Boolean, String],
    rules: Array,
    useNative: Boolean,
  },
  setup(props, {root, emit}) {
    const time = ref(null);
    const clearTime = function () {
      time.value = null;
      showTimePicker.value = false;
    };
    watch(
        time,
        (newVal, oldVal) => {
          if (newVal !== oldVal) {
            emit('input', newVal)
          }
        }
    );
    watch(() => props.value, (newValue) => {
      if (newValue) {
        time.value = newValue;
      } else {
        time.value = null;
      }
    }, {deep: true, immediate: true});

    const showTimePicker = ref(false);

    return {
      time,
      clearTime,
      showTimePicker,
    }
  }
}
</script>
