<template>
  <v-container fluid class="pa-0 fill-height">
    <RemotePaginatedList full-height
                         :headers="headers"
                         multi-sort
                         data-url="client/managed_items/"
                         data-key="items"
                         @click:row="onItemClicked"
                         :refresh-trigger.sync="refreshTrigger"
                         mobile-breakpoint="0"
                         v-bind="$attrs">
      <template v-slot:item.session_generation_limit="{value}">
        {{ value ? $parseDate(value, 'yyyy-MM-dd') : '' }}
      </template>
    </RemotePaginatedList>

    <v-dialog v-model="moreActionDialogShown"
              max-width="320px">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-btn block color="primary"
                     @click="sessionGenerationLimitModalShown = true">{{ $t('item.change_session_generation_limit') }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn block color="primary"
                     @click="showItemSchedule">{{ $t('item.show_item_schedule') }}
              </v-btn>
            </v-col>
          </v-row>
<!--          <v-row>-->
<!--            <v-col>-->
<!--              <v-btn block color="primary"-->
<!--                     @click="createAdminBooking">{{ $t('item.create_admin_booking') }}-->
<!--              </v-btn>-->
<!--            </v-col>-->
<!--          </v-row>-->
          <v-row v-if="moreActionSelectedItemEnabledFeatures.indexOf(OPTIONAL_FEATURES.ASD_EXPORT_CAR_BOOKING) !== -1">
            <v-col>
              <v-btn block color="primary"
                     @click="asdExportCarBookings">{{ $t('item.asd_export_car_booking') }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn block
                     @click="moreActionDialogShown = false">{{ $t('cancel') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="sessionGenerationLimitModalShown"
              persistent
              width="290px">
      <v-card>
        <v-card-title>{{ $t('item.change_session_generation_limit') }}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-select outlined
                        :label="$t('item.session_generate_from')"
                        v-model="itemSessionGenerateFrom"
                        :items="itemSessionGenerateOptions"
                        hide-details></v-select>
            </v-col>
          </v-row>
          <v-row v-if="itemSessionGenerateFrom === 'absolute'">
            <v-col>
              <DatePickerInput v-model="itemSessionGenerateFromDate"></DatePickerInput>
            </v-col>
          </v-row>
          <v-row v-if="itemSessionGenerateFrom === 'relative'">
            <v-col>
              <v-text-field outlined
                            type="number"
                            min="0"
                            suffix="Day(s) from Today"
                            v-model="itemSessionGenerateFromDay"
                            hide-details></v-text-field>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row>
            <v-col>
              <v-select outlined
                        :label="$t('item.session_generate_until')"
                        v-model="itemSessionGenerateUntil"
                        :items="itemSessionGenerateOptions"
                        hide-details></v-select>
            </v-col>
          </v-row>
          <v-row v-if="itemSessionGenerateUntil === 'absolute'">
            <v-col>
              <DatePickerInput v-model="itemSessionGenerateUntilDate"></DatePickerInput>
            </v-col>
          </v-row>
          <v-row v-if="itemSessionGenerateUntil === 'relative'">
            <v-col>
              <v-text-field outlined
                            type="number"
                            min="0"
                            suffix="Day(s) from Today"
                            v-model="itemSessionGenerateUntilDay"
                            hide-details></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text
                 color="primary"
                 @click="sessionGenerationLimitModalShown = false">
            Cancel
          </v-btn>
          <v-btn ext
                 color="primary"
                 @click="setSessionGenerationLimit">OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <CreateAdminBookingModal :shown="createAdminBookingModalShown"
                             :item-id="moreActionSelectedItem && moreActionSelectedItem.id"
                             @cancel="createAdminBookingModalShown = false"></CreateAdminBookingModal>
  </v-container>
</template>
<script>
import {computed, ref, watch} from '@vue/composition-api';
import BannerFrame from "@/components/BannerFrame";
import RemotePaginatedList from "@/components/RemotePaginatedList";
import {ACTION_TYPES} from "@/store/types";
import CreateAdminBookingModal from "@/components/CreateAdminBookingModal";
import DatePickerInput from "@/components/DatePickerInput";
import {OPTIONAL_FEATURES} from "@/constants";
import {DateTime} from "luxon";
import DateInputDialog from "@/components/DateInputDialog";
import {saveAs} from 'file-saver';
import _ from "lodash";

export default {
  name: 'ManagedItems',
  components: {DatePickerInput, CreateAdminBookingModal, RemotePaginatedList, BannerFrame},
  setup(props, {root}) {
    const refresh = function () {
      refreshTrigger.value = true;
    };
    const refreshTrigger = ref(false);

    const headers = ref([
      {text: root.$t('item.name'), value: 'name'},
      {text: root.$t('item.location'), value: 'region.location.name'},
      {text: root.$t('item.region'), value: 'region.name'},
      // {text: root.$t('item.session_generation_limit'), value: 'session_generation_limit'},
    ]);
    const onItemClicked = function (item) {
      moreActionSelectedItem.value = item;
      moreActionDialogShown.value = true;

      if (item.session_generate_from_date) {
        itemSessionGenerateFrom.value = 'absolute';
        itemSessionGenerateFromDate.value = item.session_generate_from_date;
        itemSessionGenerateFromDay.value = 0;
      } else if (item.session_generate_from_day !== 0) {
        itemSessionGenerateFrom.value = 'relative';
        itemSessionGenerateFromDate.value = null;
        itemSessionGenerateFromDay.value = item.session_generate_from_day;
      } else {
        itemSessionGenerateFrom.value = 'system';
        itemSessionGenerateFromDate.value = null;
        itemSessionGenerateFromDay.value = 0;
      }

      if (item.session_generate_until_date) {
        itemSessionGenerateUntil.value = 'absolute';
        itemSessionGenerateUntilDate.value = item.session_generate_until_date;
        itemSessionGenerateUntilDay.value = 0;
      } else if (item.session_generate_until_day !== -1) {
        itemSessionGenerateUntil.value = 'relative';
        itemSessionGenerateUntilDate.value = null;
        itemSessionGenerateUntilDay.value = item.session_generate_until_day;
      } else {
        itemSessionGenerateUntil.value = 'system';
        itemSessionGenerateUntilDate.value = null;
        itemSessionGenerateUntilDay.value = 0;
      }
    };

    const moreActionDialogShown = ref(false);
    const moreActionSelectedItem = ref(null);
    const moreActionSelectedItemEnabledFeatures = computed(() => {
      if (moreActionSelectedItem.value) {
        let enabledFeatures = JSON.parse(moreActionSelectedItem.value.type.enabled_features);
        if (moreActionSelectedItem.value.enabled_features) {
          enabledFeatures = JSON.parse(moreActionSelectedItem.value.enabled_features);
        }
        return enabledFeatures;
      }
      return [];
    });

    // Generation Limit
    const itemSessionGenerateFrom = ref(null);
    const itemSessionGenerateFromDate = ref(null);
    const itemSessionGenerateFromDay = ref(0);
    const itemSessionGenerateUntil = ref(null);
    const itemSessionGenerateUntilDate = ref(null);
    const itemSessionGenerateUntilDay = ref(-1);
    const itemSessionGenerateOptions = ref([
      {text: root.$t('item.session_generate_follow_system'), value: 'system'},
      {text: root.$t('item.session_generate_by_date'), value: 'absolute'},
      {text: root.$t('item.session_generate_by_day'), value: 'relative'},
    ])
    const sessionGenerationLimitModalShown = ref(false);
    const selectedSessionGenerationLimit = ref(null);
    const clearSessionGenerationLimit = function () {
      selectedSessionGenerationLimit.value = null;
      setSessionGenerationLimit();
    }
    const setSessionGenerationLimit = async function () {
      if (await root.$dialog.confirm({text: root.$t('item.change_session_generation_limit_confirm_msg')})) {
        await root.$store.dispatch(ACTION_TYPES.SET_ITEM_SESSION_GENERATION_LIMIT, {
          item_id: moreActionSelectedItem.value.id,

          session_generate_from_date: itemSessionGenerateFrom.value === 'absolute' ? itemSessionGenerateFromDate.value : null,
          session_generate_from_day: itemSessionGenerateFrom.value === 'relative' ? itemSessionGenerateFromDay.value : 0,
          session_generate_until_date: itemSessionGenerateUntil.value === 'absolute' ? itemSessionGenerateUntilDate.value : null,
          session_generate_until_day: itemSessionGenerateUntil.value === 'relative' ? itemSessionGenerateUntilDay.value : -1,
        });
        root.$showAlertMessage(root.$t('item.change_session_generation_limit_success_msg'));
        sessionGenerationLimitModalShown.value = false;
        moreActionDialogShown.value = false;
        refresh();
      }
    };

    //
    const showItemSchedule = function () {
      root.$router.push({
        name: 'item-schedule',
        params: {itemId: moreActionSelectedItem.value.id}
      })
    }

    // Admin Booking
    const createAdminBookingModalShown = ref(false);
    const createAdminBooking = function () {
      createAdminBookingModalShown.value = true;
    };

    // ASD Export Car Bookings
    const asdExportCarBookings = async function () {
      const date = await root.$dialog.showAndWait(DateInputDialog, {
        prefill: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
        showClose: false,
        persistent: true,
      });

      if (!date) {
        return;
      }

      let dayStart = DateTime.fromFormat(date, 'yyyy-MM-dd').startOf('day');
      let dayEnd = dayStart.endOf('day');

      const response = await root.$store.dispatch(ACTION_TYPES.CALL_API, {
        url: 'client/managed_items/bookings/',
        params: {
          item_id: moreActionSelectedItem.value.id,
          booking_start: dayStart.toISO(),
          booking_end: dayEnd.toISO(),
        },
      });

      if (response.body.success) {
        let itemAttributeMap = {};
        moreActionSelectedItem.value.type.attributes.forEach((a) => {
          let itemAttribute = _.find(moreActionSelectedItem.value.attributes, {type_attribute: a.id});
          itemAttributeMap[a.name] = itemAttribute ? itemAttribute.value : 'False';
        })

        const minWidth = 40;
        const divider = ''.padEnd(minWidth * 2, '=') + '\r';
        let content = `Date: ${date}`.padEnd(minWidth, ' ') + `Car: ${moreActionSelectedItem.value.name}`.padStart(minWidth, ' ') + '\r' + divider;
        let bookings = response.body.collisions;
        _.sortBy(bookings, 'start_time').forEach((booking, idx) => {
          let start = DateTime.fromISO(booking.start_time);
          let end = DateTime.fromISO(booking.end_time);
          let NoOfHours = end.diff(start, 'hours').hours;

          let map = {};
          booking.additional_items.forEach((a) => {
            map[a.item_type_additional_item.name] = a.value;
          })

          content += `[ JOB ${(idx + 1).toString().padStart(2, ' ')} ]`.padEnd(minWidth, ' ');
          content += `${start.toFormat('hh:mm a')} - ${end.toFormat('hh:mm a')} (${NoOfHours} Hours)`.padStart(minWidth, ' ') + `\r`;
          content += 'From: QGO'.padEnd(minWidth * 2, ' ') + '\r';
          content += `To: ${map['District']} (${map['Destination']})`.padEnd(minWidth * 2, '') + '\r';
          content += `Departure Time at QGO: ${start.toFormat('hh:mm a')}`.padEnd(minWidth * 2, '') + '\r';
          content += `Return Trip: ${map['Return Trip'] === 'True' ? 'Yes' : ' No'}`.padEnd(minWidth, ' ') + `Valid Permit: ${itemAttributeMap['Permit'] === 'True' ? 'Yes' : ' No'}`.padStart(minWidth, ' ') + '\r';
          content += `Arrival Time at QGO: ${end.toFormat('hh:mm a')}`.padEnd(minWidth * 2, '') + '\r';
          content += '\r';
          content += `Passengers: ${itemAttributeMap['Capacity']}`.padEnd(minWidth, ' ') + `Bulky Items: ${map['Carry Bulky Car'] === 'True' ? 'Yes' : ' No'}`.padStart(minWidth, ' ') + '\r';
          content += `Name of User: ${booking.account.name} (${booking.account.title || ''}) ${booking.account.mobile || ''}`.padEnd(minWidth * 2, ' ') + '\r';
          content += `Purpose: ${booking.title}`.padEnd(minWidth * 2, '') + '\r';

          content += divider;
        })


        let blob = new Blob([content], {type: "text/plain;charset=utf-8"});
        saveAs(blob, `${moreActionSelectedItem.value.name}_${date}.txt`);
      }
    };

    // watch(() => root.$store.getters.account, async (newValue) => {
    //   if (newValue) {
    //     refresh();
    //   }
    // }, {immediate: true})

    return {
      refreshTrigger,
      headers,
      onItemClicked,

      moreActionDialogShown,
      moreActionSelectedItem,
      moreActionSelectedItemEnabledFeatures,

      itemSessionGenerateFrom,
      itemSessionGenerateFromDate,
      itemSessionGenerateFromDay,
      itemSessionGenerateUntil,
      itemSessionGenerateUntilDate,
      itemSessionGenerateUntilDay,
      itemSessionGenerateOptions,
      sessionGenerationLimitModalShown,
      selectedSessionGenerationLimit,
      clearSessionGenerationLimit,
      setSessionGenerationLimit,

      showItemSchedule,

      createAdminBookingModalShown,
      createAdminBooking,

      asdExportCarBookings,

      OPTIONAL_FEATURES,
    }
  }
}
</script>
