<template>
  <div class="rsvp-input">
    <v-autocomplete outlined
                    hide-details="auto"
                    :label="label || $t('booking.invitations')"
                    :hint="hint"
                    persistent-hint
                    :items="accounts"
                    multiple
                    v-if="!readonly"
                    return-object
                    :search-input.sync="searchInput"
                    @change="onAccountSelect"
                    :item-text="accountText"
                    v-model="selectedAccounts"
                    :menu-props="{closeOnContentClick: true}">
      <template #selection></template>
      <template #item="{parent, item}">
        <v-list-item-content class="text-left">
          <v-list-item-title v-html="parent.genFilteredText(item.name)"></v-list-item-title>
          <!--              <v-list-item-subtitle v-text="account.username"></v-list-item-subtitle>-->
          <v-list-item-subtitle v-if="item.title"
                                v-html="parent.genFilteredText(item.title)">
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="item.mobile"
                                v-html="parent.genFilteredText(item.mobile)">
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="item.email"
                                v-html="parent.genFilteredText(item.email)">
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>
    <v-row class="rsvp-input-invitation-list" no-gutters>
      <v-col v-for="(invitation, idx) in invitations"
             cols="12" md="6">
        <v-list-item class="rsvp-input-invitation-item px-0">
<!--          <v-list-item-avatar class="mr-0">-->
<!--            <v-icon>{{ invitationStatusIcon(invitation) }}</v-icon>-->
<!--          </v-list-item-avatar>-->
          <v-list-item-content class="text-left">
            <v-list-item-title>
              {{ invitation.account.name }}
            </v-list-item-title>
            <v-list-item-subtitle v-for="field in filteredFields(invitation.account)">
              {{ field }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action class="ml-0">
            <div>
              <v-menu v-if="!disableRoles">
                <template #activator="{on, attrs}">
                  <v-btn text
                         x-small
                         class="rsvp-input-role-btn"
                         v-on="readonly ? null: on"
                         v-bind="attrs">
                    {{ $t(`invitation_role.${invitation.role}`) }}
                    <v-icon v-if="!readonly">mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-for="role in INVITATION_ROLE"
                               :class="[{'v-list-item--active': invitation.role === role}]"
                               @click="setInvitationRole(idx, role)">
                    <v-list-item-icon>
                      <v-icon>{{ invitationRoleIcon(role) }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ $t(`invitation_role.${role}`) }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-btn icon
                     v-if="!readonly"
                     @click="removeInvitation(idx)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </v-list-item-action>
        </v-list-item>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {computed, onMounted, ref, watch} from "@vue/composition-api";
import {User} from "@/store/models";
import _ from "lodash";
import {ACTION_TYPES} from "@/store/types";
import {INVITATION_ROLE, INVITATION_STATUS} from "@/constants";

export default {
  name: 'RsvpInput',
  props: {
    value: Array,
    label: String,
    hint: String,
    fields: {
      type: Array,
      default: () => {
        return ['title', 'mobile', 'email'];
      },
    },
    disableRoles: Boolean,
    rules: Array,
    readonly: Boolean,
    accountText: {
      type: Function,
      default: (a) => `${a.name} ${a.title ? '(' + a.title + ')' : ''} <${a.username}>`
    }
  },
  setup(props, {root, emit}) {
    const allAccounts = computed(() => User.query().orderBy('name').get());
    const selectedAccounts = ref([]);
    const filteredFields = function (account) {
      let results = [];

      props.fields.forEach((f) => {
        if (account[f]) {
          results.push(account[f]);
        }
      })

      return results;
    }
    const accountFilter = function (a) {
      return invitedAccountIds.value.indexOf(a.id) === -1;
    }
    const accounts = computed(() => {
      return _.filter(allAccounts.value, accountFilter).map((a) => {
        return {
          ...a,
          value: a,
        }
      });
    });


    const searchInput = ref('');
    const onAccountSelect = function (evt) {
      let selectedAccount = evt[0];
      invitations.value.push({
        id: null,
        account: selectedAccount,
        status: INVITATION_STATUS.NEEDS_ACTION,
        role: invitations.value.length === 0 ? INVITATION_ROLE.CHAIR : INVITATION_ROLE.REQUIRED_PARTICIPANT,
      });
      selectedAccounts.value.length = 0;
      searchInput.value = '';
    }

    const invitations = ref([]);
    const invitedAccountIds = computed(() => invitations.value.map((i) => i.account.id));
    const invitationRoleMap = computed(() => {
      let result = {};

      invitations.value.forEach((i) => {
        result[i.account.id] = i.role;
      })

      return {};
    })
    watch(() => props.value, (newValue, oldValue) => {
      if (_.xor(newValue, oldValue).length) {
        invitations.value = newValue;
      }
    }, {immediate: true});
    watch(() => invitations.value, (newValue, oldValue) => {
      emit('input', newValue);
    })

    const invitationStatusIcon = function (invitation) {
      return {
        [INVITATION_STATUS.ACCEPTED]: 'mdi-check-circle',
        [INVITATION_STATUS.DECLINED]: 'mdi-close-circle',
        [INVITATION_STATUS.NEEDS_ACTION]: 'mdi-help-circle',
        [INVITATION_STATUS.TENTATIVE]: 'mdi-minus-circle',
      }[invitation.status];
    };
    const invitationRoleIcon = function (role) {
      return {
        [INVITATION_ROLE.CHAIR]: 'mdi-chair-rolling',
        [INVITATION_ROLE.REQUIRED_PARTICIPANT]: 'mdi-account-star',
        [INVITATION_ROLE.OPTIONAL_PARTICIPANT]: 'mdi-account-question',
        [INVITATION_ROLE.NON_PARTICIPANT]: 'mdi-account-remove',
      }[role];
    };
    const setInvitationRole = function (idx, role) {
      let original = invitations.value[idx];

      invitations.value.splice(idx, 1, {
        ...original,
        role,
      })
    }
    const removeInvitation = function (idx) {
      invitations.value.splice(idx, 1);
    }

    onMounted(async () => {
      await root.$store.dispatch(ACTION_TYPES.GET_USERS, {});
    })

    return {
      accounts,
      selectedAccounts,
      filteredFields,
      searchInput,
      onAccountSelect,
      invitations,
      invitationRoleMap,
      invitationStatusIcon,
      INVITATION_ROLE,
      invitationRoleIcon,
      setInvitationRole,
      removeInvitation,
    }
  },
}
</script>

<style lang="less">
.rsvp-input {
  display: flex;
  flex-direction: column;

  .rsvp-input-role-btn {
    color: var(--v-primary-base);
    font-weight: 400;
  }
}
</style>
