var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BannerFrame',{attrs:{"label":_vm.$t('app_drawer.my_invitations'),"flex-content":"","no-padding":"","hide-banner":""}},[_c('RemotePaginatedList',_vm._b({attrs:{"full-height":"","headers":_vm.headers,"multi-sort":"","data-url":"client/invitations/","data-key":"invitations"},on:{"click:row":_vm.onInvitationClicked},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$parseDate(value))+" ")]}},{key:"item.booking.start_time",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$parseDate(value))+" ")]}},{key:"item.time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$parseDate(item.start_time, 'HH:mm') + ' - ' + _vm.$parseDate(item.end_time, 'HH:mm'))+" ")]}},{key:"item.status",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$t(("invitation_status." + value)))+" ")]}}])},'RemotePaginatedList',_vm.$attrs,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }