<template>
  <v-container :class="['user-selector', 'pa-0', {'full-height': fullHeight}]">
    <v-row class="flex-grow-0">
      <v-col>
        <!--        <v-autocomplete outlined-->
        <!--                        hide-details-->
        <!--                        :label="label"-->
        <!--                        :items="accounts"-->
        <!--                        v-model="selectedAccount"-->
        <!--                        return-object-->
        <!--                        :item-text="getAccountText"-->
        <!--                        @input="onAccountSelect"></v-autocomplete>-->
        <AccountAutoComplete :label="label"
                             :account-text="getAccountText"
                             :account-filter="accountFilter"
                             @input="onAccountSelect"></AccountAutoComplete>
      </v-col>
    </v-row>
    <v-row class="flex-grow-1 overflow-y-auto">
      <v-col>
        <v-list two-line
                class="selected-account-list text-left">
          <v-list-item v-for="(account, index) in selectedAccounts"
                       :key="account.id">
            <v-list-item-avatar>
              <v-icon>
                fas fa-user
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-text="account.name"></v-list-item-title>
<!--              <v-list-item-subtitle v-text="account.username"></v-list-item-subtitle>-->
              <v-list-item-subtitle v-if="account.title">
                {{ account.title }}
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="account.mobile">
                {{ account.mobile }}
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="account.email">
                {{ account.email }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="hideDelete.length === 0 || hideDelete.indexOf(account.id) === -1">
              <v-btn icon
                     @click="removeSelectedAccount(index)">
                <v-icon>
                  fas fa-times
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {ACTION_TYPES} from "@/store/types";
import {computed, ref, onMounted, watch} from "@vue/composition-api";
import _ from "lodash";
import {User} from "@/store/models";
import AccountAutoComplete from "@/components/AccountAutoComplete";

export default {
  name: 'UserSelector',
  components: {AccountAutoComplete},
  props: {
    label: String,
    exclusion: Array,
    fullHeight: Boolean,
    value: Array,
    returnObject: Boolean,
    hideDelete: {
      type: Array,
      default: () => {
        return [];
      },
    }
  },
  setup(props, {root, emit}) {
    const allAccounts = computed(() => User.query().orderBy('name').get());
    const accounts = computed(() => {
      let selectedAccountIds = selectedAccounts.value.map((a) => a.id);

      return _.filter(allAccounts.value, (a) => {
        return selectedAccountIds.indexOf(a.id) === -1 && (!props.exclusion || props.exclusion.indexOf(a.id) === -1);
      })
    });
    const getAccountText = function (a) {
      return `${a.name} ${a.title ? '(' + a.title + ')' : ''} <${a.username}>`;
    }
    const accountFilter = function (a) {
      return selectedAccountIds.value.indexOf(a.id) === -1 && (!props.exclusion || props.exclusion.indexOf(a.id) === -1);
    }
    const selectedAccount = ref(null);
    const selectedAccountIds = ref([]);
    const selectedAccounts = computed(() => User.query().whereIdIn(selectedAccountIds.value).get());
    const onAccountSelect = function (account) {
      selectedAccountIds.value = [...selectedAccountIds.value, account.id];

      // selectedAccountIds.value = [...selectedAccountIds.value, selectedAccount.value.id];
      // selectedAccount.value = null;
      //
    };
    const removeSelectedAccount = function (index) {
      selectedAccountIds.value.splice(index, 1);
    };

    watch(() => selectedAccounts.value, () => {
      emit('input', props.returnObject ? selectedAccounts.value : selectedAccountIds.value);
    })

    onMounted(async () => {
      // await root.$store.dispatch(ACTION_TYPES.GET_USERS);
    })

    watch(() => props.value, (newValue, oldValue) => {
      if (_.xor(newValue, oldValue).length) {
        selectedAccountIds.value = newValue;
        // selectedAccounts.value = User.query().whereIdIn(newValue).get();
      }
    }, {deep: true, immediate: true});

    return {
      accounts,
      getAccountText,
      accountFilter,
      selectedAccount,
      selectedAccounts,
      onAccountSelect,
      removeSelectedAccount,
    }
  },
}
</script>

<style lang="less">
.user-selector {
  display: flex;
  flex-direction: column;

  &.full-height {
    height: 100% !important;
  }
}
</style>
