<template>
  <v-container class="scan-qr-code pa-0">
    <v-row no-gutters
           class="flex-grow-1">
      <v-col>
        <v-btn block
               plain
               x-large
               :ripple="false"
               @click="startTnQrCodeReader"
               style="height: 100% !important;"
               v-if="tnQrCodeReaderAvailable">
          <v-container fluid>
            <v-row>
              <v-col>
                <v-icon size="60px">fas fa-camera</v-icon>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                Tap to Scan QR Code
              </v-col>
            </v-row>
          </v-container>
        </v-btn>
        <QrcodeStream :camera="qrCodeReaderSuspended? 'off' : 'auto'"
                      @init="onQrcodeStreamInit"
                      @decode="onQrCodeDecode"
                      class="qr-code-reader"
                      v-if="!tnQrCodeReaderAvailable && htmlCameraAvailable">
          <div class="qr-code-reader-overlay" v-if="qrCodeReaderSuspended">
            <label>PROCESSING...</label>
          </div>
        </QrcodeStream>
      </v-col>
    </v-row>

    <InstantBookModal :shown="instantBookModalShown"
                      :item-id="instantBookItemId"
                      :session-id="instantBookSessionId"
                      @cancel="onInstantBookModalFinish"></InstantBookModal>
  </v-container>
</template>

<script>
import {QrcodeStream} from 'vue-qrcode-reader'
import {computed, onMounted, ref} from "@vue/composition-api";
import {ACTION_TYPES} from "@/store/types";
import jwt_decode, {InvalidTokenError} from "jwt-decode";
import {JWT_KEY} from "@/constants";
import InstantBookModal from "@/components/InstantBookModal";

export default {
  name: 'ScanQrCode',
  components: {InstantBookModal, QrcodeStream},
  setup(props, {root}) {
    const token = ref(null);

    const tnQrCodeReaderAvailable = computed(() => {
      return window.tnConnector && window.tnConnector.util.scanCode;
    });
    const startTnQrCodeReader = function () {
      window.tnConnector.util.scanCode({}, ({type, data}) => {
        onQrCodeDecode(data);
      }, async ({errorMessage, isCameraPermissionDenied, isCancelledByUser}) => {
        if (isCameraPermissionDenied) {
          await root.$store.dispatch(ACTION_TYPES.SHOW_SNACKBAR, 'Camera Permission Denied');
        }
      })
    };

    const htmlCameraAvailable = ref(true);
    const onQrcodeStreamInit = async function (promise) {
      try {
        const {capabilities} = await promise

        // successfully initialized
        htmlCameraAvailable.value = true;
      } catch (error) {
        // await root.$store.dispatch(ACTION_TYPES.SHOW_SNACKBAR, error.name);
        htmlCameraAvailable.value = false;

        if (error.name === 'NotAllowedError') {
          // user denied camera access permisson
        } else if (error.name === 'NotFoundError') {
          // no suitable camera device installed
        } else if (error.name === 'NotSupportedError') {
          // page is not served over HTTPS (or localhost)
        } else if (error.name === 'NotReadableError') {
          // maybe camera is already in use
        } else if (error.name === 'OverconstrainedError') {
          // did you requested the front camera although there is none?
        } else if (error.name === 'StreamApiNotSupportedError') {
          // browser seems to be lacking features
        }
      } finally {
        // hide loading indicator
      }
    }
    const qrCodeReaderSuspended = ref(false);
    const onQrCodeDecode = async function (s) {
      qrCodeReaderSuspended.value = true;
      try {
        let hash = new URL(s).hash;
        let jwt = hash.substr('#/scan/'.length)

        await handleJWT(jwt);

        // let decoded = jwt_decode(jwt);
        //
        // if (!decoded.int) {
        //   root.$store.dispatch(ACTION_TYPES.SHOW_SNACKBAR, 'Invalid JWT: int');
        // }
        //
        // const handler = {
        //   CHECK_IN: checkInBooking,
        //   CHECK_OUT: checkOutBooking,
        // }[decoded.int];
        //
        // await handler(jwt);
      } catch (e) {
        if (e instanceof TypeError) {
          root.$store.dispatch(ACTION_TYPES.SHOW_SNACKBAR, 'Invalid QR Code');
        }
      } finally {
        qrCodeReaderSuspended.value = false;
      }
    };

    // Handlers
    const handleJWT = async function (jwt) {
      try {
        let decoded = jwt_decode(jwt);

        if (!decoded[JWT_KEY.INTENTION]) {
          root.$store.dispatch(ACTION_TYPES.SHOW_SNACKBAR, 'Invalid JWT: int');
        }

        const handler = {
          CHECK_IN: checkInBooking,
          CHECK_OUT: checkOutBooking,
          INSTANT_BOOK: instantBook,
        }[decoded[JWT_KEY.INTENTION]];

        await handler({jwt, decoded});
      } catch (e) {
        if (e instanceof InvalidTokenError || e instanceof TypeError) {
          root.$store.dispatch(ACTION_TYPES.SHOW_SNACKBAR, 'Invalid QR Code');
        } else if (e.body?.msg) {
          root.$store.dispatch(ACTION_TYPES.SHOW_SNACKBAR, root.$t(`error_messages.${e.body.msg}`));
        } else {
          root.$store.dispatch(ACTION_TYPES.SHOW_SNACKBAR, root.$t('error_messages.default'));
        }
      }
    };
    const checkInBooking = async function ({jwt}) {
      if (await root.$dialog.confirm({text: root.$t('booking.check_in_confirm_message')})) {
        try {
          const response = await root.$store.dispatch(ACTION_TYPES.CHECK_IN_BOOKING, {token: jwt});
          if (response.body?.booking_id) {
            root.$router.push({name: 'booking-details', params: {bookingId: response.body.booking_id}});
          }
          await root.$store.dispatch(ACTION_TYPES.SHOW_SNACKBAR, root.$t('booking.check_in_success_message'));
        } catch (e) {
          if (e.body?.msg) {
            root.$store.dispatch(ACTION_TYPES.SHOW_SNACKBAR, root.$t(`error_messages.${e.body.msg}`));
          } else {
            root.$store.dispatch(ACTION_TYPES.SHOW_SNACKBAR, root.$t('error_messages.default'));
          }
        }
      }
    };
    const checkOutBooking = async function ({jwt}) {
      if (await root.$dialog.confirm({text: root.$t('booking.check_out_confirm_message')})) {
        try {
          const response = await root.$store.dispatch(ACTION_TYPES.CHECK_OUT_BOOKING, {token: jwt});
          if (response.body?.booking_id) {
            root.$router.push({name: 'booking-details', params: {bookingId: response.body.booking_id}});
          }
          await root.$store.dispatch(ACTION_TYPES.SHOW_SNACKBAR, root.$t('booking.check_out_success_message'));
        } catch (e) {
          if (e.body?.msg) {
            root.$store.dispatch(ACTION_TYPES.SHOW_SNACKBAR, root.$t(`error_messages.${e.body.msg}`));
          } else {
            root.$store.dispatch(ACTION_TYPES.SHOW_SNACKBAR, root.$t('error_messages.default'));
          }
        }
      }
    };

    const instantBookModalShown = ref(false);
    const instantBookItemId = ref(null);
    const instantBookSessionId = ref(null);
    const instantBook = function ({decoded}) {
      instantBookItemId.value = decoded[JWT_KEY.ITEM_ID];
      instantBookSessionId.value = decoded[JWT_KEY.SESSION_ID];
      instantBookModalShown.value = true;

      setTimeout(() => {
        qrCodeReaderSuspended.value = true;
      }, 1000);
    };
    const onInstantBookModalFinish = function () {
      instantBookModalShown.value = false;
      qrCodeReaderSuspended.value = false;
    }


    onMounted(() => {
      if (root.$route.params.token) {
        handleJWT(root.$route.params.token);
      }
    })

    return {
      tnQrCodeReaderAvailable,
      startTnQrCodeReader,

      htmlCameraAvailable,
      onQrcodeStreamInit,
      qrCodeReaderSuspended,
      onQrCodeDecode,

      instantBookModalShown,
      instantBookItemId,
      instantBookSessionId,
      onInstantBookModalFinish,
    }
  }
}
</script>

<style lang="less">
.scan-qr-code {
  height: 100% !important;
  display: flex;
  flex-direction: column;
}
</style>
