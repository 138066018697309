import Vue from 'vue'
import router from '@/router'
import * as Sentry from '@sentry/vue'

export const installSentry = () => {
  Sentry.init({
    Vue,
    dsn: 'https://57d0ad48e5674649a66b39ebc0f5eb77@sentry.teamnoteapp.com/29',
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', /^https:\/\/booking-asd.teamnoteapp\.com\/api/, /^\//],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        trackComponents: true,
        hooks: ['mount', 'update', 'destroy']
      }),
      new Sentry.Replay()
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })

  return Sentry;
}
