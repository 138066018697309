import { VCalendar } from 'vuetify/lib/components/VCalendar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"dashboard",attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c('DatePickerInput',{attrs:{"label":"DATE","dense":"","hide-details":""},on:{"click:append":_vm.onDatePickerAppendClicked},model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}})],1),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c(VSelect,{attrs:{"outlined":"","dense":"","hide-details":"","items":_vm.locationFilterOptions,"label":"LOCATION & FLOORS"},on:{"input":_vm.onLocationFilterChange},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{'item-options': true, 'region-options': !item.value.item_id, 'location-options': !item.value.item_id && !item.value.region_id}},[_vm._v(_vm._s(item.text))])]}}]),model:{value:(_vm.locationFilter),callback:function ($$v) {_vm.locationFilter=$$v},expression:"locationFilter"}})],1),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c(VSelect,{attrs:{"outlined":"","dense":"","hide-details":"","multiple":"","chips":"","small-chips":"","deletable-chips":"","items":_vm.itemTypeFilterOptions,"label":"FACILITY TYPE"},on:{"input":_vm.onItemTypeFilterChange},model:{value:(_vm.itemTypeFilter),callback:function ($$v) {_vm.itemTypeFilter=$$v},expression:"itemTypeFilter"}})],1)],1),_c(VRow,[_c(VCol,[_c(VSheet,{attrs:{"height":"calc(100vh - 192px)"}},[_c(VCalendar,{ref:"calendar",attrs:{"type":"category","interval-height":64,"interval-width":45,"start":_vm.calendarDate,"categories":_vm.categories,"events":_vm.processedSessions,"event-color":_vm.getSessionColor,"first-interval":_vm.firstInterval,"interval-count":_vm.intervalCount,"category-show-all":""},on:{"click:event":_vm.sessionClicked,"click:date":_vm.onDatePickerAppendClicked},scopedSlots:_vm._u([{key:"category",fn:function(ref){
var category = ref.category;
return [_c(VContainer,{staticClass:"text-center",on:{"click":_vm.onDatePickerAppendClicked}},[_vm._v(_vm._s(_vm.items[category].name))])]}},{key:"event",fn:function(ref){return undefined}}])})],1),_c(VMenu,{attrs:{"max-width":"480px","offset-x":"","close-on-content-click":false,"activator":_vm.selectedSessionTarget},model:{value:(_vm.sessionMenuOpened),callback:function ($$v) {_vm.sessionMenuOpened=$$v},expression:"sessionMenuOpened"}},[(_vm.selectedSession)?_c(VCard,{attrs:{"outlined":""}},[_c(VCardTitle,[_vm._v(_vm._s(_vm.items[_vm.selectedSession.item_id].name))]),_c(VCardText,[_c(VRow,[_c(VCol,[(_vm.selectedSessionBooking)?_c(VTextField,{attrs:{"readonly":"","label":_vm.$t('booking.account'),"value":_vm.selectedSessionBooking.account.name}}):_vm._e(),_c(VTextField,{attrs:{"readonly":"","label":_vm.$t('booking.item'),"value":_vm.items[_vm.selectedSessionBooking.item].name}}),_c(VTextField,{attrs:{"readonly":"","label":_vm.$t('booking.datetime'),"value":_vm.selectedSessionBookingDateTimeLabel}}),(_vm.selectedSessionBooking.checkin_time)?_c(VTextField,{attrs:{"readonly":"","label":_vm.$t('booking.checkin_time'),"value":_vm.$parseDate(_vm.selectedSessionBooking.checkin_time)}}):_vm._e()],1)],1)],1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }