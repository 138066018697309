import {ACTION_TYPES} from "@/store/types";

export const appBarTitleModule = {
    state: {
        appBarShown: false,
        appBarContent: '',
        appBarIconSrc: null,
    },
    mutations: {
        setAppBarShown(state, shown) {
            state.appBarShown = shown;
        },
        setAppBarContent(state, content) {
            state.appBarContent = content;
        },
        clearAppBarContent(state) {
            state.appBarContent = '';
        },
        setAppBarIconSrc(state, src) {
            state.appBarIconSrc = src;
        },
        clearAppBarSrc(state) {
            state.appBarIconSrc = null;
        },
    },
    actions: {
        [ACTION_TYPES.SET_APP_BAR_SHOWN]: {
            root: true,
            handler({commit}, shown) {
                commit('setAppBarShown', shown);
            },
        },
        [ACTION_TYPES.SET_APP_BAR_TITLE]: {
            root: true,
            handler({commit}, content) {
                commit('setAppBarContent', content);
            },
        },
        [ACTION_TYPES.CLEAR_APP_BAR_TITLE]: {
            root: true,
            handler({commit}, content) {
                commit('clearAppBarContent', content);
            },
        },
        [ACTION_TYPES.SET_APP_BAR_ICON_SRC]: {
            root: true,
            handler({commit}, src) {
                commit('setAppBarIconSrc', src);
            },
        },
        [ACTION_TYPES.CLEAR_APP_BAR_ICON_SRC]: {
            root: true,
            handler({commit}) {
                commit('clearAppBarSrc');
            },
        },
    },
    getters: {
        appBarShown(state) {
            return state.appBarShown;
        },
        appBarTitle(state) {
            return state.appBarContent;
        },
        appBarIconSrc(state) {
            return state.appBarIconSrc;
        },
    },
}
