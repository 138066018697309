import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VInput } from 'vuetify/lib/components/VInput';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"pending-approval",attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c('DatePickerInput',{attrs:{"range":"","show-from-today":""},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VSelect,{attrs:{"outlined":"","clearable":"","hide-details":"","label":_vm.$t('booking.item'),"items":_vm.itemOptions},model:{value:(_vm.filterItemId),callback:function ($$v) {_vm.filterItemId=$$v},expression:"filterItemId"}})],1)],1),_c('RemotePaginatedList',{attrs:{"full-height":"","headers":_vm.headers,"multi-sort":"","single-expand":"","show-expand":"","hide-default-footer":"","items-per-page":-1,"data-url":"client/pending_approval/","data-key":"bookings","extra-params":_vm.extraParams,"extra-filters":_vm.extraFilters,"refresh-trigger":_vm.refreshTrigger},on:{"click:row":_vm.onBookingClicked,"item-expanded":_vm.onBookingExpanded,"update:refreshTrigger":function($event){_vm.refreshTrigger=$event},"update:refresh-trigger":function($event){_vm.refreshTrigger=$event}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$parseDate(value))+" ")]}},{key:"item.start_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$parseDate(item.start_time, 'yyyy-MM-dd HH:mm') + ' - ' + _vm.$parseDate(item.end_time, 'HH:mm'))+" ")]}},{key:"item.item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.item.name)+" ")]}},{key:"item.chairs",fn:function(ref){
var item = ref.item;
return _vm._l((_vm.eventChairs(item)),function(i){return _c('div',{key:i.id},[_vm._v(" "+_vm._s(i.account.name)+" ")])})}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c(VContainer,{attrs:{"fluid":""}},[_c(VTextField,{attrs:{"readonly":"","hide-details":"","label":_vm.$t('booking.account'),"value":item.account.name}}),(_vm.eventChairLabel(item))?_c(VTextarea,{attrs:{"readonly":"","hide-details":"","label":_vm.$t('booking.chairs'),"rows":1,"auto-grow":"","value":_vm.eventChairLabel(item)}}):_vm._e(),_c(VTextarea,{attrs:{"readonly":"","hide-details":"","label":_vm.$t('booking.item'),"rows":1,"auto-grow":"","value":item.item.name}}),_c(VTextField,{attrs:{"readonly":"","hide-details":"","label":_vm.$t('booking.date'),"value":_vm.$parseDate(item.start_time, 'yyyy-MM-dd')}}),(item.editing_booking)?_c(VTextField,{attrs:{"label":_vm.$t('booking.original_time'),"readonly":"","hide-details":"","value":_vm.$parseDate(item.editing_booking.start_time, 'HH:mm') + ' - ' + _vm.$parseDate(_vm.booking.editing_booking.end_time, 'HH:mm')}}):_vm._e(),_c(VTextField,{attrs:{"label":_vm.$t('booking.time'),"readonly":"","hide-details":"","value":_vm.$parseDate(item.start_time, 'HH:mm') + ' - ' + _vm.$parseDate(item.end_time, 'HH:mm')}}),(item.title)?_c(VTextField,{attrs:{"label":_vm.$t('booking.title'),"placeholder":" ","hide-details":""},model:{value:(item.title),callback:function ($$v) {_vm.$set(item, "title", $$v)},expression:"item.title"}}):_vm._e(),_c(VTextField,{attrs:{"label":_vm.$t('booking.created_at'),"readonly":"","hide-details":"","value":_vm.$parseDate(item.created_at)}}),_c(VRow,{staticClass:"mt-4"},[_c(VCol,[_c(VInput,{staticClass:"mb-8",attrs:{"hide-details":""}},[_c(VContainer,{staticClass:"flex-column pa-0",attrs:{"fluid":""}},[_c('legend',{staticClass:"v-label theme--light",staticStyle:{"font-size":"13px"}},[_vm._v("Conflicted Bookings")]),_c(VDataTable,{attrs:{"headers":headers,"items":_vm.conflicts,"items-per-page":-1,"hide-default-footer":"","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$parseDate(value))+" ")]}},{key:"item.start_time",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$parseDate(value, 'yyyy-MM-dd'))+" ")]}},{key:"item.time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$parseDate(item.start_time, 'HH:mm') + ' - ' + _vm.$parseDate(item.end_time, 'HH:mm'))+" ")]}},{key:"item.status",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$t(("booking_status." + value)))+" ")]}},{key:"item.chairs",fn:function(ref){
var item = ref.item;
return _vm._l((_vm.eventChairs(item)),function(i){return _c('div',{key:i.id},[_vm._v(" "+_vm._s(i.account.name)+" ")])})}},{key:"item.detail",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.showBooking(item)}}},[_c(VIcon,[_vm._v("mdi-magnify")])],1)]}}],null,true)})],1)],1)],1)],1),_c(VRow,{staticClass:"text-right"},[_c(VCol,[_c(VBtn,{staticClass:"ml-4",on:{"click":function($event){return _vm.rejectBooking(item)}}},[_vm._v(_vm._s(_vm.$t('booking.reject'))+" ")]),_c(VBtn,{staticClass:"ml-4",attrs:{"color":"primary"},on:{"click":function($event){return _vm.approveBooking(item)}}},[_vm._v(_vm._s(_vm.$t('booking.approve'))+" ")])],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }