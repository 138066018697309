<template>
  <v-container class="pending-approval" fluid>
    <v-row>
      <v-col cols="12" sm="6">
        <DatePickerInput range
                         show-from-today
                         v-model="dateRange"></DatePickerInput>
      </v-col>
      <v-col cols="12" sm="6">
        <v-select outlined
                  clearable
                  hide-details
                  :label="$t('booking.item')"
                  :items="itemOptions"
                  v-model="filterItemId"></v-select>
      </v-col>
    </v-row>

    <RemotePaginatedList full-height
                         :headers="headers"
                         multi-sort
                         single-expand
                         show-expand
                         hide-default-footer
                         :items-per-page="-1"
                         data-url="client/pending_approval/"
                         data-key="bookings"
                         :extra-params="extraParams"
                         :extra-filters="extraFilters"
                         @click:row="onBookingClicked"
                         @item-expanded="onBookingExpanded"
                         :refresh-trigger.sync="refreshTrigger">
      <template v-slot:item.created_at="{value}">
        {{ $parseDate(value) }}
      </template>
      <template v-slot:item.start_time="{item}">
        {{ $parseDate(item.start_time, 'yyyy-MM-dd HH:mm') + ' - ' + $parseDate(item.end_time, 'HH:mm') }}
      </template>
      <template v-slot:item.item="{item}">
        {{ item.item.name }}
      </template>
      <template #item.chairs="{item}">
        <div v-for="i in eventChairs(item)"
             :key="i.id">
          {{ i.account.name }}
        </div>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-container fluid>
            <v-text-field readonly
                          hide-details
                          :label="$t('booking.account')"
                          :value="item.account.name"></v-text-field>
            <v-textarea readonly
                        hide-details
                        :label="$t('booking.chairs')"
                        :rows="1"
                        auto-grow
                        v-if="eventChairLabel(item)"
                        :value="eventChairLabel(item)"></v-textarea>
            <v-textarea readonly
                        hide-details
                        :label="$t('booking.item')"
                        :rows="1"
                        auto-grow
                        :value="item.item.name"></v-textarea>
            <v-text-field readonly
                          hide-details
                          :label="$t('booking.date')"
                          :value="$parseDate(item.start_time, 'yyyy-MM-dd')"></v-text-field>
            <v-text-field :label="$t('booking.original_time')"
                          readonly
                          hide-details
                          :value="$parseDate(item.editing_booking.start_time, 'HH:mm') + ' - ' + $parseDate(booking.editing_booking.end_time, 'HH:mm')"
                          v-if="item.editing_booking"></v-text-field>
            <v-text-field :label="$t('booking.time')"
                          readonly
                          hide-details
                          :value="$parseDate(item.start_time, 'HH:mm') + ' - ' + $parseDate(item.end_time, 'HH:mm')"></v-text-field>
            <v-text-field :label="$t('booking.title')"
                          placeholder=" "
                          hide-details
                          v-model="item.title"
                          v-if="item.title"></v-text-field>
            <v-text-field :label="$t('booking.created_at')"
                          readonly
                          hide-details
                          :value="$parseDate(item.created_at)"></v-text-field>
            <v-row class="mt-4">
              <v-col>
                <v-input hide-details
                         class="mb-8">
                  <v-container fluid class="flex-column pa-0">
                    <legend class="v-label theme--light" style="font-size: 13px">Conflicted Bookings</legend>
                    <v-data-table :headers="headers"
                                  :items="conflicts"
                                  :items-per-page="-1"
                                  hide-default-footer
                                  mobile-breakpoint="0">
                      <template v-slot:item.created_at="{value}">
                        {{ $parseDate(value) }}
                      </template>
                      <template v-slot:item.start_time="{value}">
                        {{ $parseDate(value, 'yyyy-MM-dd') }}
                      </template>
                      <template v-slot:item.time="{item}">
                        {{ $parseDate(item.start_time, 'HH:mm') + ' - ' + $parseDate(item.end_time, 'HH:mm') }}
                      </template>
                      <template v-slot:item.status="{value}">
                        {{ $t(`booking_status.${value}`) }}
                      </template>
                      <template #item.chairs="{item}">
                        <div v-for="i in eventChairs(item)"
                             :key="i.id">
                          {{ i.account.name }}
                        </div>
                      </template>
                      <template #item.detail="{item}">
                        <v-btn icon
                               @click="showBooking(item)">
                          <v-icon>mdi-magnify</v-icon>
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-container>
                </v-input>
              </v-col>
            </v-row>
            <v-row class="text-right">
              <v-col>
                <v-btn class="ml-4"
                       @click="rejectBooking(item)">{{ $t('booking.reject') }}
                </v-btn>
                <v-btn class="ml-4"
                       color="primary"
                       @click="approveBooking(item)">{{ $t('booking.approve') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </td>
      </template>
    </RemotePaginatedList>
  </v-container>
</template>
<script>
import {computed, onMounted, ref, watch} from '@vue/composition-api';
import BannerFrame from "@/components/BannerFrame";
import BGModal from "@/components/BGModal";
import RemotePaginatedList from "@/components/RemotePaginatedList";
import DatePickerInput from "@/components/DatePickerInput";
import {DateTime} from 'luxon';
import {Item, ItemApprover, ItemType} from "@/store/models";
import _ from "lodash";
import {INVITATION_ROLE} from "@/constants";
import {ACTION_TYPES} from "@/store/types";

export default {
  name: 'PendingApproval',
  components: {DatePickerInput, RemotePaginatedList, BGModal, BannerFrame},
  setup(props, {root}) {
    const itemType = computed(() => {
      if (root.$route.params.itemTypeId) {
        return ItemType.find(root.$route.params.itemTypeId);
      }
    });
    watch(() => itemType.value, (newValue) => {
      if (newValue) {
        root.$store.dispatch(ACTION_TYPES.SET_APP_BAR_TITLE, `${root.$t('app_drawer.pending_approval')} - ${newValue.name}`)
      }
    })

    const now = DateTime.fromJSDate(new Date())
    const dateRange = ref([
      now.toFormat('yyyy-MM-dd'),
      now.plus({days: 30}).toFormat('yyyy-MM-dd'),
    ]);

    const itemOptions = computed(() => {
      const account = root.$store.getters.account;
      const managedItemIds = ItemApprover.query().where('user_id', account.id).all().map((a) => a.item_id);
      let query = Item.query().whereIdIn(managedItemIds)
      if (root.$route.params.itemTypeId) {
        query = query.where('type_id', root.$route.params.itemTypeId)
      }
      // return query.all().map((i) => {
      //   return {text: i.name, value: i.id};
      // });

      let itemsByName = {};
      _.forEach(query.all(), (item)=> {
        if (!itemsByName[item.name]) {
          itemsByName[item.name] = [];
        }
        itemsByName[item.name].push(item.id);
      })
      return _.map(itemsByName, (items, name)=> {
        return {text: name, value: items};
      })
    })
    const filterItemId = ref(null);
    const extraParams = computed(() => {
      let params = {};

      if (dateRange.value && dateRange.value.length === 2) {
        params = {
          ...params,
          from_date: dateRange.value[0],
          to_date: dateRange.value[1],
        }
      }

      // if (filterItemId.value) {
      //   params = {
      //     ...params,
      //     item_id: filterItemId.value,
      //   }
      // }

      return params;
    })
    const extraFilters = computed(() => {
      let filters = {};

      if (root.$route.params.itemTypeId) {
        filters['item.type.id'] = root.$route.params.itemTypeId;
      }

      if (filterItemId.value) {
        filters['item.id'] = filterItemId.value;
      }

      return filters;
    })

    const load = async function () {
      refreshTrigger.value = true;
    };

    const headers = ref([
      {text: root.$t('booking.created_at'), value: 'created_at', sortable: true},
      {text: root.$t('booking.account'), value: 'account.name', sortable: true},
      {text: root.$t('booking.chairs'), value: 'chairs', sortable: false},
      {text: root.$t('booking.item'), value: 'item.name', sortable: true},
      {text: root.$t('booking.date'), value: 'start_time', sortable: true},
      {text: root.$t('booking.title'), value: 'title', sortable: true},
      {text: '', value: 'data-table-expand', width: '20px', sortable: false},
    ]);
    const refreshTrigger = ref(false);

    const eventChairs = function (event) {
      return _.filter(event.invitations, (i) => i.role === INVITATION_ROLE.CHAIR);
    }
    const eventChairLabel = function (booking) {
      return eventChairs(booking).map((i)=> i.account.name).join("; ")
    }

    const conflicts = ref([]);
    const getConflicts = async function (booking_id) {
      const response = await root.$store.dispatch(ACTION_TYPES.CALL_API, {
        url: 'client/pending_approval/conflicts/',
        params: {booking_id},
      });

      if (response.body.success) {
        conflicts.value = response.body.bookings;
      }
    }
    const onBookingExpanded = function ({item, value}) {
      conflicts.value = [];
      if (value) {
        getConflicts(item.id);
      }
      // console.log(item, value);
    }

    const onBookingClicked = function (item) {
      // root.$router.push({name: 'approve-booking', params: {bookingId: item.id}})
    };

    const approveBooking = async function (item) {
      if (await root.$dialog.confirm({text: root.$t('booking.confirm_approve_msg'), showClose: false})) {
        const response = await root.$store.dispatch(ACTION_TYPES.CALL_API, {
          url: 'client/pending_approval/approve/',
          params: {
            booking_id: item.id,
            approve: true,
          }
        })

        if (response.body.success) {
          root.$store.dispatch(ACTION_TYPES.SHOW_SNACKBAR, root.$t('booking.approve_success_msg'));
          refreshTrigger.value = true;
        }
      }
    }
    const rejectBooking = async function (item) {
      if (await root.$dialog.confirm({text: root.$t('booking.confirm_disapprove_msg'), showClose: false})) {
        const response = await root.$store.dispatch(ACTION_TYPES.CALL_API, {
          url: 'client/pending_approval/approve/',
          params: {
            booking_id: item.id,
            approve: false,
          }
        })

        if (response.body.success) {
          root.$store.dispatch(ACTION_TYPES.SHOW_SNACKBAR, root.$t('booking.disapprove_success_msg'));
          refreshTrigger.value = true;
        }
      }
    }

    watch(() => root.$store.getters.account, async (newValue) => {
      if (newValue) {
        // await load();
      }
    }, {immediate: true})

    onMounted(() => {
      // const now = DateTime.fromJSDate(new Date())
      //
      // dateRange.value = [
      //   now.startOf('month').toFormat('yyyy-MM-dd'),
      //   now.endOf('month').toFormat('yyyy-MM-dd'),
      // ];
    })

    return {
      itemType,
      dateRange,
      itemOptions,
      filterItemId,
      extraParams,
      extraFilters,
      headers,
      refreshTrigger,
      eventChairs,
      eventChairLabel,
      conflicts,
      onBookingExpanded,
      onBookingClicked,
      approveBooking,
      rejectBooking,
    }
  }
}
</script>

<style lang="less">
.pending-approval {
  height: 100%;
  display: flex;
  flex-direction: column;

  .pending-approval-date-filter {
    display: flex;
    flex: 0 0 auto;
  }
}
</style>
