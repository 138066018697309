<template>
  <v-container :class="['booking-detail', {'px-0': $vuetify.breakpoint.smAndDown}]" v-if="booking">
    <div class="booking-detail-detail-row"
         v-if="itemTypeLayoutDefinitions.length">
      <v-btn color="primary"
             tile
             v-if="!editMode"
             @click="editMode = true"
             class="booking-detail-edit-btn">{{ $t('edit') }}
      </v-btn>
      <v-row v-for="item in itemTypeLayoutDefinitions">
        <!-- Display / Text -->
        <v-col v-if="[
                    ITEM_TYPE_LAYOUT_TYPES.DISPLAY,
                    ITEM_TYPE_LAYOUT_TYPES.TEXT,
                ].indexOf(item.type) !== -1">
          <DisplayTextField :label="item.label"
                            :hint="item.hint"
                            :value="getDisplayValue(item)"></DisplayTextField>
        </v-col>

        <!-- Datetime / Date / Time Display -->
        <v-col v-if="[
                        ITEM_TYPE_LAYOUT_TYPES.DATETIME_DISPLAY,
                        ITEM_TYPE_LAYOUT_TYPES.DATE_DISPLAY,
                        ITEM_TYPE_LAYOUT_TYPES.TIME_DISPLAY,
                      ].indexOf(item.type) !== -1">
          <DisplayTextField :label="item.label"
                            :hint="item.hint"
                            :value="$parseDate(getDisplayValue(item), item.format)"></DisplayTextField>
        </v-col>

        <!-- Recurring -->
        <v-col cols="12"
               v-if="item.type === ITEM_TYPE_LAYOUT_TYPES.RECURRENCE && bookingRecord.recurrence">
          <DisplayTextField :label="item.label"
                            :value="$parseCronString(bookingRecord.recurrence, bookingRecord.recur_until)"></DisplayTextField>
        </v-col>

        <!-- Account Select -->
        <v-col v-if="item.type === ITEM_TYPE_LAYOUT_TYPES.ACCOUNTS">
          <v-input hide-details="auto"
                   :hint="item.hint">
            <v-container fluid class="flex-column pa-0">
              <legend class="v-label theme--light" style="font-size: 13px">{{ item.label }}
                {{ hostIds.length > 0 ? `(${hostIds.length})` : '' }}
              </legend>

              <MultipleAccountInput :readonly="!editMode"
                                    v-model="hostIds"
                                    :label="item.label"
                                    :hint="item.hint"
                                    :fields="item.fields"></MultipleAccountInput>
            </v-container>
          </v-input>
        </v-col>

        <!-- RSVP -->
        <v-col v-if="item.type === ITEM_TYPE_LAYOUT_TYPES.RSVP">
          <v-input hide-details="auto"
                   :hint="item.hint">
            <v-container fluid class="flex-column pa-0">
              <legend class="v-label theme--light" style="font-size: 13px">{{ item.label }}
                {{ invitations.length > 0 ? `(${invitations.length})` : '' }}
              </legend>

              <RsvpInput :label="item.label"
                         :hint="item.hint"
                         :fields="item.fields"
                         :disable-roles="item.disable_rsvp_role"
                         :readonly="!editMode"
                         v-model="invitations"></RsvpInput>
            </v-container>
          </v-input>
        </v-col>

        <!-- CUSTOM ATTRIBUTE -->
        <v-col v-if="item.type === ITEM_TYPE_LAYOUT_TYPES.CUSTOM_ATTRIBUTE">
          <DisplayTextField :label="item.label"
                            :hint="item.hint"
                            :value="itemAttributeMap[item.id] ? itemAttributeMap[item.id].value : 'No'"></DisplayTextField>
        </v-col>

        <!-- ADDITIONAL ITEM -->
        <v-col v-if="item.type === ITEM_TYPE_LAYOUT_TYPES.ADDITIONAL_ITEM">
          <DisplayTextField :label="item.label"
                            :hint="item.hint"
                            :value="bookingAdditionalItemMap[item.id] ? bookingAdditionalItemMap[item.id].value : 'N/A'"></DisplayTextField>
        </v-col>

        <!-- BOOKING STATUS -->
        <v-col v-if="item.type === ITEM_TYPE_LAYOUT_TYPES.BOOKING_STATUS">
          <v-input hide-details="auto"
                   :hint="item.hint">
            <v-container fluid class="flex-column pa-0">
              <legend class="v-label theme--light" style="font-size: 13px">{{ item.label }}</legend>
              <div class="booking-detail-status-label">{{ $t(`booking_status.${booking.status}`) }}
                <v-icon>{{ bookingStatusIcon }}</v-icon>
              </div>
            </v-container>
          </v-input>
        </v-col>

        <!-- BOOKING DATETIME-->
        <v-col v-if="item.type === ITEM_TYPE_LAYOUT_TYPES.BOOKING_DATETIME">
          <DisplayTextField :label="item.label"
                            :hint="item.hint"
                            :value="`${$parseDate(booking.start_time, 'yyyy-MM-dd HH:mm')} - ${$parseDate(booking.end_time, 'HH:mm')}`"></DisplayTextField>
        </v-col>
      </v-row>
    </div>

    <div class="booking-detail-status-row"
         v-if="!itemTypeLayoutDefinitions.length">
      <v-input hide-details
               class="mb-8">
        <v-container fluid class="flex-column pa-0">
          <legend class="v-label theme--light" style="font-size: 13px">{{ $t('booking.status') }}</legend>
          <div class="booking-detail-status-label">{{ $t(`booking_status.${booking.status}`) }}
            <v-icon>{{ bookingStatusIcon }}</v-icon>
          </div>
        </v-container>
      </v-input>

      <DisplayTextField :label="$t('booking.created_at')"
                        :value="$parseDate(booking.created_at)"></DisplayTextField>
      <DisplayTextField v-if="booking.approved_by"
                        :label="$t('booking.approved_at')"
                        :value="`${$parseDate(booking.approved_at)} by ${booking.approved_by.name}`"></DisplayTextField>
    </div>

    <div class="booking-detail-detail-row"
         v-if="!itemTypeLayoutDefinitions.length">
      <v-btn color="primary"
             tile
             v-if="!editMode"
             @click="editMode = true"
             class="booking-detail-edit-btn">{{ $t('edit') }}
      </v-btn>
      <v-row>
        <v-col cols="12">
          <DisplayTextField :label="$t('booking.account')"
                            :value="booking.account.name"
                            v-if="!bookingOwnedByMe"></DisplayTextField>
        </v-col>
        <v-col cols="12">
          <DisplayTextField :label="$t('booking.item')"
                            :value="booking.item.name"></DisplayTextField>

          <!--          <v-input hide-details>-->
          <!--            <v-container fluid class="flex-column pa-0">-->
          <!--              <legend class="v-label theme&#45;&#45;light" style="font-size: 13px">{{ $t('booking.item') }}</legend>-->

          <!--              <v-tooltip top>-->
          <!--                <template #activator="{on, attrs}">-->
          <!--                  <label v-on="on"-->
          <!--                         v-bind="attrs">-->
          <!--                    {{ booking.item.name }}-->
          <!--                    <v-btn icon-->
          <!--                           color="rgb(40, 97, 169)"-->
          <!--                           @click="itemDescriptionModalShown = true">-->
          <!--                      <v-icon>mdi-information-outline</v-icon>-->
          <!--                    </v-btn>-->
          <!--                  </label>-->
          <!--                </template>-->
          <!--                <span>Facility Details</span>-->
          <!--              </v-tooltip>-->
          <!--            </v-container>-->
          <!--          </v-input>-->
        </v-col>
        <v-col cols="12" sm="6">
          <DisplayTextField :label="$t('booking.date')"
                            :value="$parseDate(booking.start_time, 'yyyy-MM-dd')"></DisplayTextField>
        </v-col>
        <v-col cols="8" sm="4">
          <DisplayTextField :label="$t('booking.time')"
                            :value="$parseDate(booking.start_time, 'HH:mm') + ' - ' + $parseDate(booking.end_time, 'HH:mm')"></DisplayTextField>
        </v-col>
        <!-- <v-col cols="4" sm="2" class="align-self-center">
          <v-btn color="primary"
                 v-if="editMode && booking.status === BOOKING_STATUS.CONFIRMED && canChangeTime"
                 @click="changeTime">
            {{ $t('change') }}
          </v-btn>
        </v-col> -->
        <v-col cols="12"
               v-if="bookingRecord.recurrence">
          <DisplayTextField :label="$t('booking_record.recurrence')"
                            :value="$parseCronString(bookingRecord.recurrence, bookingRecord.recur_until)"></DisplayTextField>
        </v-col>
        <v-col cols="12">
          <DisplayTextField :label="$t('booking.title')"
                            :value="booking.title"
                            placeholder="(N/A)"
                            v-if="enabledFeatures.indexOf(OPTIONAL_FEATURES.BOOKING_TITLE) !== -1 && !editMode"></DisplayTextField>

          <v-text-field outlined
                        :label="$t('booking.title')"
                        v-model="booking.title"
                        hide-details="auto"
                        v-if="enabledFeatures.indexOf(OPTIONAL_FEATURES.BOOKING_TITLE) !== -1 && editMode"></v-text-field>
        </v-col>
        <v-col cols="12">
          <DisplayTextField v-for="additionalItem in bookingAdditionalItems"
                            :label="additionalItem.item_type_additional_item.name"
                            :value="additionalItem.value"></DisplayTextField>
        </v-col>
        <v-col cols="12"
               v-if="enabledFeatures.indexOf(OPTIONAL_FEATURES.BOOKING_HOSTS) !== -1 && bookingOwnedByMe && showInvitationsAndHosts">
          <v-input hide-details>
            <v-container fluid class="flex-column pa-0">
              <legend class="v-label theme--light" style="font-size: 13px">{{ $t('booking.hosts') }}
                {{ hostIds.length > 0 ? `(${hostIds.length})` : '' }}
              </legend>

              <MultipleAccountInput :readonly="!editMode"
                                    v-model="hostIds"></MultipleAccountInput>
            </v-container>
          </v-input>
        </v-col>
        <v-col cols="12"
               v-if="enabledFeatures.indexOf(OPTIONAL_FEATURES.BOOKING_INVITATIONS) !== -1 && bookingOwnedByMe && showInvitationsAndHosts">
          <v-input hide-details>
            <v-container fluid class="flex-column pa-0">
              <legend class="v-label theme--light" style="font-size: 13px">{{ $t('booking.invitations') }}
                {{ invitations.length > 0 ? `(${invitations.length})` : '' }}
              </legend>

              <RsvpInput :readonly="!editMode"
                         v-model="invitations"></RsvpInput>
            </v-container>
          </v-input>
        </v-col>
      </v-row>
    </div>

    <ItemDetailModal :shown="itemDescriptionModalShown"
                     :item-id="booking.item.id"
                     @cancel="itemDescriptionModalShown = false"></ItemDetailModal>

    <BookingCheckInModal :booking-id="bookingId"
                         :shown="bookingCheckInModalShown"
                         @cancel="bookingCheckInModalShown = false"
                         @finish="onCheckInModalFinish"></BookingCheckInModal>

    <v-footer app
              inset
              class="pr-6 py-3">
      <v-row no-gutters>
        <v-btn color="primary"
               @click="$router.back()">
          {{ $t('back') }}
        </v-btn>
        <v-spacer></v-spacer>
<!--        <v-btn color="primary"-->
<!--               class="ml-4"-->
<!--               v-if="!editMode && $vuetify.breakpoint.mdAndUp && booking.status === BOOKING_STATUS.CONFIRMED && !booking.checkin_time"-->
<!--               @click="initCheckin">{{ $t('booking.check_in') }}-->
<!--        </v-btn>-->

<!--        <v-btn color="primary"-->
<!--               class="ml-4"-->
<!--               v-if="!editMode &&$vuetify.breakpoint.mdAndUp && booking.status === BOOKING_STATUS.CONFIRMED && booking.checkin_time && !booking.checkout_time"-->
<!--               @click="checkOut">{{ $t('booking.check_out') }}-->
<!--        </v-btn>-->

        <v-btn color="primary"
               class="ml-4"
               v-if="!editMode && $vuetify.breakpoint.mdAndUp"
               :disabled="!canCancelBooking"
               @click="cancelBooking">{{ $t('booking_record.cancel') }}
        </v-btn>

        <v-btn outlined
               color="primary"
               class="ml-4"
               v-if="editMode"
               @click="getBooking">CANCEL
        </v-btn>

        <v-btn color="primary"
               class="ml-4"
               v-if="editMode"
               @click="saveBooking">SAVE
        </v-btn>

        <v-menu left
                bottom
                v-if="!editMode && $vuetify.breakpoint.smAndDown">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon
                   class="ml-4"
                   v-bind="attrs"
                   v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
<!--            <v-list-item v-if="booking.status === BOOKING_STATUS.CONFIRMED && !booking.checkin_time"-->
<!--                         @click="initCheckin">-->
<!--              <v-list-item-icon>-->
<!--                <v-icon>fas fa-sign-in-alt</v-icon>-->
<!--              </v-list-item-icon>-->
<!--              <v-list-item-title>{{ $t('booking.check_in') }}</v-list-item-title>-->
<!--            </v-list-item>-->
<!--            <v-list-item-->
<!--                v-if="booking.status === BOOKING_STATUS.CONFIRMED && booking.checkin_time && !booking.checkout_time"-->
<!--                @click="checkOut">-->
<!--              <v-list-item-icon>-->
<!--                <v-icon>fas fa-sign-out-alt</v-icon>-->
<!--              </v-list-item-icon>-->
<!--              <v-list-item-title>{{ $t('booking.check_out') }}</v-list-item-title>-->
<!--            </v-list-item>-->
            <v-list-item :disabled="!canCancelBooking"
                         @click="cancelBooking">
              <v-list-item-icon>
                <v-icon>fas fa-times</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t('booking_record.cancel') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-row>
    </v-footer>
  </v-container>
</template>
<script>
import {computed, onMounted, ref} from '@vue/composition-api';
import BannerFrame from "@/components/BannerFrame";
import {ACTION_TYPES} from "@/store/types";
import {
  BOOKING_STATUS,
  CHECKIN_MODE,
  INVITATION_ROLE,
  INVITATION_STATUS,
  ITEM_TYPE_LAYOUT_KEYS, ITEM_TYPE_LAYOUT_TYPES,
  OPTIONAL_FEATURES
} from "@/constants";
import BookingCheckInModal from "@/components/BookingCheckInModal";
import EditRecurringDialog from "@/components/EditRecurringDialog";
import AccountAutoComplete from "@/components/AccountAutoComplete";
import _ from "lodash";
import UserSelectModal from "@/components/UserSelectModal";
import ItemDetailModal from "@/components/ItemDetailModal";
import RsvpInput from "@/components/RsvpInput";
import MultipleAccountInput from "@/components/MultipleAccountInput";
import DisplayTextField from "@/components/DisplayTextField";
import ChangeBookingTimeDialog from "@/components/ChangeBookingTimeDialog";

export default {
  name: 'BookingDetail',
  components: {
    DisplayTextField,
    MultipleAccountInput,
    RsvpInput,
    ItemDetailModal,
    UserSelectModal, AccountAutoComplete, BookingCheckInModal, BannerFrame
  },
  setup(props, {root}) {
    const bookingId = ref(root.$route.params.bookingId);
    const bookingRecord = ref(null);
    const booking = ref(null);
    const bookingStatusIcon = computed(() => {
      if (booking.value) {
        return {
          [BOOKING_STATUS.CONFIRMED]: 'mdi-check-circle',
          [BOOKING_STATUS.REJECTED]: 'mdi-close-circle',
          [BOOKING_STATUS.PENDING_APPROVAL]: 'mdi-minus-circle',
        }[booking.value.status];
      }

      return '';
    })
    const getRoleIdx = function (i) {
      return {
        [INVITATION_ROLE.CHAIR]: 1,
        [INVITATION_ROLE.REQUIRED_PARTICIPANT]: 2,
        [INVITATION_ROLE.OPTIONAL_PARTICIPANT]: 3,
        [INVITATION_ROLE.NON_PARTICIPANT]: 4,
      }[i.role] || 5;
    };
    const getBooking = async function () {
      editMode.value = false;
      const response = await root.$store.dispatch(ACTION_TYPES.GET_BOOKINGS, {booking_id: bookingId.value});
      booking.value = response.body.bookings[0];
      invitations.value = _.filter(_.sortBy(booking.value.invitations, [getRoleIdx, 'created_at']), (i) => i.status !== INVITATION_STATUS.CANCELLED);
      hostIds.value = booking.value.hosts.map((h) => h.id);
      bookingRecord.value = response.body.booking_records[0];
    }
    const bookingOwnedByMe = computed(() => booking.value && root.$store.getters.account && root.$store.getters.account.id === booking.value.account.id);

    const itemTypeLayoutDefinitions = computed(() => {
      if (booking.value) {
        return JSON.parse(booking.value.item.type.booking_detail_layout_definition);
      }
      return [];
    })
    const getDisplayValue = function (item) {
      return {
        [ITEM_TYPE_LAYOUT_KEYS.ITEM_TYPE_NAME]: booking.value.item.type.name,
        [ITEM_TYPE_LAYOUT_KEYS.ITEM_NAME]: booking.value.item.name,
        [ITEM_TYPE_LAYOUT_KEYS.BOOKING_TITLE]: booking.value.title,
        [ITEM_TYPE_LAYOUT_KEYS.BOOKING_DATE]: booking.value.start_time,
        [ITEM_TYPE_LAYOUT_KEYS.BOOKING_START_TIME]: booking.value.start_time,
        [ITEM_TYPE_LAYOUT_KEYS.BOOKING_END_TIME]: booking.value.end_time,
        [ITEM_TYPE_LAYOUT_KEYS.BOOKING_CREATE_DATE]: booking.value.created_at,
      }[item.key];
    }

    const itemTypeAdditionalItems = computed(() => {
      if (booking.value) {
        return _.sortBy(booking.value.item.type.additional_items, (i) => parseInt(i.ordering, 10))
      }

      return [];
    });
    const itemTypeAdditionalItemMap = computed(() => {
      let result = {};

      itemTypeAdditionalItems.value.forEach((i) => {
        result[i.id] = i;
      })

      return result;
    })

    const itemAttributeMap = computed(()=> {
      let result = {};

      if (booking.value) {
        booking.value.item.attributes.forEach((a) => {
          result[a.type_attribute] = a;
        });
      }

      return result;
    })

    const bookingAdditionalItems = computed(() => {
      if (booking.value) {
        return _.sortBy(booking.value.additional_items, (i) => i.item_type_additional_item.ordering);
      }

      return [];
    })
    const bookingAdditionalItemMap = computed(() => {
      let result = {};

      bookingAdditionalItems.value.forEach((i) => {
        result[i.item_type_additional_item.id] = i;
      })

      return result;
    })

    const enabledFeatures = computed(() => {
      if (booking.value) {
        let enabledFeatures = JSON.parse(booking.value.item.type.enabled_features);
        if (booking.value.item.enabled_features) {
          enabledFeatures = JSON.parse(booking.value.item.enabled_features);
        }
        return enabledFeatures;
      }
      return [];
    });
    const itemDescriptionModalShown = ref(false);

    const editingTitle = ref(false);
    const saveBookingTitle = async function () {
      if (await root.$dialog.confirm({text: root.$t('booking.save_title_confirm_msg')})) {
        let edit = 'this';

        if (bookingRecord.value.recurrence) {
          edit = await root.$dialog.showAndWait(EditRecurringDialog, {title: 'RSVP recurring booking'});
          if (edit === undefined) return;
        }

        await root.$store.dispatch(ACTION_TYPES.SAVE_BOOKING_TITLE, {
          booking_id: bookingId.value,
          title: booking.value.title,
          edit
        });
        await root.$store.dispatch(ACTION_TYPES.GET_BOOKINGS, {booking_id: bookingId.value});
        await root.$store.dispatch(ACTION_TYPES.SHOW_SNACKBAR, root.$t('booking.save_title_success_msg'));
        editingTitle.value = false;
      }
    };

    const showInvitationsAndHosts = computed(() => {
      return booking.value && [BOOKING_STATUS.PENDING_APPROVAL, BOOKING_STATUS.CONFIRMED].indexOf(booking.value.status) !== -1 && _.some([OPTIONAL_FEATURES.BOOKING_INVITATIONS, OPTIONAL_FEATURES.BOOKING_HOSTS], (f) => enabledFeatures.value.indexOf(f) !== -1);
    })

    const invitations = ref([]);

    const hostIds = ref([]);
    const hostAccountFilter = function (a) {
      return hostIds.value.indexOf(a.id) === -1;
    }
    const canClear = function (a) {
      return a.id !== booking.value.account.id;
    };

    const editMode = ref(false);
    const saveBooking = async function () {
      if (await root.$dialog.confirm({text: root.$t('booking.save_confirm_msg')})) {
        editMode.value = false;
        let edit = 'this';

        if (bookingRecord.value.recurrence) {
          edit = await root.$dialog.showAndWait(EditRecurringDialog, {title: 'Edit recurring booking'});
          if (edit === undefined || edit === false) return;
        }

        await root.$store.dispatch(ACTION_TYPES.CALL_API, {
          url: 'client/bookings/save/',
          params: {
            booking_id: bookingId.value,
            title: booking.value.title,
            edit,
            host_ids: hostIds.value,
            chair_ids: _.filter(invitations.value, (i) => i.role === INVITATION_ROLE.CHAIR).map((i) => i.account.id),
            required_participant_ids: _.filter(invitations.value, (i) => i.role === INVITATION_ROLE.REQUIRED_PARTICIPANT).map((i) => i.account.id),
            optional_participant_ids: _.filter(invitations.value, (i) => i.role === INVITATION_ROLE.OPTIONAL_PARTICIPANT).map((i) => i.account.id),
          },
        });

        await getBooking();
        await root.$store.dispatch(ACTION_TYPES.SHOW_SNACKBAR, root.$t('booking.save_success_msg'));
      }
    }
    const canCancelBooking = computed(() => {
      return (booking.value.status === BOOKING_STATUS.CONFIRMED || booking.value.status === BOOKING_STATUS.PENDING_APPROVAL) && (!bookingOwnedByMe.value || !booking.value.checkin_time);
    })
    const cancelBooking = async function () {
      if (await root.$dialog.confirm({text: root.$t('booking_record.cancel_confirm_msg')})) {
        let edit = 'this';

        if (bookingRecord.value.recurrence) {
          edit = await root.$dialog.showAndWait(EditRecurringDialog);
          if (edit === undefined || edit === false) return;
        }

        await root.$store.dispatch(ACTION_TYPES.CANCEL_BOOKING, {booking_id: bookingId.value, edit});
        await root.$store.dispatch(ACTION_TYPES.SHOW_SNACKBAR, root.$t('booking_record.cancel_success_msg'));
        root.$router.push({name: 'my-bookings'})
      }
    }

    const bookingCheckInModalShown = ref(false);
    const onCheckInModalFinish = async function () {
      bookingCheckInModalShown.value = false;
      await getBooking()
    }

    const oneClickCheckIn = async function () {
      if (await root.$dialog.confirm({text: root.$t('booking.check_in_confirm_message')})) {
        let params = {
          booking_id: bookingId.value,
        }

        await root.$store.dispatch(ACTION_TYPES.CHECK_IN_BOOKING, params);
        await getBooking();
      }
    }
    const initCheckin = async function () {
      const response = await root.$store.dispatch(ACTION_TYPES.CALL_API, {
        url: 'client/bookings/init_check_in/',
        params: {
          booking_id: bookingId.value,
        },
      });

      const checkInMode = response.body.mode;

      switch (checkInMode) {
        case CHECKIN_MODE.APP_ONE_CLICK:
        case CHECKIN_MODE.KIOSK_ONE_CLICK:
          await oneClickCheckIn();
          break;
        case CHECKIN_MODE.APP_KIOSK:
          bookingCheckInModalShown.value = true;
          break;
        case CHECKIN_MODE.KIOSK_PIN:
          await getBooking();
          await root.$store.dispatch(ACTION_TYPES.SHOW_SNACKBAR, root.$t('booking.check_in_enter_pin'));
          break;
      }
    }
    const checkOut = async function () {
      if (await root.$dialog.confirm({text: root.$t('booking.check_out_confirm_message')})) {
        await root.$store.dispatch(ACTION_TYPES.CHECK_OUT_BOOKING, {booking_id: bookingId.value});
        await root.$store.dispatch(ACTION_TYPES.SHOW_SNACKBAR, root.$t('booking.check_out_success_message'));
        await getBooking()
      }
    }

    const canChangeTime = computed(() => {
      if (booking.value) {
        let item = booking.value.item;

        return item.allow_booking_extension || item.allow_booking_shortening;
      }
    })
    const changeTime = async function () {
      const result = await root.$dialog.showAndWait(ChangeBookingTimeDialog, {
        bookingId: bookingId.value,
        width: '480px',
        showClose: false,
        allowShortening: true,
      });

      if (result === true) {
        await getBooking();
      }
    }

    onMounted(() => {
      getBooking();
    })

    return {
      getBooking,
      bookingId,
      booking,
      bookingRecord,
      bookingStatusIcon,
      bookingOwnedByMe,

      enabledFeatures,
      OPTIONAL_FEATURES,
      itemDescriptionModalShown,

      itemTypeLayoutDefinitions,
      getDisplayValue,
      itemTypeAdditionalItemMap,
      itemAttributeMap,
      ITEM_TYPE_LAYOUT_TYPES,
      ITEM_TYPE_LAYOUT_KEYS,

      bookingAdditionalItems,
      bookingAdditionalItemMap,

      editingTitle,
      saveBookingTitle,

      showInvitationsAndHosts,

      invitations,

      hostIds,
      hostAccountFilter,
      canClear,

      editMode,
      canChangeTime,
      changeTime,
      saveBooking,
      canCancelBooking,
      cancelBooking,

      bookingCheckInModalShown,
      onCheckInModalFinish,

      initCheckin,
      checkOut,

      BOOKING_STATUS,
      INVITATION_ROLE,
    }
  },
}
</script>

<style lang="less">
.booking-detail {
  margin-top: 8px;
  background: transparent !important;

  .booking-detail-status-row, .booking-detail-detail-row {
    background-color: white;
    padding: 16px 24px;
    margin-bottom: 8px;
    border-radius: 2px;
    position: relative;

    .booking-detail-status-label {
      font-weight: 500;
      font-size: 150%;

      .v-icon {
        font-size: 120%;
      }

      .mdi-check-circle {
        color: #87d563;
      }

      .mdi-minus-circle {
        color: #edcc45;
      }

      .mdi-close-circle {
        color: red;
      }
    }

    .booking-detail-edit-btn {
      position: absolute;
      top: 12px;
      right: 12px;
    }
  }
}
</style>
