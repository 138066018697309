<template>
  <v-card>
    <v-card-text v-html="content" style="height: 70vh; overflow: auto"></v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text
             color="primary"
             @click="$emit('submit')">
        {{ $t('ok') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'AnnouncementDialog',
  props: {
    content: String,
  },
}
</script>
