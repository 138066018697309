import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import ormPlugin from './models'
import {snackBarModule} from "@/store/modules/snackBar";
import {apiModule} from "@/store/modules/api";
import {accountModule} from "@/store/modules/account";
import {appBarTitleModule} from "@/store/modules/appBar";

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
    storage: window.localStorage
})

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        account: accountModule,
        appBarTitle: appBarTitleModule,
        snackBar: snackBarModule,
        api: apiModule,
    },
    plugins: [
        ormPlugin,
        vuexLocal.plugin,
    ]
})
