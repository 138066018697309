<template>
  <BGModal :shown="dialogShown"
           :title="title"
           no-fullscreen>
    <template #content>
      <div class="d-flex flex-column h-100 pa-4">
        <UserSelector :label="title"
                      :value="selectedAccountIds"
                      :hide-delete="hideDelete"
                      @input="selectedAccounts = $event"
                      return-object></UserSelector>
      </div>
    </template>
    <template #footer>
      <v-row no-gutters>
        <v-btn color="secondary"
               @click="cancel"
               class="mr-3"
               depressed
               large>
          {{ $t('back') }}
        </v-btn>

        <v-btn color="primary"
               @click="ok"
               depressed
               large>
          {{ $t('ok') }}
        </v-btn>
      </v-row>
    </template>
  </BGModal>
</template>
<script>
import UserSelector from "@/components/UserSelector";
import BGModal from "@/components/BGModal";
import {ref, watch} from "@vue/composition-api";

export default {
  name: 'UserSelectModal',
  components: {BGModal, UserSelector},
  props: {
    shown: Boolean,
    title: String,
    value: Array,
    hideDelete: {
      type: Array,
      default: () => {
        return [];
      },
    }
  },
  setup(props, {root, emit}) {
    const dialogShown = ref(props.shown);
    watch(() => props.shown, async (newValue, oldValue) => {
      if (newValue !== oldValue) {
        dialogShown.value = newValue;

        if (newValue) {
          selectedAccountIds.value = props.value;
        }
      }
    });
    const cancel = function () {
      emit('cancel');
    };

    const selectedAccountIds = ref([]);
    const selectedAccounts = ref([]);

    const ok = function () {
      emit('input', selectedAccounts.value);
    }

    return {
      dialogShown,
      selectedAccountIds,
      selectedAccounts,

      cancel,
      ok,
    }
  },
}
</script>
