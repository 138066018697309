<template>
  <BannerFrame :label="$t('booking_record.detail_title')">
    <v-container class="booking-detail" v-if="bookingRecord">
      <v-row>
        <v-col>
          <v-text-field :label="$t('booking.created_at')"
                        readonly
                        hide-details
                        :value="$parseDate(bookingRecord.created_at)"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field :label="$t('booking_record.recurrence')"
                        readonly
                        hide-details
                        :value="$parseCronString(bookingRecord.recurrence, bookingRecord.recur_until)"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-textarea :label="$t('booking.status')"
                      readonly
                      hide-details
                      :value="$t(`booking_record_status.${bookingRecord.status}`)"
                      rows="1"
                      auto-grow
                      no-resize></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table :headers="headers"
                        :items="bookings"
                        @click:row="onBookingClicked"
                        mobile-breakpoint="0">
            <template v-slot:item.created_at="{value}">
              {{ $parseDate(value) }}
            </template>
            <template v-slot:item.start_time="{value}">
              {{ $parseDate(value, 'yyyy-MM-dd') }}
            </template>
            <template v-slot:item.time="{item}">
              {{ $parseDate(item.start_time, 'HH:mm') + ' - ' + $parseDate(item.end_time, 'HH:mm') }}
            </template>
            <template v-slot:item.status="{value}">
              {{ $t(`booking_status.${value}`) }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <v-footer app
                inset
                class="pr-6 py-3">
        <v-row no-gutters>
          <v-btn color="primary"
                 @click="$router.back()">
            {{ $t('back') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary"
                 @click="cancelBooking">
            {{ $t('booking_record.cancel') }}
          </v-btn>
        </v-row>
      </v-footer>
    </v-container>
  </BannerFrame>
</template>
<script>
import {computed, onMounted, ref, watch} from '@vue/composition-api';
import BannerFrame from "@/components/BannerFrame";
import {ACTION_TYPES} from "@/store/types";
import {BookingRecord} from "@/store/models";
import {BOOKING_RECORD_STATUS} from "@/constants";
import _ from 'lodash';

export default {
  name: 'BookingRecordDetail',
  components: {BannerFrame},
  setup(props, {root}) {
    const bookingRecordId = ref(root.$route.params.bookingRecordId);
    const bookingRecord = computed(() => {
      return BookingRecord.query().where('id', bookingRecordId.value).withAllRecursive().first();
    });
    watch(()=> bookingRecord.value, (newValue) => {
      if (newValue && !newValue.recurrence) {
        root.$router.replace({name: 'booking-details', params: {bookingId: newValue.bookings[0].id}});
      }
    })
    const bookings = computed(()=> {
      if (!bookingRecord.value) return [];

      return _.reverse(_.sortBy(bookingRecord.value.bookings, 'start_time'));
    })

    const headers = ref([
      {text: root.$t('booking.created_at'), value: 'created_at'},
      {text: root.$t('booking.item'), value: 'item.name'},
      {text: root.$t('booking.date'), value: 'start_time'},
      {text: root.$t('booking.time'), value: 'time', sortable: false},
      {text: root.$t('booking.title'), value: 'title'},
      {text: root.$t('booking.status'), value: 'status'},
    ])

    const onBookingClicked = function (item) {
      root.$router.push({name: 'booking-details', params: {bookingId: item.id}});
    };
    const cancelBooking = async function () {
      if (await root.$dialog.confirm({text: root.$t('booking_record.cancel_confirm_msg')})) {
        await root.$store.dispatch(ACTION_TYPES.CANCEL_BOOKING, {booking_record_id: bookingRecordId.value});
        await root.$store.dispatch(ACTION_TYPES.GET_BOOKING_RECORDS, {booking_record_id: bookingRecordId.value});
      }
    }

    onMounted(async () => {
      await root.$store.dispatch(ACTION_TYPES.GET_BOOKING_RECORDS, {booking_record_id: bookingRecordId.value});
    })

    return {
      bookingRecord,
      bookings,

      headers,

      onBookingClicked,
      cancelBooking,

      BOOKING_RECORD_STATUS,
    }
  },
}
</script>

<style lang="less">
.booking-detail {
  background-color: white;
  padding: 2rem;

  .v-label {
    color: var(--v-primary-base);
  }

  .v-text-field > .v-input__control > .v-input__slot:before {
    display: none;
  }

  .v-text-field > .v-input__control > .v-input__slot:after {
    display: none;
  }

}
</style>
