<template>
  <v-text-field readonly
                :label="label"
                :value="value"
                :placeholder="placeholder"
                hide-details="auto"
                class="display-only"></v-text-field>
</template>

<script>
export default {
  name: 'DisplayTextField',
  props: {
    label: String,
    value: String,
    placeholder: String,
  }
}
</script>

<style lang="less">
.display-only {
  .v-input__slot {
    &:before, &:after {
      display: none !important;
    }
  }

  .v-label {
    color: var(--v-primary-base) !important;
    caret-color: var(--v-primary-base) !important;
  }
}
</style>
