<template>
  <BGModal :shown="dialogShown"
           :title="$t('item.create_admin_booking')"
           no-fullscreen>
    <template #content>
      <v-container fluid class="d-flex flex-column pb-0 create-admin-booking" style="height: 100%">
        <v-window v-model="step" class="fill-height">
          <v-window-item>
            <v-container class="pa-0">
              <h3>Step 1: Booking Details</h3>
              <v-form ref="bookingForm"
                      v-model="bookingFormValid">
                <v-row>
                  <v-col cols="12">
                    <v-radio-group v-model="bookingType"
                                   :rules="requiredRules">
                      <v-radio value="single"
                               label="Single"></v-radio>
                      <v-radio value="recurring"
                               label="Recurring"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>

                <!--Single-->
                <v-row v-if="bookingType === 'single'">
                  <v-col cols="12">
                    <v-input v-model="bookingStart"
                             :rules="requiredRules"
                             hide-details="auto">
                      <v-container fluid class="flex-column pa-0">
                        <legend class="v-label theme--light">Booking Start</legend>
                        <DateTimePickerInput v-model="bookingStart"
                                             hide-details></DateTimePickerInput>
                      </v-container>
                    </v-input>
                  </v-col>
                  <v-col cols="12">
                    <v-input v-model="bookingEnd"
                             :rules="bookingEndRules"
                             hide-details="auto">
                      <v-container fluid class="flex-column pa-0">
                        <legend class="v-label theme--light">Booking End</legend>
                        <DateTimePickerInput v-model="bookingEnd"
                                             hide-details></DateTimePickerInput>
                      </v-container>
                    </v-input>
                  </v-col>
                </v-row>

                <!--Recurring-->
                <v-row v-if="bookingType === 'recurring'">
                  <v-col cols="12">
                    <DatePickerInput label="Start Date"
                                     :min="today"
                                     v-model="fromDate"
                                     :rules="requiredRules"
                                     hide-details="auto"></DatePickerInput>
                  </v-col>
                  <v-col cols="12">
                    <DatePickerInput label="End Date"
                                     :min="fromDate || today"
                                     v-model="toDate"
                                     :rules="toDateRules"
                                     hide-details="auto"></DatePickerInput>
                  </v-col>
                  <v-col cols="12">
                    <TimePickerInput label="From Time"
                                     v-model="fromTime"
                                     :rules="requiredRules"
                                     hide-details="auto"
                                     use-native></TimePickerInput>
                  </v-col>
                  <v-col cols="12">
                    <TimePickerInput label="To Time"
                                     v-model="toTime"
                                     :rules="toTimeRules"
                                     hide-details="auto"
                                     use-native></TimePickerInput>
                  </v-col>
                  <v-col cols="12">
                    <CronInput outlined
                               label="Recurrence"
                               hide-details="auto"
                               v-model="recurrence"
                               :rules="requiredRules"></CronInput>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-window-item>
          <v-window-item>
            <v-container class="h-100 d-flex flex-column pa-0">
              <h3>Step 2: Confirm Conflict(s)</h3>

              <v-data-table :items="collisions"
                            :headers="collisionHeaders"
                            fixed-header
                            hide-default-footer
                            mobile-breakpoint="0"
                            @click:row="onBookingClicked"
                            class="collision-list--table">
                <template v-slot:item.created_at="{value}">
                  {{ $parseDate(value) }}
                </template>
                <template v-slot:item.start_time="{value}">
                  {{ $parseDate(value, 'yyyy-MM-dd') }}
                </template>
                <template v-slot:item.time="{item}">
                  {{ $parseDate(item.start_time, 'HH:mm') + ' - ' + $parseDate(item.end_time, 'HH:mm') }}
                </template>
                <template v-slot:item.status="{value}">
                  {{ $t(`booking_status.${value}`) }}
                </template>
              </v-data-table>
            </v-container>
          </v-window-item>
        </v-window>
      </v-container>
    </template>
    <template #footer>
      <v-row no-gutters>
        <v-btn color="secondary"
               @click="cancel"
               class="mr-3"
               depressed
               large>
          {{ $t('close') }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary"
               @click="$refs.bookingForm.validate() && searchCollisions()"
               v-if="step === 0"
               depressed
               large>
          {{ $t('next') }}
        </v-btn>

        <v-btn color="primary"
               class="mr-3"
               @click="step = 0"
               v-if="step === 1"
               depressed
               large>
          {{ $t('back') }}
        </v-btn>
        <v-btn color="primary"
               @click="createBooking"
               v-if="step === 1"
               depressed
               large>
          {{ $t('next') }}
        </v-btn>
      </v-row>
    </template>
  </BGModal>
</template>
<script>
import BGModal from "@/components/BGModal";
import {ref, watch} from "@vue/composition-api";
import DatePickerInput from "@/components/DatePickerInput";
import TimePickerInput from "@/components/TimePickerInput";
import {DateTime} from "luxon";
import CronInput from "@/components/CronInput";
import {ACTION_TYPES} from "@/store/types";
import DateTimePickerInput from "@/components/DateTimePickerInput";
import {BookingRecord} from "@/store/models";

export default {
  name: 'CreateAdminBookingModal',
  components: {DateTimePickerInput, CronInput, TimePickerInput, DatePickerInput, BGModal},
  props: {
    shown: Boolean,
    itemId: String,
  },
  setup(props, {root, emit}) {
    const dialogShown = ref(props.shown);

    // Init
    watch(() => props.shown, async (newValue, oldValue) => {
      if (newValue !== oldValue) {
        dialogShown.value = newValue;

        if (newValue) {
          step.value = 0;
          bookingType.value = null;

          bookingStart.value = null;
          bookingEnd.value = null;

          fromDate.value = DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd');
          toDate.value = null;
          fromTime.value = null;
          toTime.value = null;
          recurrence.value = null;
        }
      }
    });
    const cancel = function () {
      emit('cancel');
    };

    const step = ref(0);

    const bookingType = ref(null);

    const bookingForm = ref();
    const bookingFormValid = ref(false);
    const requiredRules = ref([
      value => !!value || 'Required',
    ]);
    const today = ref(DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'));

    // Single
    const bookingStart = ref(null);
    const bookingEnd = ref(null);
    const bookingEndRules = ref([
      ...requiredRules.value,
      value => (!bookingStart.value || value > bookingStart.value) || 'Invalid End Date',
    ])

    // Recurring
    const fromDate = ref(DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'));
    const toDate = ref(null);
    const toDateRules = ref([
      ...requiredRules.value,
      value => (!fromDate.value || value > fromDate.value) || 'Invalid Date',
    ]);
    const fromTime = ref(null);
    const toTime = ref(null);
    const toTimeRules = ref([
      ...requiredRules.value,
      value => (!fromTime.value || value > fromTime.value) || 'Invalid Time',
    ]);
    const recurrence = ref(null);

    //
    const collisions = ref([]);
    const collisionHeaders = ref([
      {text: root.$t('booking.created_at'), value: 'created_at'},
      {text: root.$t('booking.item'), value: 'item.name'},
      {text: root.$t('booking.account'), value: 'account.name'},
      {text: root.$t('booking.date'), value: 'start_time'},
      {text: root.$t('booking.time'), value: 'time', sortable: false},
      {text: root.$t('booking.title'), value: 'title'},
      {text: root.$t('booking.status'), value: 'status'},
    ]);

    const searchCollisions = async function () {
      step.value = 1;

      let response = await root.$store.dispatch(ACTION_TYPES.CALL_API, {
        url: 'client/managed_items/bookings/',
        params: {
          item_id: props.itemId,

          // Single
          booking_start: bookingType.value === 'single' ? bookingStart.value : null,
          booking_end: bookingType.value === 'single' ? bookingEnd.value : null,

          // Recurring
          from_date: bookingType.value === 'recurring' ? DateTime.fromFormat(fromDate.value, 'yyyy-MM-dd').toISO() : null,
          to_date: bookingType.value === 'recurring' ? DateTime.fromFormat(toDate.value, 'yyyy-MM-dd').startOf('day').plus({days: 1}).toISO() : null,
          from_time: bookingType.value === 'recurring' ? fromTime.value : null,
          to_time: bookingType.value === 'recurring' ? toTime.value : null,
          recurrence: bookingType.value === 'recurring' ? recurrence.value : null,
        },
      });

      collisions.value = response.body.collisions;
    }
    const createBooking = async function () {
      if (collisions.value.length === 0 || await root.$dialog.confirm({text: root.$t('item.create_booking_confirm_msg')})) {
        let response = await root.$store.dispatch(ACTION_TYPES.CALL_API, {
          url: 'client/managed_items/create_booking/',
          params: {
            item_id: props.itemId,

            // Single
            booking_start: bookingType.value === 'single' ? bookingStart.value : null,
            booking_end: bookingType.value === 'single' ? bookingEnd.value : null,

            // Recurring
            from_date: bookingType.value === 'recurring' ? DateTime.fromFormat(fromDate.value, 'yyyy-MM-dd').toISO() : null,
            to_date: bookingType.value === 'recurring' ? DateTime.fromFormat(toDate.value, 'yyyy-MM-dd').startOf('day').plus({days: 1}).toISO() : null,
            from_time: bookingType.value === 'recurring' ? fromTime.value : null,
            to_time: bookingType.value === 'recurring' ? toTime.value : null,
            recurrence: bookingType.value === 'recurring' ? recurrence.value : null,
          },
        });

        if (response.body.booking_record) {
          await BookingRecord.insert({
            data: [response.body.booking_record],
          });
        }

        root.$router.push({name: 'booking-details', params: {bookingId: response.body.booking.id}});
      }
    }

    return {
      dialogShown,
      cancel,

      step,

      bookingType,

      bookingForm,
      bookingFormValid,
      requiredRules,

      bookingStart,
      bookingEnd,
      bookingEndRules,


      today,
      fromDate,
      toDate,
      toDateRules,
      fromTime,
      toTime,
      toTimeRules,
      recurrence,

      searchCollisions,
      collisions,
      collisionHeaders,
      createBooking,
    }
  }
}
</script>

<style lang="less">
.create-admin-booking {
  .v-window__container {
    display: flex;
    flex-direction: column;
  }
  .v-window-item {
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
  }

  .collision-list--table {
    flex: 1 1 0;
    display: flex;
    flex-direction: column;

    .v-data-table__wrapper {
      flex: 1 1 0;
    }

    .v-data-footer {
      margin-right: 0 !important;
    }
  }
}
</style>
